export const globalColors = {
  green: '#0097A0',
  black: '#101010',
  blackLight: '#171717',
  darkblack: '#000000',
  white: '#FFFFFF',
  whiteBorder: '#303030',
  whiteLight: '#f7f9fb',
  blue: '#0455C6',
  lightblue: '#599BF7',
  mainBlue: '#599BF7',
  gray: '#939393',
  secondBlue: '#0455C6',
  lightgray: '#F7F9FB',
  red: '#b23b3b',
  mainBackground: '#070B14',
  text: {
    main: '#F6F6F6',
    secondary: '#939393',
  },
  background: {
    gray: '#303030',
  },
  border: {
    gray: '#303030',
  },
  icon: {
    whiteSmoke: '#F6F6F6',
  },
  calendar: {
    type: {
      // done: '#939393',
      // meeting: '#599BF7',
      // appointment: '#0455C6',
      // call: '#19B200',
      inPerson: '#599BF7',
      online: '#19B200',
    },
  },
};