import { Modal } from '@mui/base/Modal';
import { Backdrop, Typography } from '@mui/material';
import { Box, Theme, styled } from '@mui/system';
import { useSelector } from 'react-redux';
import { IMGDefault } from '../../../assets/Images';
import { IFormDetailProject } from '../../../store/projects';
import { IFormDetailProperty } from '../../../store/property';
import { globalColors } from '../../../utils/constants/color';
import { ProductCategories } from '../../../utils/types/products';
import { CardSkeleton } from '../../molecules';
import { selectColor, selectThemeMode } from '../../../store/selector';

interface ModalViewProductProps {
  onClose: () => void;
  open: boolean;
  data: IFormDetailProject | IFormDetailProperty | null;
  type: ProductCategories | null;
  loading: boolean;
}

const ModalViewProduct = ({ onClose, open, data, type, loading }: ModalViewProductProps) => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);

  const dataProperty = data as IFormDetailProperty;
  const dataProject = data as IFormDetailProject;
  const isProperty = type === ProductCategories.Property;
  const isProject = type === ProductCategories.Project;

  return (
    <div>
      <StyledModal
        mode={mode}
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={onClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style} bgcolor={mode === 'dark' ? '#171717' : globalColors.lightgray}>
          {loading && (
            <div className="content">
              <CardSkeleton />
            </div>
          )}
          {!loading && (
            <>
              <div className="img-cover">
                <img src={dataProperty?.cover?.preview || IMGDefault} alt="img" />
              </div>
              <div className="content">
                <Box marginBottom={'1rem'}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant='h5' color={mode === 'dark' ? globalColors.white : globalColors.black}>{dataProperty?.name || '-'}</Typography>
                  </Box>
                  
                  <Typography>{dataProperty?.description}</Typography>
                </Box>

                <Box display={'flex'} marginBottom={'1rem'} justifyContent={'space-between'}>
                  <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Status</Typography>
                  <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{dataProperty?.status || '-'}</Typography>
                </Box>
                {isProject && <Box display={'flex'} marginBottom={'1rem'} justifyContent={'space-between'}>
                  <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Type</Typography>
                  <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{dataProject?.type || '-'}</Typography>
                </Box>}
                <Box marginBottom={'1rem'}>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Country</Typography>
                    <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{dataProperty?.country || '-'}</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>State</Typography>
                    <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{dataProperty?.state || '-'}</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>City</Typography>
                    <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{dataProperty?.city || '-'}</Typography>
                  </Box>
                  <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Address</Typography>
                    <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{dataProperty?.address || '-'}</Typography>
                  </Box>
                </Box>
                <Box marginBottom={'1rem'}>
                  <Typography color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>Amenities</Typography>
                  <Box display={'flex'} justifyContent={'end'} marginTop={'10px'} gap={'1rem'} flexWrap={'wrap'}>
                    {dataProperty?.amenities?.map((item) => (
                      <Box display={'flex'} flexDirection={'column'} gap={'10px'} alignItems={'center'}>
                        <Typography fontSize={'12px'} color={mode === 'dark' ? globalColors.gray : globalColors.lightgray}>
                          {item.label}
                        </Typography>
                        <Typography>
                          {item.value}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <p className="text-raw">
                  Area: {dataProperty?.area} <span className="value">{dataProperty?.areaUnit}</span>
                </p>
                {/* <div className="text-raw pictures">
                  Pictures:
                  <div className="pictures-wrap">
                    {dataProperty?.images?.map((img) => (
                      <img className="picture" src={img.url} alt={img.url} key={img.id} />
                    ))}
                  </div>
                </div> */}

                {isProperty && (
                  <p className="text-raw">
                    Price: <span className="value">{dataProperty?.price} PKR</span>
                  </p>
                )}
                
                <p className="text-raw">
                  Contact Name : <span className="value">{dataProperty?.contactName}</span>
                </p>
                <p className="text-raw">
                  Contact Primary Name : <span className="value">{dataProperty?.contactPhone}</span>
                </p>
                <p className="text-raw">
                  Contact Secondary Name : <span className="value">{dataProperty?.contactSecondaryPhone}</span>
                </p>
              </div>
            </>
          )}
        </Box>
      </StyledModal>
    </div>
  );
};

const StyledModal = styled(Modal)`
  position: fixed;
  z-index: 1300;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const style = (theme: Theme) => ({
  width: '40%',
  minWidth: '500px',
  height: '500px',
  borderRadius: '12px',
  color: 'white',
  overflowY: 'scroll',

  '& p': {
    margin: '0px',
  },
  '.img-cover': {
    height: '250px',
    img: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
  },

  '& .content': {
    padding: '1.2rem',

    '& .title': {
      fontSize: '1.5rem',
      fontWeight: '600',
      marginBottom: '10px',
    },

    '& .text-raw': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
      fontWeight: 400,
      fontSize: '14px',
      color: globalColors.text.secondary,
      flexWrap: 'wrap',
      '&.block': {
        flexDirection: 'column',
        gap: '8px',
        margin: '10px 0',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },

    '& .value': {
      display: 'flex',
      justifyContent: 'space-between',
      margin: 0,
      fontWeight: 400,
      fontSize: '1rem',
      color: globalColors.text.main,
      maxWidth: '60%',
      flexWrap: 'wrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      '&:hover': {
        overflow: 'visible',
      },
    },

    '& .amenities': {
      marginTop: '8px',
      '& .items': {
        maxWidth: '70%',
        display: 'flex',
        justifyContent: 'end',
        gap: '1rem',
        flexWrap: 'wrap',
        '& .item': {
          minWidth: '70px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      },
    },

    '& .pictures': {
      margin: '1rem 0',
      '& .pictures-wrap': {
        margin: '1rem 0 0 0',
        display: 'flex',
        gap: '1rem',
        '& .picture': {
          width: '100px',
          height: '100px',
          borderRadius: '10px',
          objectFit: 'cover',
        },
      },
    },
  },

  // Responsive
  [theme.breakpoints.up('xl')]: {
    minWidth: '50rem',
  },
  [theme.breakpoints.down('sm')]: {
    minWidth: '80%',
  },
});

const StyledBackdrop = styled(Backdrop)`
  z-index: -1;
  position: fixed;
  inset: 0;
  background-color: rgb(0 0 0 / 0.5);
  -webkit-tap-highlight-color: transparent;
`;

export default ModalViewProduct;
