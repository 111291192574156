import { Button, styled } from '@mui/material';
import React from 'react';
import ICLock from '../../../assets/Icons/ic-lock.svg';
import { FieldInput } from '../../atoms';
import { HeadingFormAuth } from '../../molecules';

interface FormResetPasswordProps {
  className?: string;
  onClose?: () => void;
}

const FormResetPassword = ({ className, onClose }: FormResetPasswordProps) => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  return (
    <FormResetPasswordStyle className={className}>
      <div className="wrapper-heading">
        <HeadingFormAuth
          title="Reset your password"
          subTitle="Enter the new passwords"
          onGoBack={onClose}
        />

        <FieldInput
          label="Password"
          id="password"
          name="password"
          type="password"
          placeholder="Example"
          icon={ICLock}
          iconPosition="left"
          className="input-password"
          onShowPassword={() => setShowPassword(!showPassword)}
          isShowPassword={showPassword}
          isPassword={true}
        />
        <FieldInput
          label="Confirm your passowrd"
          id="confirmPassword"
          name="confirmPassword"
          placeholder="Example"
          icon={ICLock}
          iconPosition="left"
          type={showPassword ? 'text' : 'password'}
          onShowPassword={() => setShowConfirmPassword(!showConfirmPassword)}
          isShowPassword={showConfirmPassword}
          isPassword={true}
        />
      </div>
      <div className="wrapper-btn">
        <Button variant="contained" className="btn-send">
          Confirm
        </Button>
      </div>
    </FormResetPasswordStyle>
  );
};

const FormResetPasswordStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  '& .wrapper-heading': {
    height: '100%',
  },

  '& .wrapper-btn': {
    display: 'grid',
    placeItems: 'center',
    marginBottom: '60px',
    '& .btn-send': {
      width: '291px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  },

  '& .input-password': {
    marginBottom: '1.25rem',
  },

  '& button': {
    textTransform: 'none',
  },
}));

export default FormResetPassword;
