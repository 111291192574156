import {
  Box,
  Drawer,
  // Fade,
  FormControlLabel,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { globalColors } from '../../../utils/constants/color';
import ColorBox from '../../../assets/Icons/ColorboxIcon.svg';
import ColorModeIcon from '../../../assets/Icons/ColorModeIcon.svg';
import { themecolor } from '../../../utils/constants/themecolor';
import LeftSideNavigation from '../../../assets/Icons/LeftSideNavigation.svg';
import UpperSideNavigation from '../../../assets/Icons/UpperSideNavigation.svg';
import { useDispatch, useSelector } from 'react-redux';
import { NavigationLayoutFtn, ThemeColorFtn, ThemeModeFtn } from '../../../store/slices';
import UserRepository from '../../../utils/api/repositories/userRepository';
import { FaChevronRight } from 'react-icons/fa';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../../store/selector';

interface ModalCustomizeDashboard {
  openCustomization: boolean;
  setOpenCustomization: (isOpen: boolean) => void;
}

const CustomizeDashboard = ({ openCustomization, setOpenCustomization }: ModalCustomizeDashboard) => {
  const dispatch = useDispatch();

  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);

  const style = {
    marginTop: { xs: '25%', md: '50%' },
    borderRadius: '20px',
    width: '350px',
    bgcolor: `${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
    border: `1px solid ${mode === 'dark' && globalColors.border.gray}`,
    boxShadow: 24,
    p: 4,
  };

  const handleUpdateUserLayout = async (leftSide: boolean) => {
    dispatch(NavigationLayoutFtn(leftSide));
    const repository = new UserRepository();
    await repository.updateUserLayout(leftSide ? 'left' : 'top');
  };

  const handleUpdateUserThemeColor = async (color: string) => {
    console.log(color);
    dispatch(ThemeColorFtn(color));
    const repository = new UserRepository();
    await repository.updateUserThemeColor(color === '' ? 'default' : color);
  };

  const handleUpdateUserThemeMode = async (mode: string) => {
    dispatch(ThemeModeFtn(mode));
    const repository = new UserRepository();
    await repository.updateUserThemeMode(mode);
  };

  return (
    <Drawer
      anchor="right"
      sx={{
        backgroundColor: 'transparent',
        marginTop: '100px',
        paddingTop: '100px',
        '& .MuiDrawer-paper': { backgroundColor: 'transparent' },
      }}
      open={openCustomization}
      onClose={() => {
        setOpenCustomization(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      className="modal-content-wrapper scroll"
    >
      {/* <Slide in={openCustomization} direction="left" timeout={800} className="scroll"> */}
      {/* <Fade in={open}> */}
      <Box sx={style}>
        <Box sx={{ display: 'flex', justifyContent: 'end', paddingBottom: '15px', cursor: 'pointer' }}>
          <FaChevronRight
            color={mode === 'dark' ? globalColors?.white : globalColors.black}
            onClick={() => {
              setOpenCustomization(false);
            }}
          />
        </Box>

        <Typography variant="h6" fontSize={'20px'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
          Theme Customizer
        </Typography>

        <Box display={'flex'} flexDirection={'column'} gap={'20px'}>
          {/* Theme Color */}
          <Box bgcolor={mode === 'dark' ? globalColors.black : globalColors.white} marginTop={'10px'} padding={'20px'} borderRadius={'12px'}>
            <Stack direction="row" gap={'10px'}>
              <img height={'18px'} src={ColorBox} alt="" />
              <Typography fontWeight={400} fontSize={'12px'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                Theme color
              </Typography>
            </Stack>
            <Grid direction="row" justifyContent="center" alignItems="center" container marginTop={'10px'} spacing={2}>
              {themecolor?.map((val) => (
                <Grid item key={val} xs={4} sm={4} md={4} container lg={4}>
                  <Box
                    border={color === val ? `3px solid ${mode === 'dark' ? globalColors.white : globalColors.blackLight}` : ''}
                    onClick={() => {
                      if (color === val) {
                        handleUpdateUserThemeColor('');
                      } else {
                        handleUpdateUserThemeColor(val);
                      }
                    }}
                    component={'span'}
                    sx={{ cursor: 'pointer' }}
                    borderRadius={'10px'}
                    bgcolor={val}
                    height={'72px'}
                    width={'83px'}
                  ></Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          {/* Navigation Layout */}
          {/* <Box bgcolor={mode === 'dark' ? globalColors.black : globalColors.white} marginTop={'10px'} padding={'20px'} borderRadius={'12px'}>
              <Stack direction="row" gap={'10px'}>
                <img height={'18px'} src={LeftSideNavigation} alt="" />
                <Typography fontWeight={400} fontSize={'12px'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                  Navigation Layout
                </Typography>
              </Stack>
              <Grid direction="row" justifyContent="center" alignItems="center" container marginTop={'10px'} spacing={2}>
                <Grid item xs={6} sm={6} md={6} container lg={6}>
                  <Box
                    onClick={() => handleUpdateUserLayout(true)}
                    border={navigation === true ? `1px solid ${mode === 'dark' ? globalColors.white : globalColors.blackLight}` : ''}
                    component={'span'}
                    borderRadius={'10px'}
                    bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                    display={'flex'}
                    gap={'10px'}
                    sx={{ cursor: 'pointer' }}
                    padding={'10px'}
                    flexDirection={'column'}
                  >
                    <img height={'50px'} src={LeftSideNavigation} alt="" />
                    <Typography color={mode === 'dark' ? globalColors.white : globalColors.black} textAlign={'center'}>
                      Left Side
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} container lg={6}>
                  <Box
                    onClick={() => handleUpdateUserLayout(false)}
                    border={navigation === false ? `1px solid ${mode === 'dark' ? globalColors.white : globalColors.blackLight}` : ''}
                    sx={{ cursor: 'pointer' }}
                    component={'span'}
                    borderRadius={'10px'}
                    bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                    display={'flex'}
                    gap={'10px'}
                    padding={'10px'}
                    flexDirection={'column'}
                  >
                    <img height={'50px'} src={UpperSideNavigation} alt="" />
                    <Typography textAlign={'center'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                      Upper Side
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box> */}
          {/* Theme Mode */}
          <Box bgcolor={mode === 'dark' ? globalColors.black : globalColors.white} marginTop={'10px'} padding={'20px'} borderRadius={'12px'}>
            <Stack direction="row" gap={'10px'}>
              <img height={'18px'} src={ColorModeIcon} alt="" />
              <Typography fontWeight={400} fontSize={'12px'} color={mode === 'dark' ? globalColors.white : globalColors.black}>
                Theme mode
              </Typography>
            </Stack>
            <RadioGroup
              sx={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10px',
                justifyContent: 'center',
                fontSize: '12px',
              }}
              row
              aria-labelledby="demo-error-radios"
              name="quiz"
              //   value={value}
              //   onChange={handleRadioChange}
            >
              <FormControlLabel
                onChange={() => handleUpdateUserThemeMode('light')}
                sx={{
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                  fontSize: '12px',
                  fontWeight: 400,
                }}
                value="light_mode"
                control={
                  <Radio
                    checked={mode === 'light'}
                    size="small"
                    sx={{
                      color: globalColors.blue,
                      '&.Mui-checked': {
                        color: globalColors.blue,
                      },
                    }}
                  />
                }
                label={<Typography sx={{ fontSize: '14px' }}>Light mode</Typography>}
              />
              <FormControlLabel
                onChange={() => handleUpdateUserThemeMode('dark')}
                sx={{
                  color: mode === 'dark' ? globalColors.white : globalColors.black,
                }}
                value="dark_mode"
                control={
                  <Radio
                    checked={mode === 'dark'}
                    size="small"
                    sx={{
                      color: globalColors.blue,
                      '&.Mui-checked': {
                        color: globalColors.blue,
                      },
                    }}
                  />
                }
                label={<Typography sx={{ fontSize: '14px' }}>Dark mode</Typography>}
              />
            </RadioGroup>
          </Box>
        </Box>
      </Box>
      {/* </Fade> */}
      {/* </Slide> */}
    </Drawer>
  );
};

export default CustomizeDashboard;
