import { Box, Modal, Typography, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { hasPermissionForAction } from "../../../../../utils/helpers";
import { globalColors } from "../../../../../utils/constants/color";
import Loader from "../../../../atoms/loader";
import NoPermission from "../../../../atoms/noPermission";

interface ModalConfirmPricingProps {
  open: boolean;
  mode: string;
  color: string;
  onClose: () => void;
  onConfirm: any;
  newPricing: number;
}
const ModalConfirmPricing = ({
  open,
  onClose,
  mode,
  color,
  onConfirm,
  newPricing,
}: ModalConfirmPricingProps) => {
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    maxWidth: "525px",
    maxHeight: "360px",
    bgcolor: mode === "dark" ? "background.paper" : globalColors.white,
    // border: '2px solid #000',
    boxShadow: 5,
    p: 4,
    backgroundColor:
      mode === "dark" ? globalColors.blackLight : globalColors.white,
    padding: "1.5rem",
    borderRadius: "20px",

    "@media (max-width: 576px)": {
      width: "80%",
    },
  };

  return (
    <Modal open={open} onClose={onClose} className="content">
      <Box sx={style}>
        <Typography
          fontSize={"20px"}
          textAlign={"center"}
          color={mode === "dark" ? globalColors.white : globalColors.black}
        >
          Are you sure you want to proceed?
        </Typography>
        <Typography
          marginTop={"15px"}
          fontSize={"12px"}
          textAlign={"center"}
          color={mode === "dark" ? globalColors.gray : globalColors.black}
        >
          You are about to change the pricing per month for this organization to{" "}
          <span style={{ color: color || globalColors.blue }}>
            {newPricing} USD
          </span>
          . This would change the amount they would pay at the end of their
          payment cycle.
        </Typography>
        <Box
          display={"flex"}
          justifyContent={"space-evenly"}
          gap={"15px"}
          marginTop={"20px"}
        >
          <Button
            sx={{
              backgroundColor: globalColors.red,
              color: globalColors.white,
              width: "100%",
              borderRadius: "12px",
              "&:hover": {
                backgroundColor: globalColors.red,
              },
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              border: `1px solid ${color || globalColors.blue}`,
              color: mode == "dark" ? globalColors.white : globalColors.black,
              width: "100%",
              borderRadius: "12px",
              paddingLeft: "10px",
              paddingRight: "10px",
              "&:hover": {
                backgroundColor: color || globalColors.blue,
              },
            }}
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalConfirmPricing;
