import { Box } from '@mui/material';
import { globalColors } from '../../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectNavigationLayout, selectThemeMode, selectColor } from '../../../../store/selector';
import { styled } from '@mui/material';
import Heading from '../../../atoms/heading';
import { useEffect, useState } from 'react';
import ProjectRepository from '../../../../utils/api/repositories/projectRepository';
import PropertyRepository from '../../../../utils/api/repositories/propertyRepository';
import Loader from '../../../atoms/loader';
import { getYearsArray } from '../../../../utils/helpers';
import StackChart from '../../../atoms/stackChart';
import YearSelector from '../../../atoms/yearSelector';
import UserRepository from '../../../../utils/api/repositories/userRepository';
import PaymentRepository from '../../../../utils/api/repositories/paymentRepository';

export interface FormValues {
  name: string;
  role: string;
  commission: string;
  email: string;
  phone: string;
  phone2: string;
}

interface QuarterlyData {
  Q1: number;
  Q2: number;
  Q3: number;
  Q4: number;
}

const RevenueGraph = () => {
  const [selectedYear, setSelectedYear] = useState<number>(new Date().getFullYear());
  const [quarterlyData, setQuarterlyData] = useState<QuarterlyData>({Q1: 0, Q2: 0, Q3: 0, Q4: 0})
  const [loading, setLoading] = useState(true);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);

  useEffect(() => {
    const fetchYearData = async () => {
      setLoading(true);
      const paymentRepository = new PaymentRepository();
      const revenue = await paymentRepository.getTotalRevenueByYearFormatted(selectedYear);
      if (revenue) {
        setQuarterlyData(revenue);
      }
      setLoading(false);
    };

    fetchYearData();
  }, [selectedYear]);

  return (
    <CardContainer
      sx={{
        flexDirection: 'column',
        backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.lightgray,
      }}
    >
      {loading ? (
        <Box height={'346px'} display={'flex'} justifyContent={'center'}>
          <Loader />
        </Box>
      ) : (
        <>
          <Box
            display={'flex'}
            justifyContent={'space-between'}
            paddingBottom={'20px'}
            paddingLeft={'30px'}
            paddingRight={'30px'}
            paddingTop={'10px'}
          >
            <Heading text={'Total Revenue'} />
            <YearSelector mode={mode} values={getYearsArray()} selectedValue={selectedYear} setSelectedValue={setSelectedYear} />
          </Box>

          <Box display="flex" flexDirection={{ md: 'row', xs: 'column' }} paddingBottom={'20px'}>
            <Box
              sx={{
                width: { md: '100%', xs: '100%' },
                height: '300px',
                padding: '10px 20px',
              }}
            >
              <StackChart data={[{name: 'Q1', uv: quarterlyData['Q1'], pv: quarterlyData['Q1'], amt: quarterlyData['Q1']}, {name: 'Q2', uv: quarterlyData['Q2'], pv: quarterlyData['Q2'], amt: quarterlyData['Q2']}, {name: 'Q3', uv: quarterlyData['Q3'], pv: quarterlyData['Q3'], amt: quarterlyData['Q3']}, {name: 'Q4', uv: quarterlyData['Q4'], pv: quarterlyData['Q4'], amt: quarterlyData['Q4']}]}/>
            </Box>
          </Box>
        </>
      )}
    </CardContainer>
  );
};

const CardContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  height: '100%',
  width: '100%',
  // marginTop: '3%',
  borderRadius: '16px',
  border: `1px solid ${globalColors?.border.gray}`,
});

export default RevenueGraph;
