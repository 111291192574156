import { Box, Button, Divider, Typography, styled, useMediaQuery } from '@mui/material';
import { globalColors } from '../../utils/constants/color';
import CustomizeDashboard from '../organisms/customizeDashboard';
import React, { useEffect, useState } from 'react';
import { FaPalette } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { selectColor, selectNewNotificationsState, selectUserData } from '../../store/selector';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import UserRepository from '../../utils/api/repositories/userRepository';
import { UserRole, resetUserData } from '../../store/user';
import { useNavigate } from 'react-router-dom';
import { IoIosArrowDropdownCircle } from 'react-icons/io';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { setCloseSettings } from '../../store/routes';
import { formatRole, getInitials } from '../../utils/helpers';
import NotificationRepository from '../../utils/api/repositories/notificationRepository';
import moment from 'moment';
import { BsEye } from 'react-icons/bs';
import { MdDelete } from 'react-icons/md';
import Loader from '../atoms/loader';
import { BiBell, BiSolidBell } from 'react-icons/bi';
import ModalNotifications from '../organisms/modalNotifications';
import { readNewNotifications, setOrganizationLogo, setOrganizationTier } from '../../store/slices';

interface NavbarProps {
  color: string;
  bgcolor: string;
  mode: string;
}

interface FilterStyleProps {
  mode: string;
}

interface NotificationTileProps {
  notification: Parse.Object;
  color: string;
  mode: string;
  handleDelete: (notification: Parse.Object, idx: number) => Promise<void>;
  idx: number;
  handleRead: (notification: Parse.Object, idx: number) => Promise<void>;
}

interface Notification {
  notification: Parse.Object;
  read: boolean;
}

const NotificationTile = ({ notification, color, mode, handleDelete, idx, handleRead }: NotificationTileProps) => {
  const [read, setRead] = useState(notification.get('read') ?? true);

  return (
    <>
      <Box
        sx={{
          paddingTop: '10px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box display={'flex'} alignItems={'start'} justifyContent={'space-between'} width={'100%'} gap={'10px'}>
          <Typography
            fontSize={'14px'}
            color={read == false ? color || globalColors.blue : mode === 'dark' ? globalColors.white : globalColors.black}
            display={'flex'}
          >
            {notification.get('title')}
          </Typography>
          <Box display={'flex'} gap={'5px'} justifyContent={'end'}>
            {read === false && (
              <BsEye
                color={color}
                size={'1rem'}
                style={{ cursor: 'pointer' }}
                onClick={async () => {
                  setRead(true);
                  await handleRead(notification, idx);
                }}
              />
            )}
            <MdDelete
              color={color}
              size={'1rem'}
              style={{ cursor: 'pointer' }}
              onClick={async () => {
                await handleDelete(notification, idx);
              }}
            />
          </Box>
        </Box>
        <Typography fontSize={'12px'} color={globalColors.gray}>
          {notification.get('body')}
        </Typography>
      </Box>
      <Box
        sx={{
          paddingRight: '10px',
          paddingBottom: '10px',
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'start',
          paddingTop: '5px',
        }}
      >
        <Typography fontSize={'12px'} color={mode === 'dark' ? globalColors.white : globalColors.black} whiteSpace={'nowrap'}>
          {notification.get('sender') ? `${notification.get('sender').get('firstName')} ${notification.get('sender').get('lastName')}` : ''}
        </Typography>
        <Typography fontSize={'12px'} color={globalColors.gray} whiteSpace={'nowrap'}>
          {moment(notification.get('createdAt')).fromNow()}
        </Typography>
      </Box>
      <Divider sx={{ width: '70%', backgroundColor: globalColors.gray, color: globalColors.gray, margin: 'auto' }} />
    </>
  );
};

const Navbar = ({ color, bgcolor, mode }: NavbarProps) => {
  const [openCustomization, setOpenCustomization] = useState<boolean>(false);
  const user = useSelector(selectUserData);
  const currentColor = useSelector(selectColor);
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [mobileAnchor, setMobileAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [notificationsAnchor, setNotificationsAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [notificationsOpen, setNotificationsOpen] = React.useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [notificationsError, setNotificationsError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const newNotifications = useSelector(selectNewNotificationsState);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [notificationsLoading, setNotificationsLoading] = useState(true);
  const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);
  const [notificationsModalOpen, setNotificationsModalOpen] = useState(false);

  const handleReadNotification = async (notification: Parse.Object, idx: number) => {
    let temp = [...notifications];
    temp[idx].read = true;
    setNotifications(temp);
    const notificationRepository = new NotificationRepository();
    await notificationRepository.markAsRead(notification);
  };

  const handleDeleteNotification = async (notification: Parse.Object, idx: number) => {
    let temp = [...notifications];
    temp.splice(idx, 1);
    setNotifications(temp);
    const notificationRepository = new NotificationRepository();
    await notificationRepository.delete(notification);
  };

  const handleLogout = async () => {
    const repository = new UserRepository();
    await repository.logout().then((success) => {
      dispatch(resetUserData());
      dispatch(setCloseSettings());
      dispatch(setOrganizationTier(''));
      dispatch(setOrganizationLogo(''));
      navigate('/');
    });
  };

  const fetchNotifications = async () => {
    setNotificationsLoading(true);
    const notificationRepository = new NotificationRepository();
    const allNotifications = await notificationRepository.getAll();
    if (allNotifications) {
      setNotificationsError('');
      let temp: Notification[] = [];
      for (let i = 0; i < allNotifications.length; i++) {
        temp.push({ notification: allNotifications[i], read: allNotifications[i].get('read') });
      }
      setNotifications(temp);
    } else {
      setNotificationsError('Notifications could not be fetched successfully. Please try again.');
    }
    setNotificationsLoading(false);
  };

  useEffect(() => {
    if (notificationsOpen || notifications.length === 0) {
      fetchNotifications();
    }
  }, [notificationsOpen]);

  useEffect(() => {
    if (notifications.length > 0) {
      const unreadNotifications = notifications.some((n) => n.read === false);
      if (unreadNotifications) {
        setHasUnreadNotifications(true);
      } else {
        setHasUnreadNotifications(false);
      }
    } else {
      setHasUnreadNotifications(false);
    }
  }, [notifications]);

  useEffect(() => {
    setHasUnreadNotifications(newNotifications);
  }, [newNotifications]);

  return (
    <Box display={'flex'} gap={'20px'} alignItems={'center'}>
      {!isMobile && (
        <>
          <Box
            sx={{
              backgroundColor: bgcolor || globalColors.black,
              height: '32px',
              width: '32px',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              margin: 'auto',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1)',
              },
            }}
            onClick={() => setOpenCustomization((prev) => !prev)}
          >
            <FaPalette
              style={{
                cursor: 'pointer',
                margin: 'auto',
              }}
              color={currentColor ? currentColor : globalColors.blue}
            />
            {/* <img
            onClick={() => setOpen((prev) => !prev)}
            style={{
              cursor: "pointer",
              margin: "auto",
            }}
            src={mode === "dark" ? ColorBox : GrayColorBox}
            alt=""
            color={color}
          /> */}
          </Box>
          <Box>
            <Box
              sx={{
                backgroundColor: bgcolor || globalColors.black,
                height: '32px',
                width: '32px',
                borderRadius: '8px',
                display: 'flex',
                justifyContent: 'center',
                margin: 'auto',
                cursor: 'pointer',
                alignItems: 'center',
                '&:hover': {
                  transform: 'scale(1.1)',
                },
              }}
              onClick={() => {
                setNotificationsOpen((o) => !o);
                dispatch(readNewNotifications());
              }}
              ref={setNotificationsAnchor}
            >
              {hasUnreadNotifications ? (
                <BiSolidBell size={'1.2rem'} color={color || globalColors.blue} />
              ) : (
                <BiBell size={'1.2rem'} color={mode === 'dark' ? globalColors.white : globalColors.black} />
              )}
            </Box>
            <BasePopup anchor={notificationsAnchor} open={notificationsOpen} withTransition>
              {(props: any) => (
                <PopAnimation {...props}>
                  <PopupBodyLarge mode={mode}>
                    <Box display={'flex'} flexDirection={'column'} padding={'10px'} width={'100%'} zIndex={99999999}>
                      {notificationsError === '' ? (
                        notificationsLoading ? (
                          <Box display={'flex'} height={'100%'} justifyContent={'center'} alignItems={'center'} marginTop={'10vh'}>
                            <Loader />
                          </Box>
                        ) : notifications.length > 0 ? (
                          notifications.map((notification, idx) => {
                            return (
                              <NotificationTile
                                notification={notification.notification}
                                color={color}
                                mode={mode}
                                handleDelete={async () => {
                                  handleDeleteNotification(notification.notification, idx);
                                }}
                                idx={idx}
                                handleRead={async () => {
                                  handleReadNotification(notification.notification, idx);
                                }}
                              />
                            );
                          })
                        ) : (
                          <Box display={'flex'} height={'100%'} justifyContent={'center'} alignItems={'center'} marginTop={'10vh'}>
                            <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>No notifications yet.</Typography>
                          </Box>
                        )
                      ) : (
                        <Box display={'flex'} height={'100%'} justifyContent={'center'} alignItems={'center'} marginTop={'10vh'}>
                          <Typography color={mode === 'dark' ? globalColors.white : globalColors.black}>{notificationsError}</Typography>
                        </Box>
                      )}
                    </Box>
                  </PopupBodyLarge>
                </PopAnimation>
              )}
            </BasePopup>
          </Box>
          <Box
            display={'flex'}
            margin={'auto'}
            height={'40px'}
            width={'40px'}
            bgcolor={color || globalColors.blue}
            borderRadius={100}
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Typography fontWeight={600}>{getInitials(user?.firstName + ' ' + user?.lastName)}</Typography>
          </Box>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'}>
            <Typography noWrap component="span" marginTop={'auto'} fontSize={'16px'} marginBottom={'auto'} color={color}>
              {user === null ? '-' : user.firstName + ' ' + user.lastName}
            </Typography>
            <Box display={'flex'}>
              <Box ref={setAnchor} onClick={() => setOpen((o) => !o)} sx={{ paddingRight: '5px', cursor: 'pointer' }}>
                <IoIosArrowDropdownCircle color={globalColors.gray} />
              </Box>
              <Typography noWrap fontSize={'14px'} color={globalColors.gray} component="span" marginTop={'auto'} marginBottom={'auto'}>
                {user === null
                  ? 'Login Again'
                  : user.role === UserRole.admin
                  ? 'Admin'
                  : user.role === UserRole.manager
                  ? 'Manager'
                  : user.role === UserRole.executive
                  ? 'Executive'
                  : user.role === UserRole.super
                  ? 'Super Admin'
                  : '-'}
              </Typography>

              <BasePopup anchor={anchor} open={open} withTransition>
                {(props: any) => (
                  <PopAnimation {...props}>
                    <PopupBody mode={mode}>
                      <Box display={'flex'} flexDirection={'column'} padding={'10px'} gap={'10px'}>
                        <Button
                          sx={{
                            textTransform: 'none',
                            display: 'flex',
                            cursor: 'pointer',
                            alignItems: 'center',
                            color: mode === 'dark' ? globalColors.white : globalColors.black,
                            borderRadius: '12px',
                            // margin: '0px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            border: '1px solid transparent',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                              backgroundColor: 'transparent',
                              border: `1px solid ${color || globalColors?.blue}`,
                            },
                          }}
                        >
                          Profile
                        </Button>
                        <Button
                          onClick={handleLogout}
                          sx={{
                            textTransform: 'none',
                            display: 'flex',
                            cursor: 'pointer',
                            alignItems: 'center',
                            color: globalColors.red,
                            borderRadius: '12px',
                            // margin: '0px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            border: '1px solid transparent',
                            transition: 'background-color 0.3s',
                            '&:hover': {
                              backgroundColor: 'transparent',
                              border: `1px solid ${globalColors.red}`,
                            },
                          }}
                        >
                          Log Out
                        </Button>
                      </Box>
                    </PopupBody>
                  </PopAnimation>
                )}
              </BasePopup>
            </Box>
          </Box>
        </>
      )}
      {isMobile && (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'}>
          <Box
            ref={setMobileAnchor}
            onClick={() => setMobileOpen((o) => !o)}
            sx={{ cursor: 'pointer', marginRight: '0px', display: 'flex', alignItems: 'center' }}
          >
            <HiOutlineDotsVertical color={globalColors.gray} size={'1.5rem'} />
          </Box>
          <BasePopup anchor={mobileAnchor} open={mobileOpen} withTransition placement={'bottom-end'}>
            {(props: any) => (
              <PopAnimation {...props}>
                <PopupBody mode={mode}>
                  <Box display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} marginTop={'30px'}>
                    <Button
                      onClick={() => {
                        setMobileOpen(false);
                        setTimeout(() => {
                          setOpenCustomization((prev) => !prev);
                        }, 600);
                      }}
                      sx={{
                        textTransform: 'none',
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        color: color || globalColors.blue,
                        borderRadius: '12px',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      Customize
                    </Button>
                    <Button
                      onClick={() => {
                        setMobileOpen(false);
                        setTimeout(() => {
                          setNotificationsModalOpen(true);
                        }, 600);
                      }}
                      sx={{
                        textTransform: 'none',
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        color: mode === 'dark' ? globalColors.white : globalColors.black,
                        borderRadius: '12px',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      Notifications
                    </Button>
                    <Button
                      sx={{
                        textTransform: 'none',
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        color: mode === 'dark' ? globalColors.white : globalColors.black,
                        borderRadius: '12px',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      Profile
                    </Button>
                    <Button
                      onClick={handleLogout}
                      sx={{
                        textTransform: 'none',
                        display: 'flex',
                        cursor: 'pointer',
                        alignItems: 'center',
                        color: globalColors.red,
                        borderRadius: '12px',
                        transition: 'background-color 0.3s',
                      }}
                    >
                      Log Out
                    </Button>
                  </Box>
                </PopupBody>
              </PopAnimation>
            )}
          </BasePopup>
        </Box>
      )}
      <CustomizeDashboard openCustomization={openCustomization} setOpenCustomization={setOpenCustomization} />
      <ModalNotifications
        open={notificationsModalOpen}
        onClose={() => {
          setNotificationsModalOpen(false);
        }}
        color={color}
        mode={mode}
      />
    </Box>
  );
};

function Animated(
  props: React.PropsWithChildren<{
    className?: string;
    requestOpen: boolean;
    onEnter: () => void;
    onExited: () => void;
  }>
) {
  const { requestOpen, onEnter, onExited, children, className } = props;

  React.useEffect(() => {
    if (requestOpen) {
      onEnter();
    }
  }, [onEnter, requestOpen]);

  const handleAnimationEnd = React.useCallback(() => {
    if (!requestOpen) {
      onExited();
    }
  }, [onExited, requestOpen]);

  return (
    <div onAnimationEnd={handleAnimationEnd} className={className + (requestOpen ? ' open' : ' close')}>
      {children}
    </div>
  );
}

const PopAnimation = styled(Animated)`
  @keyframes open-animation {
    0% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes close-animation {
    0% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }
  }

  &.open {
    animation: open-animation 0.4s ease-in forwards;
  }

  &.close {
    animation: close-animation 0.4s ease-in forwards;
  }
`;

const PopupBody = styled('div')<FilterStyleProps>(
  ({ theme, mode }) =>
    `
    width: max-content;
    padding: 0.5rem 1rem;
    margin: 8px;
    // border: 1px solid ${mode === 'dark' ? '' : ''};
     background-color: ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray};
    border-radius: 8px;
     box-shadow: ${mode === 'dark' ? `0px 4px 8px rgb(0 0 0 / 0.7)` : `0px 4px 8px rgb(0 0 0 / 0.1)`};
    min-height: 3rem;
    display: flex;
    align-items: center;
`
);

const PopupBodyLarge = styled('div')<FilterStyleProps>(
  ({ theme, mode }) =>
    `
    height: 35vh;
    width: 25vw;
    padding: 0.5rem 1rem;
    margin: 8px;
    overflow: scroll;
    // border: 1px solid ${mode === 'dark' ? '' : ''};
    background-color: ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray};
    border-radius: 8px;
    box-shadow: ${mode === 'dark' ? `0px 4px 8px rgb(0 0 0 / 0.7)` : `0px 4px 8px rgb(0 0 0 / 0.1)`};
    min-height: 3rem;
    display: flex;
    align-items: start;
    z-index: 999999999999;
`
);

export default Navbar;
