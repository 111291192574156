import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import PaymentModel from '../models/paymentModel';
import { getYearQuarter } from '../../helpers';

class PaymentRepository {
  private className = 'Payment';
  // create
  public async create(object: PaymentModel): Promise<Parse.Object | null> {
    try {
      const Payment = Parse.Object.extend(this.className);
      const newPayment = new Payment();

      // Set properties of the new payment object
      if (object.paymentId) newPayment.set('paymentId', object.paymentId);
      if (object.currency) newPayment.set('currency', object.currency);
      if (object.status) newPayment.set('status', object.status);
      if (object.amount) newPayment.set('amount', object.amount);
      if (object.type) newPayment.set('type', object.type);
      if (object.organization) newPayment.set('organization', object.organization);

      // Save the new payment
      const savedPayment = await newPayment.save(null, { useMasterKey: true });

      console.log('New payment created with objectId:', savedPayment.id);
      return savedPayment;
    } catch (error: any) {
      console.error('Error creating payment:', error);
      return null;
    }
  }

  // Get
  public async getByOrganizationId(organizationId: string): Promise<PaymentModel[]> {
    try {
      const Payment = Parse.Object.extend(this.className);
      const query = new Parse.Query(Payment);

      // Query payments where the organization field matches the provided organizationId
      query.equalTo('organization', { __type: 'Pointer', className: 'Organization', objectId: organizationId }).descending('createdAt');

      // Execute the query
      const payments = await query.find();

      // Map Parse objects to PaymentModel
      const paymentData: PaymentModel[] = payments.map((payment: any) => ({
        objectId: payment.id,
        paymentId: payment.get('paymentId'),
        paymentMethod: payment.get('paymentMethod'),
        currency: payment.get('currency'),
        status: payment.get('status'),
        amount: payment.get('amount'),
        type: payment.get('type'),
        organization: payment.get('organization'),
        createdAt: payment.createdAt,
        updatedAt: payment.updatedAt,
      }));

      return paymentData;
    } catch (error: any) {
      console.error('Error retrieving payments by organization ID:', error);
      return [];
    }
  }

  public async getPaymentsTotal() {
    try {
      const Payment = Parse.Object.extend(this.className);
      const query = new Parse.Query(Payment);

      const payments = await query.find();

      if (payments) {
        let total = 0;
        for (let i = 0; i < payments.length; i++) {
          total += (payments[i].get('amount') / 100);
        }
        return total;
      }
      else {
        return null;
      }
    }
    catch (e) {
      return null;
    }
  }

  public async getTotalRevenueByYearFormatted(year: number): Promise<any | null> {
    const Payment = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(Payment).greaterThanOrEqualTo('createdAt', yearStartDate).lessThanOrEqualTo('createdAt', yearEndDate).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (payments) => {
            if (payments) {
              let quarterlyData = {'Q1': 0, 'Q2': 0, 'Q3': 0, 'Q4': 0};

              for (let i = 0; i < payments.length; i++) {
                const quarter = getYearQuarter(payments[i].get('createdAt'));
                if (quarter !== '-') {
                  quarterlyData[quarter] += (payments[i].get('amount') / 100) ?? 0;
                }
              }
              resolve(quarterlyData);
            } else {
              resolve(null);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }
}

export default PaymentRepository;
