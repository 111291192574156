import * as React from 'react';
import { styled } from '@mui/system';
import { Unstable_Popup as BasePopup } from '@mui/base/Unstable_Popup';
import { Box, Button, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { selectColor, selectPropertiesFilter, selectThemeMode } from '../../../store/selector';
import { globalColors } from '../../../utils/constants/color';
import { ButtonFilter, FieldInput } from '../../atoms';
import Dropdown from '../../atoms/dropdown';
import SliderWithLabel from '../../atoms/slider';
import { FilterDataFtn, onLoading } from '../../../store/property';

interface FilterStyleProps {
  mode: string;
}
interface FilterValues {
  status: string;
  priceLow: number;
  priceHigh: number;
  location: string;
  minArea: number;
  maxArea: number;
}

export default function FilterProperties() {
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const propertiesFilterData = useSelector(selectPropertiesFilter);
  const popupRef = React.useRef<HTMLDivElement>(null);

  const handleFilterValues = async (values: FilterValues) => {
    dispatch(FilterDataFtn(values));
    dispatch(onLoading(true));
  };

  const handleClick = (event: any) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        setAnchor(null);
        if (anchor === null) {
          setOpen(!open);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [anchor]);

  return (
    <div>
      <Box
        // ref={setAnchor}
        onClick={() => setOpen((o) => !o)}
      >
        <ButtonFilter onClick={handleClick} />
      </Box>
      <BasePopup anchor={anchor} open={open} withTransition>
        {(props: any) => (
          <PopAnimation {...props}>
            <PopupBody mode={mode} ref={popupRef}>
              <Formik
                initialValues={{
                  status: propertiesFilterData.status || '',
                  priceLow: propertiesFilterData.priceLow || 0,
                  priceHigh: propertiesFilterData.priceHigh || 0,
                  location: propertiesFilterData.location || '',
                  minArea: propertiesFilterData.minArea || 0,
                  maxArea: propertiesFilterData.maxArea || 0,
                }}
                validate={(values) => {}}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                  console.log('VALUES', values);
                  await handleFilterValues(values);
                  setSubmitting(false);
                }}
              >
                {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue }) => (
                  <Form>
                    <Box display={'flex'} flexDirection={'column'} gap={'15px'}>
                      <Dropdown
                        label="Status"
                        mode={mode}
                        defaultValue={values?.status || 'Select'}
                        showCheckBox={true}
                        checked={values?.status !== ''}
                        onUnchecked={() => {
                          setFieldValue('status', '');
                        }}
                        id="status"
                        name="status"
                        values={['Select', 'Available', 'Hold', 'Sold']}
                      />

                      <FieldInput
                        checked={values?.priceLow !== 0}
                        label="Minimum Price"
                        showCheckBox={true}
                        onUnchecked={() => {
                          setFieldValue('priceLow', 0);
                        }}
                        mode={mode}
                        id="priceLow"
                        name="priceLow"
                        placeholder="Min"
                        type="number"
                        min={0}
                      />

                      <FieldInput
                        showCheckBox={true}
                        label="Maximum Price"
                        checked={values?.priceHigh !== 0}
                        onUnchecked={() => {
                          setFieldValue('priceHigh', 0);
                        }}
                        mode={mode}
                        id="priceHigh"
                        name="priceHigh"
                        placeholder="Max"
                        type="number"
                        min={0}
                      />

                      <FieldInput
                        label="Location"
                        showCheckBox={true}
                        onUnchecked={() => {
                          setFieldValue('location', '');
                        }}
                        mode={mode}
                        id="location"
                        name="location"
                        placeholder="Location"
                        type="text"
                      />

                      <FieldInput
                        checked={values?.minArea !== 0}
                        label="Minimum Area"
                        showCheckBox={true}
                        onUnchecked={() => {
                          setFieldValue('minArea', 0);
                        }}
                        mode={mode}
                        id="minArea"
                        name="minArea"
                        placeholder="Min"
                        type="number"
                        min={0}
                      />

                      <FieldInput
                        checked={values?.maxArea !== 0}
                        showCheckBox={true}
                        onUnchecked={() => {
                          setFieldValue('maxArea', 0);
                        }}
                        label="Maximum Area"
                        mode={mode}
                        id="maxArea"
                        name="maxArea"
                        placeholder="Max"
                        type="number"
                        min={0}
                      />

                      <Box display={'flex'} justifyContent={'space-evenly'} gap={'15px'} marginTop={'20px'}>
                        <Button
                          sx={{
                            border: `1px solid ${color || globalColors.blue}`,
                            color: mode === 'dark' ? globalColors.white : globalColors.black,
                            width: '100%',
                            borderRadius: '12px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            '&:hover': {
                              backgroundColor: color || globalColors.blue,
                            },
                          }}
                          type="button"
                          onClick={() => {
                            handleSubmit();
                          }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? 'Loading...' : 'Apply'}
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </PopupBody>
          </PopAnimation>
        )}
      </BasePopup>
    </div>
  );
}

function Animated(
  props: React.PropsWithChildren<{
    className?: string;
    requestOpen: boolean;
    onEnter: () => void;
    onExited: () => void;
  }>
) {
  const { requestOpen, onEnter, onExited, children, className } = props;

  React.useEffect(() => {
    if (requestOpen) {
      onEnter();
    }
  }, [onEnter, requestOpen]);

  const handleAnimationEnd = React.useCallback(() => {
    if (!requestOpen) {
      onExited();
    }
  }, [onExited, requestOpen]);

  return (
    <div onAnimationEnd={handleAnimationEnd} className={className + (requestOpen ? ' open' : ' close')}>
      {children}
    </div>
  );
}

const PopAnimation = styled(Animated)`
  @keyframes open-animation {
    0% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes close-animation {
    0% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }
  }

  &.open {
    animation: open-animation 0.4s ease-in forwards;
  }

  &.close {
    animation: close-animation 0.4s ease-in forwards;
  }
`;

const PopupBody = styled('div')<FilterStyleProps>(
  ({ theme, mode }) =>
    `
    width: max-content;
    
    padding: 0.5rem 1rem;
    margin: 8px;
    // border: 1px solid ${mode === 'dark' ? '' : ''};
     background-color: ${mode === 'dark' ? globalColors.black : globalColors.lightgray};
    border-radius: 8px;
     box-shadow: ${mode === 'dark' ? `0px 4px 8px rgb(0 0 0 / 0.7)` : `0px 4px 8px rgb(0 0 0 / 0.1)`};
    min-height: 3rem;
    display: flex;
    align-items: center;
`
);
