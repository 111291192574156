import { combineReducers } from '@reduxjs/toolkit';
import appDataReducer from './slices';
import projectReducer from './projects';
import propertyReducer from './property';
import calendarReducer from './calendar';
import userReducer from './user';
import RouteReducer from './routes';
import commissionReducer from './commission';

const rootReducer = combineReducers({
  UseData: appDataReducer,
  projects: projectReducer,
  properties: propertyReducer,
  calendar: calendarReducer,
  user: userReducer,
  route: RouteReducer,
  commission: commissionReducer
});

export default rootReducer;