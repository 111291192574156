import { Toast } from '../../../components/atoms';
import { UserRole } from '../../../store/user';
import ActivityModel from '../models/activityModel';
import LeadRepository from './leadRepository';
import ManagerRepository from './managerRepository';
import SalesExecutiveRepository from './salesExecutiveRepository';
import UserRepository from './userRepository';
import Parse from 'parse';

class ActivityRepository {
  private className = 'Activity';

  // constructor() {}

  public async create(activity: ActivityModel) {
    try {
      const userRepository = new UserRepository();
      const user = userRepository.getCurrentUser();

      if (!user) {
        return false;
      }

      const Activity = Parse.Object.extend(this.className);
      const newActivity = new Activity();

      const leadRepository = new LeadRepository();
      let lead = null;
      if (typeof activity.lead === 'string') {
        lead = await leadRepository.getObjectById(activity.lead);
      }

      newActivity.set('title', activity.title);
      newActivity.set('start', activity.start);
      newActivity.set('end', activity.end);
      const assignedToUser = await userRepository.getObjectById(activity.assignedTo as string);
      if (!assignedToUser) {
        Toast(`Appointment could not be added successfully!`, 'error');
        return null;
      }
      newActivity.set('assignedTo', assignedToUser);
      newActivity.set('color', activity.color);
      newActivity.set('type', activity.type);
      newActivity.set('scheduledFor', activity.start);
      newActivity.set('lead', lead);
      newActivity.set('others', activity.others);
      newActivity.set('time', activity.time);
      newActivity.set('createdAt', activity.createdAt);
      newActivity.set('updatedAt', activity.updatedAt);
      newActivity.set('createdBy', user);
      newActivity.set('updatedBy', user);
      newActivity.set('organization', user.get('organization'));
      if (assignedToUser.get('role') === UserRole.executive) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const salesExecutive = await salesExecutiveRepository.getObjectByUserId(assignedToUser.id);
        if (salesExecutive) {
          newActivity.set('managedBy', salesExecutive.get('manager').get('user'))
        } else {
          return null;
        }
      } else if (assignedToUser.get('role') === UserRole.manager) {
        const managerRepository = new ManagerRepository();
        const manager = await managerRepository.getObjectByUserId(assignedToUser.id);
        if (manager) {
          newActivity.set('managedBy', manager.get('user'))
        } else {
          return null;
        }
      }

      return new Promise((resolve, _) => {
        newActivity
          .save(null, { useMasterKey: true })
          .then(async (savedActivity: Parse.Object | null) => {
            if (savedActivity) {
              Toast('Activity created successfully.', 'success');
              resolve(savedActivity);
            }
            else {
              Toast('Activity could not be created successfully. Please try again.', 'error');
              resolve(null);
            }
          })
          .catch((error: Error) => {
            resolve(null);
          });
      });
    } catch (error) {
      Toast(`Error adding activity ${error}`, 'error');
      return null;
    }
  }

  public async update(id: string, values: ActivityModel) {
    try {
      const Activity = Parse.Object.extend(this.className);
      const query = new Parse.Query(Activity);
      try {
        const activity = await query.get(id, { useMasterKey: true });
        if (activity) {
          const userRepository = new UserRepository();
          // var user = await userRepository.getObjectById(values.assignedTo as string);

          const leadRepository = new LeadRepository();
          let lead = null;
          if (typeof values.lead === 'string') {
            lead = await leadRepository.getObjectById(values.lead);
          }

          if (values.title) {
            activity.set('title', values.title);
          }
          if (values.start) {
            activity.set('start', values.start);
          }
          if (values.end) {
            activity.set('end', values.end);
          }
          if (values.color) {
            activity.set('color', values.color);
          }
          if (values.type) {
            activity.set('type', values.type);
          }
          if (values.assignedTo) {
            var user: any = null;
            if (typeof values.assignedTo === 'string') {
              user = await userRepository.getObjectById(values.assignedTo);
              if (!user) {
                return null;
              }
              activity.set('assignedTo', user);
              if (user.get('role') === UserRole.executive) {
                const salesExecutiveRepository = new SalesExecutiveRepository();
                const salesExecutive = await salesExecutiveRepository.getObjectByUserId(user.id);
                if (salesExecutive) {
                  activity.set('managedBy', salesExecutive.get('manager').get('user'))
                } else {
                  return null;
                }
              } else if (user.get('role') === UserRole.manager) {
                const managerRepository = new ManagerRepository();
                const manager = await managerRepository.getObjectByUserId(user.id);
                if (manager) {
                  activity.set('managedBy', manager.get('user'))
                } else {
                  return null;
                }
              }
            }
            
          }
          if (values.lead) {
            activity.set('lead', values.lead);
          }
          if (values.assignedTo) {
            activity.set('scheduledFor', values.start);
          }

          activity.set('updatedAt', new Date());

          const currentUser = userRepository.getCurrentUser();
          if (currentUser) {
            activity.set('updatedBy', currentUser);
          }

          return new Promise((resolve, _) => {
            activity
              .save(null, { useMasterKey: true })
              .then(async (savedActivity) => {
                if (savedActivity) {
                  if (savedActivity) {
                    Toast('Activity updated successfully.', 'success');
                    resolve(savedActivity);
                  }
                  else {
                    Toast('Activity could not be updated successfully. Please try again.', 'error');
                    resolve(null);
                  }
                }
              })
              .catch((error) => {
                resolve(null);
              });
          });
        }
      } catch (error) {
        return false;
      }

      return Toast(`Activity updated successfully!`, 'success');
    } catch (error) {
      Toast(`Error updated activity ${error}`, 'error');
      return null;
    }
  }

  public async getAll(user?: Parse.User | null): Promise<ActivityModel[]> {
    if (!user) {
      return [];
    }
    const Activity = Parse.Object.extend(this.className);
    let activityQuery = new Parse.Query(Activity);
    const userRole = user.get('role');

    if (userRole === UserRole.admin) {
      const userOrganization = user.get('organization');
      activityQuery.equalTo('organization', userOrganization);
    } 
    else if (userRole === UserRole.manager) {
      activityQuery.equalTo('managedBy', user);
    } 
    else if (userRole === UserRole.executive) {
      activityQuery.equalTo('assignedTo', user);
    } 
    else {
      return [];
    }

    activityQuery.include('assignedTo', 'lead')
    try {
      const activities = await activityQuery.find({ useMasterKey: true });
      const dataResult: ActivityModel[] = [];
      await Promise.all(
        activities.map(async (activity: any) => {
          const activityData = activity.toJSON();
          let start = null;
          let end = null;
          if (activityData.start) {
            start = new Date(activityData.start?.iso);
          }
          if (activityData.end) {
            end = new Date(activityData.end?.iso);
          }

          const getAssignedTo = await activity.get('assignedTo');
          if (getAssignedTo) {
            activityData.assignedTo = {
              id: getAssignedTo.id,
              value: `${getAssignedTo.get('firstName')} ${getAssignedTo.get('lastName')}`,
            };
          }
          const getLead = await activity.get('lead');
          if (getLead) {
            activityData.lead = {
              id: getLead.id,
              value: `${getLead.get('firstName')} ${getLead.get('lastName')}`,
            };
          }

          const data = {
            objectId: activityData.objectId,
            title: activityData.title,
            start: start,
            end: end,
            // assignedTo: {
            //   id: activity.get('assignedTo')?.id,
            //   value: `${activity.get('assignedTo').get('firstName')} ${activity.get('assignedTo').get('lastName')}`,
            // },
            type: activityData.type,
            scheduledFor: activityData.scheduledFor,
            // lead: { id: activity.get('lead').id, value: `${activity.get('lead').get('firstName')} ${activity.get('lead').get('lastName')}` },
            color: activityData?.color,
            createdAt: activityData.createdAt,
            updatedAt: activityData.updatedAt,
          };

          // if (activity.get('lead')) {
          //   data['assignedTo'] = {
          //     id: activity.get('assignedTo')?.id,
          //     value: `${activity.get('assignedTo').get('firstName')} ${activity.get('assignedTo').get('lastName')}`,
          //   };
          //   data['lead'] = { id: activity.get('lead').id, value: `${activity.get('lead').get('firstName')} ${activity.get('lead').get('lastName')}` };
          // }

          dataResult.push(data);
        })
      );

      return dataResult;
    } catch (error) {
      console.error('Error fetching project:', error);
      return [];
    }
  }

  public async getLeads(): Promise<any[]> {
    const userRepository = new UserRepository();
    const user = userRepository.getCurrentUser();
    const User = Parse.User;

    if (!user) {
      return [];
    }

    const userRole = user.get('role');
    const Lead = Parse.Object.extend('Lead');
    let query = new Parse.Query(Lead).ascending('createdAt');

    if (userRole === UserRole.admin) {
      const userOrganization = user.get('organization');
      query.equalTo('organization', userOrganization);
    } else if (userRole === UserRole.manager) {
      query.equalTo('managedBy', user);
    } else if (userRole === UserRole.executive) {
      query.equalTo('assignedTo', user);
    } else {
      return [];
    }

    try {
      const leads = await query.find({ useMasterKey: true });

      const leadModels = leads.map((lead: any) => {
        const dataLead = lead.toJSON();
        return {
          id: dataLead?.objectId,
          name: dataLead?.firstName + ' ' + dataLead?.lastName,
        };
      });

      return leadModels;
    } catch (error) {
      console.error('Error fetching leads:', error);
      return [];
    }
  }

  public async delete(id: string) {
    const Activity = Parse.Object.extend(this.className);
    const query = new Parse.Query(Activity);

    try {
      const activity = await query.get(id, { useMasterKey: true });
      if (activity) {
        return new Promise((resolve, _) => {
          activity.destroy({ useMasterKey: true })
            .then(async () => {
              Toast('Activity removed successfully.', 'success');
              resolve(true);
            })
            .catch((error) => {
              Toast('Activity could not be removed successfully. Please try again.', 'error');
              resolve(null);
            });
        });
      }
    } catch (error) {
      Toast('Activity could not be removed successfully. Please try again.', 'error');
      return false;
    }
  }

  public async changeAllManagedByForAssignedTo(assignedTo: string, newManagerId: string): Promise<boolean> {
    const Activity = Parse.Object.extend(this.className);
    const query = new Parse.Query(Activity).equalTo('assignedTo', assignedTo);
    try {
      const managerUser = Parse.User.createWithoutData(newManagerId);
      const activities = await query.find({ useMasterKey: true });
      if (activities) {
        for (let i = 0; i < activities.length; i++) {
          activities[i].set('managedBy', managerUser);
          const savedActivity = await activities[i].save(null, { useMasterKey: true });
          if (!savedActivity) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }
}

export default ActivityRepository;
