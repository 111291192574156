import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useModalViewProducts } from '../../../hooks';
import useModalDeleteProduct from '../../../hooks/useModalDeleteProduct';
import { IFormDetailProject } from '../../../store/projects';
import { IFormDetailProperty } from '../../../store/property';
import { globalColors } from '../../../utils/constants/color';
import { ColorStatusProduct, ProductCategories } from '../../../utils/types/products';
import { Gallery } from '../../organisms';
import ModalDeleteProduct from '../../organisms/products/modalDeleteProduct';
import ModalViewProduct from '../../organisms/products/modalViewProduct';
import { CiEdit } from 'react-icons/ci';
import { MdDelete } from 'react-icons/md';
import { MdOutlineFileDownload } from 'react-icons/md';
import { Box, TableRow, Checkbox, TableCell, Typography, styled } from '@mui/material';
import { selectColor, selectThemeMode } from '../../../store/selector';

import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FlagIcon from '@mui/icons-material/Flag';
import BedIcon from './bedIcon';
import BathroomIcon from './bathroomIcon';
import KitchenIcon from './kitchenIcon';
import WifiIcon from './wifiIcon';
import FireIcon from './fireIcon';
import { setAddNew } from '../../../store/routes';
import { BsEyeFill } from 'react-icons/bs';
import { formatMoney } from '../../../utils/helpers';

const options = ['Save', 'Edit', 'Download'];

const ITEM_HEIGHT = 48;

interface ListProductItemProps {
  category: ProductCategories;
  mode: string;
  data: IFormDetailProperty | IFormDetailProject;
  onShowGallery?: () => void;
  onDelete?: () => void;
  edit: boolean;
  isCanEdit: boolean;
  rowCheckboxState: { [key: string]: boolean };
  setRowCheckboxState: (state: (prevState: { [key: string]: boolean }) => { [key: string]: boolean }) => void;
  key: string;
}

const ListProductItem = (props: ListProductItemProps) => {
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // Menu
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [isHovered, setIsHovered] = useState(false);
  const [isHovered, setIsHovered] = useState('');
  const [isCardHovered, setIsCardHovered] = useState(false);
  const [isHoveredIndex, setIsHoveredIndex] = useState<any>(null);
  const openMenu = Boolean(anchorEl);

  const handleMouseEnter = () => {
    setIsHovered('');
  };

  const handleMouseLeave = () => {
    setIsHovered('');
  };

  const handleCardEnter = () => {
    setIsCardHovered(true);
  };

  const handleCardLeave = () => {
    setIsCardHovered(false);
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleAction = (option: any) => {
    if (option == 'Edit') {
      dispatch(setAddNew({ addNew: true, path: '/properties' }));
      navigate(route);
    }
  };

  const handleProjectAction = (option: any) => {
    if (option == 'Edit') {
      dispatch(setAddNew({ addNew: true, path: '/projects' }));
      navigate(route);
    }
  };

  const color = useSelector(selectColor);
  const mode = useSelector(selectThemeMode);
  // const { isLoading, onDelete } = useProjects();

  const dataProperty = props.data as IFormDetailProperty;
  const dataProject = props.data as IFormDetailProject;
  // * Handling status styles
  const isProperty = props.category === ProductCategories.Property;
  const isProject = props.category === ProductCategories.Project;
  let styleStatusProject = '';
  let styleStatusProperty = '';
  let stylePriceProperty = '';

  // * Handle delete
  const route = `edit/${props.data?.id}`;
  if (isProject) {
    styleStatusProject = dataProject.status ? ColorStatusProduct[dataProject.status] : 'colorGray';
  }

  if (isProperty) {
    styleStatusProperty = dataProperty.status ? ColorStatusProduct[dataProperty.status] : 'colorGray';
    stylePriceProperty = 'price colorBlue';
  }

  // * Gallery
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const style = {
    color: mode === 'dark' ? globalColors.white : globalColors.black,
    whiteSpace: 'nowrap',
    fontSize: '12px',
    fontWeight: '400',
    textTransform: 'capitalize',
    sm: {
      width: '150px',
      maxWidth: '150px',
    },
    lg: {
      width: '250px',
      maxWidth: '250px',
    },
  };

  // * Modal View Detail
  const { openModalView, handleOpenModalView, handleCloseModalView, loadingModal, data, productType } = useModalViewProducts();

  // * Modal Delete
  const { openModalDelete, handleOpenModalDelete, handleDelete, handleCloseModalDelete, productType: productTypeDelete } = useModalDeleteProduct();

  // const handleHeaderCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const checked = event.target.checked;

  //   const updatedRowCheckboxState = Object.fromEntries(showLead.map((lead) => [lead.id, checked]));
  //   setRowCheckboxState(updatedRowCheckboxState);
  // };

  const handleRowCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, productId: string | undefined) => {
    if (productId) {
      const checked = event.target.checked;
      props.setRowCheckboxState((prevState: { [key: string]: boolean }) => ({
        ...prevState,
        [productId]: checked,
      }));
    }
  };

  if (props.category === ProductCategories.Project) {
    return (
      <>
        <TableRow
          sx={{
            backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
            color: mode === 'dark' ? globalColors.white : globalColors.black,
            borderRadius: '10px',
            gap: '20px',
            marginBottom: '20px',
            paddingBottom: '20px',
            borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
            position: 'relative',
          }}
          key={props.data.id}
        >
          <TableCell sx={style} component="th" scope="row">
            {props.edit && (
              <Checkbox
                size="small"
                sx={{
                  color: color || globalColors.blue,
                  '&.Mui-checked': {
                    color: color || globalColors.secondBlue,
                  },
                }}
                onChange={(event) => {
                  handleRowCheckboxChange(event, props.data.id);
                }}
                checked={props.rowCheckboxState[dataProject.id] || false}
              />
            )}
          </TableCell>
          <TableCell sx={style} component="th" scope="row">
            <Box display={'flex'} alignItems={'center'} gap={'10px'}>
              {/* image */}
              <Box
                sx={{
                  height: '32px',
                  width: '32px',
                  borderRadius: '6px',
                  backgroundImage: `url(${props.data.cover.preview})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  cursor: 'pointer',
                }}
                onClick={handleOpen}
              ></Box>
              {/* name */}
              {dataProject.name}
            </Box>
          </TableCell>

          {/* type */}
          <TableCell sx={style}>{dataProject.type === '' ? '-' : dataProject.type}</TableCell>

          {/* consist of */}
          <TableCell sx={style}>
            {dataProject.consistOf === '' ? '-' : dataProject.consistOf} {dataProject.consistOf === '' ? '' : dataProject.consistOfUnit}
          </TableCell>

          {/* purpose */}
          <TableCell sx={style}>{dataProject.purpose === '' ? '-' : dataProject.purpose}</TableCell>

          {/* status */}
          <TableCell style={{ color: dataProject.status === 'Available' ? 'rgba(0, 203, 69, 1)' : 'rgba(219, 0, 0, 1)' }} sx={style}>
            {dataProject.status}
          </TableCell>

          {/* interest */}
          {/* <TableCell>
            <Typography sx={{ display: 'flex' }} width={'10px'} fontSize={'12px'} flexGrow={1} textAlign="left">
              {dummyData.length > 0
                ? dummyData.length === 1
                  ? dummyData[0]
                  : dummyData.slice(0, 3).map((e: any, i: any) => {
                      return (
                        <AvatarContainer
                          sx={{
                            marginLeft: i === 0 ? '' : '-6px !important',
                            backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
                            cursor: 'pointer',
                          }}
                          onMouseEnter={() => {
                            setIsHovered(e);
                            setIsHoveredIndex(props.key);
                          }}
                          onMouseLeave={() => setIsHovered('')}
                          mode={mode}
                        >
                          {e.charAt(0).toUpperCase()}
                          {isHovered === e && isHoveredIndex === props.key && (
                            <Box
                              sx={{
                                position: 'absolute',
                                top: '100%',
                                left: '50%',
                                transform: 'translateX(-50%)',
                                backgroundColor: '#333',
                                color: '#fff',
                                padding: '4px',
                                borderRadius: '4px',
                                whiteSpace: 'nowrap',
                                fontSize: '12px',
                              }}
                            >
                              {e}
                            </Box>
                          )}
                        </AvatarContainer>
                      );
                    })
                : ''}
              {dummyData.length > 3 ? (
                <AvatarContainer
                  sx={{
                    marginLeft: '-6px !important',
                    color: mode !== 'dark' ? globalColors.black : globalColors.white,
                    cursor: 'pointer',
                  }}
                  onMouseEnter={() => {
                    handleCardEnter();
                    setIsHoveredIndex(props.key);
                  }}
                  onMouseLeave={handleCardLeave}
                  mode={mode}
                >
                  <span>+{dummyData.length - 3}</span>
                </AvatarContainer>
              ) : (
                <></>
              )}
            </Typography>
            {isCardHovered && isHoveredIndex === props.key && (
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
                  border: '1px solid rgba(48, 48, 48, 1)',
                  borderRadius: '10px',
                  padding: '8px',
                  zIndex: '1',
                  whiteSpace: 'nowrap',
                  display: 'flex',
                  flexDirection: 'column',
                  right: '8%',
                }}
              >
                {dummyData.slice(3).map((name: any, index: any) => (
                  <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }} key={index}>
                    <AvatarContainer mr={'16px'} mode={mode}>
                      {name.charAt(0).toUpperCase()}
                    </AvatarContainer>
                    <div style={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}>{name}</div>
                  </div>
                ))}
              </div>
            )}
          </TableCell> */}

          {/* action */}
          <TableCell sx={style}>
            <Box display={'flex'} gap={'10px'}>
              {props.isCanEdit && (
                <CiEdit
                  size={'1.3rem'}
                  color={color || globalColors.blue}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    dispatch(setAddNew({ addNew: true, path: '/projects' }));
                    navigate(route);
                  }}
                />
              )}
              <BsEyeFill
                size={'1.3rem'}
                color={color || globalColors.blue}
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpenModalView(props.category, props.data.id)}
              />

              {props.isCanEdit && (
                <MdDelete
                  onClick={() => handleOpenModalDelete(props.category)}
                  size={'1.3rem'}
                  color={color || globalColors.blue}
                  style={{ cursor: 'pointer' }}
                />
              )}
            </Box>
          </TableCell>

          {/* <TableCell>
            <div>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-controls={openMenu ? 'long-menu' : undefined}
                aria-expanded={openMenu ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClickMenu}
                style={{ color: 'white' }}
              >
                <MoreHorizIcon style={{ color: mode === 'dark' ? globalColors.white : globalColors.black }} />
              </IconButton>
              <Menu
                id="long-menu"
                MenuListProps={{
                  'aria-labelledby': 'long-button',
                }}
                anchorEl={anchorEl}
                open={openMenu}
                onClose={handleCloseMenu}
                PaperProps={{
                  style: {
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '110px',
                    backgroundColor: 'rgba(16, 16, 16, 1 )',
                    color: 'rgba(147, 147, 147, 1)',
                  },
                }}
              >
                {options.map((option) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      '&:hover': {
                        backgroundColor: 'rgba(23, 23, 23, 1 ) !important',
                        color: 'rgba(4, 85, 198, 1 ) !important',
                      },
                    }}
                  >
                    <span>
                      {option === 'Save' ? (
                        <FlagIcon fontSize={'small'} />
                      ) : option === 'Edit' ? (
                        <CiEdit size={'1.3rem'} />
                      ) : (
                        <MdOutlineFileDownload size={'1.3rem'} />
                      )}
                    </span>
                    <MenuItem
                      sx={{ fontSize: '12px' }}
                      key={option}
                      selected={option === 'Any'}
                      onClick={() => {
                        handleCloseMenu();
                        handleProjectAction(option);
                      }}
                    >
                      {option}
                    </MenuItem>
                  </Box>
                ))}
              </Menu>
            </div>
          </TableCell> */}

          {/* Hot Product */}
          {dataProject?.priority === 'hot' && (
            <Box sx={{ position: 'absolute', right: '1px', top: '-10px' }}>
              <FireIcon />
            </Box>
          )}
        </TableRow>

        <Gallery open={open} onClose={handleClose} images={props.data.images} />
        <ModalViewProduct open={openModalView} onClose={handleCloseModalView} loading={loadingModal} data={data || null} type={productType || null} />
        <ModalDeleteProduct
          open={openModalDelete}
          mode={mode}
          color={color}
          onClose={handleCloseModalDelete}
          type={productTypeDelete || null}
          onDelete={() => handleDelete(props.category, props.data.id)}
          action={{permissionName: isProperty ? 'Properties' : 'Projects', action: 'Delete'}}
        />
      </>
    );
  }
  return (
    <>
      <TableRow
        sx={{
          backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
          color: mode === 'dark' ? globalColors.white : globalColors.black,
          borderRadius: '10px',
          gap: '20px',
          marginBottom: '20px',
          paddingBottom: '20px',
          borderBottom: `20px solid ${mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}`,
          position: 'relative',
        }}
        key={props.data.id}
      >
        <TableCell sx={style} component="th" scope="row">
          {props.edit && (
            <Checkbox
              size="small"
              sx={{
                color: color || globalColors.blue,
                '&.Mui-checked': {
                  color: color || globalColors.secondBlue,
                },
              }}
              onChange={(event) => {
                handleRowCheckboxChange(event, props.data.id);
              }}
              checked={props.rowCheckboxState[dataProperty.id] || false}
            />
          )}
        </TableCell>

        <TableCell sx={style} component="th" scope="row">
          <Box display={'flex'} alignItems={'center'} gap={'10px'}>
            {/* image */}
            <Box
              sx={{
                height: '32px',
                width: '32px',
                borderRadius: '6px',
                backgroundImage: `url(${props.data.cover.preview})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                cursor: 'pointer',
              }}
              onClick={handleOpen}
            ></Box>
            {/* name */}
            {dataProperty.name}
          </Box>
        </TableCell>

        {/* address */}
        <TableCell sx={style}>{dataProperty.address === '' ? '-' : dataProperty.address}</TableCell>

        {/* Amenities */}
        {/* <TableCell sx={style} component="th" scope="row">
          <Box sx={{ display: 'flex' }}>
            {dataProperty?.amenities.map((e: any) => {
              if (e.label === 'Kitchen') {
                return (
                  <Box sx={{ display: 'flex' }}>
                    <span style={{ marginRight: '5px' }} className="detail">
                      {e.value}
                    </span>
                    <BedIcon mode={mode} />
                  </Box>
                );
              }

              if (e.label === 'Bedroom') {
                return (
                  <Box sx={{ display: 'flex' }}>
                    <span style={{ margin: '0px 5px' }} className="detail">
                      {'- ' + e.value}
                    </span>
                    <BathroomIcon mode={mode} />
                  </Box>
                );
              }

              if (e.label === 'Bathroom') {
                return (
                  <Box sx={{ display: 'flex' }}>
                    <span style={{ margin: '0px 5px' }} className="detail">
                      {'- ' + e.value}
                    </span>
                    <KitchenIcon mode={mode} />
                  </Box>
                );
              }

              if (e.label === 'Wifi') {
                return (
                  <Box sx={{ display: 'flex' }}>
                    <span style={{ margin: '0px 5px' }} className="detail">
                      {'- ' + e.value}
                    </span>
                    <WifiIcon mode={mode} />
                  </Box>
                );
              }
            })}
          </Box>
        </TableCell> */}

        {/* area */}
        <TableCell sx={style}>
          {dataProperty.area === '' ? '-' : dataProperty.area} {dataProperty.area === '' ? '' : dataProperty.areaUnit}
        </TableCell>

        {/* status */}
        <TableCell style={{ color: dataProperty.status === 'Available' ? 'rgba(0, 203, 69, 1)' : 'rgba(219, 0, 0, 1)' }} sx={style}>
          {dataProperty.status}
        </TableCell>

        {/* price */}
        <TableCell style={{ color: color || globalColors.blue }} sx={style}>
          {dataProperty.price === '0' ? '-' : formatMoney(parseFloat(dataProperty.price))}
        </TableCell>

        {/* interest */}
        {/* <TableCell>
          <Typography sx={{ display: 'flex' }} width={'10px'} fontSize={'12px'} flexGrow={1} textAlign="left">
            {dummyData.length > 0
              ? dummyData.length === 1
                ? dummyData[0]
                : dummyData.slice(0, 3).map((e: any, i: any) => {
                    return (
                      <AvatarContainer
                        sx={{
                          marginLeft: i === 0 ? '' : '-6px !important',
                          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
                          cursor: 'pointer',
                        }}
                        onMouseEnter={() => {
                          setIsHovered(e);
                          setIsHoveredIndex(props.key);
                        }}
                        onMouseLeave={() => setIsHovered('')}
                        mode={mode}
                      >
                        {e.charAt(0).toUpperCase()}
                        {isHovered === e && isHoveredIndex === props.key && (
                          <Box
                            sx={{
                              position: 'absolute',
                              top: '100%',
                              left: '50%',
                              transform: 'translateX(-50%)',
                              backgroundColor: '#333',
                              color: '#fff',
                              padding: '4px',
                              borderRadius: '4px',
                              whiteSpace: 'nowrap',
                              fontSize: '12px',
                            }}
                          >
                            {e}
                          </Box>
                        )}
                      </AvatarContainer>
                    );
                  })
              : ''}
            {dummyData.length > 3 ? (
              <AvatarContainer
                sx={{
                  marginLeft: '-6px !important',
                  color: mode !== 'dark' ? globalColors.black : globalColors.white,
                  cursor: 'pointer',
                }}
                onMouseEnter={() => {
                  handleCardEnter();
                  setIsHoveredIndex(props.key);
                }}
                onMouseLeave={handleCardLeave}
                mode={mode}
              >
                <span>+{dummyData.length - 3}</span>
              </AvatarContainer>
            ) : (
              <></>
            )}
          </Typography>

          {isCardHovered && isHoveredIndex === props.key && (
            <div
              style={{
                position: 'absolute',
                backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
                border: '1px solid rgba(48, 48, 48, 1)',
                borderRadius: '10px',
                padding: '8px',
                zIndex: '1',
                whiteSpace: 'nowrap',
                display: 'flex',
                flexDirection: 'column',
                right: '8%',
              }}
            >
              {dummyData.slice(3).map((name: any, index: any) => (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }} key={index}>
                  <AvatarContainer mr={'16px'} mode={mode}>
                    {name.charAt(0).toUpperCase()}
                  </AvatarContainer>
                  <div style={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}>{name}</div>
                </div>
              ))}
            </div>
          )}
        </TableCell> */}

        {/* action */}
        <TableCell sx={style}>
          <Box display={'flex'} gap={'10px'}>
            {props.isCanEdit && (
              <CiEdit
                size={'1.3rem'}
                color={color || globalColors.blue}
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  dispatch(setAddNew({ addNew: true, path: '/properties' }));
                  navigate(route);
                }}
              />
            )}
            <BsEyeFill
              size={'1.3rem'}
              color={color || globalColors.blue}
              style={{ cursor: 'pointer' }}
              onClick={() => handleOpenModalView(props.category, props.data.id)}
            />

            {props.isCanEdit && (
              <MdDelete
                onClick={() => handleOpenModalDelete(props.category)}
                size={'1.3rem'}
                color={color || globalColors.blue}
                style={{ cursor: 'pointer' }}
              />
            )}
          </Box>
        </TableCell>

        {/* <TableCell>
          <div>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={openMenu ? 'long-menu' : undefined}
              aria-expanded={openMenu ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClickMenu}
              style={{ color: mode === 'dark' ? globalColors.white : globalColors.black }}
            >
              <MoreHorizIcon />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={openMenu}
              onClose={handleCloseMenu}
              PaperProps={{
                style: {
                  maxHeight: ITEM_HEIGHT * 4.5,
                  width: '110px',
                  backgroundColor: 'rgba(16, 16, 16, 1 )',
                  color: 'rgba(147, 147, 147, 1)',
                },
              }}
            >
              {options.map((option) => (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: 'rgba(23, 23, 23, 1 ) !important',
                      color: 'rgba(4, 85, 198, 1 ) !important',
                    },
                  }}
                >
                  <span>
                    {option === 'Save' ? (
                      <FlagIcon fontSize={'small'} />
                    ) : option === 'Edit' ? (
                      <CiEdit size={'1.3rem'} />
                    ) : (
                      <MdOutlineFileDownload size={'1.3rem'} />
                    )}
                  </span>
                  <MenuItem
                    sx={{ fontSize: '12px' }}
                    key={option}
                    selected={option === 'Any'}
                    onClick={() => {
                      handleCloseMenu();
                      handleAction(option);
                    }}
                  >
                    {option}
                  </MenuItem>
                </Box>
              ))}
            </Menu>
          </div>
        </TableCell> */}

        {/* Hot Product */}
        {dataProperty.priority === 'hot' && (
          <Box sx={{ position: 'absolute', right: '1px', top: '-10px' }}>
            <FireIcon />
          </Box>
        )}
      </TableRow>
      <Gallery open={open} onClose={handleClose} images={props.data.images} />
      <ModalViewProduct open={openModalView} onClose={handleCloseModalView} loading={loadingModal} data={data || null} type={productType || null} />
      <ModalDeleteProduct
        open={openModalDelete}
        mode={mode}
        color={color}
        onClose={handleCloseModalDelete}
        type={productTypeDelete || null}
        onDelete={() => handleDelete(props.category, props.data.id)}
        action={{permissionName: isProperty ? 'Properties' : 'Projects', action: 'Delete'}}
      />
    </>
  );
};

const AvatarContainer = styled(Box)(({ mode }: { mode: any }) => ({
  height: '24px',
  width: '24px',
  border: `1px solid rgba(48, 48, 48, 1)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '16px',
  position: 'relative',
  backgroundColor: mode === 'dark' ? globalColors.black : globalColors.whiteLight,
  color: mode === 'dark' ? globalColors.white : globalColors.black,
  padding: '7px',
}));

export default ListProductItem;
