import { useEffect, useRef, useState } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { globalColors } from "../../../../../utils/constants/color";
import {
  selectAddNew,
  selectColor,
  selectCommissionFilter,
  selectOrganizationFilter,
  selectThemeMode,
  sortCommission,
} from "../../../../../store/selector";
import ContentRow from "./contentRow";
import NotFoundLottie from "../../../../atoms/notFound";
import CommissionRepository from "../../../../../utils/api/repositories/commissionRepository";
import UserRepository from "../../../../../utils/api/repositories/userRepository";
import UserModel from "../../../../../utils/api/models/userModel";
import { Button, SearchInput, Toast } from "../../../../atoms";
import Loader from "../../../../atoms/loader";
import Heading from "../../../../atoms/heading";
import FilterOrganization from "./filterOrganization";
import OrganizationRepository from "../../../../../utils/api/repositories/organizationRepository";
import { MdAddCircle } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { setAddNew } from "../../../../../store/routes";

const headerContent = [
  "Name",
  "Country",
  "Users",
  "Date Joined",
  "Status",
  "Action",
];

// const data = [
//   {
//     objectId: '1',
//     name: 'Name 1',
//     country: 'USA',
//     users: 121,
//     dateJoined: '2 Oct 2020',
//     status: 'Paid',
//   },
//   {
//     objectId: '2',
//     name: 'Name 2',
//     country: 'China',
//     users: 141,
//     dateJoined: '12 Oct 2020',
//     status: 'Unpaid',
//   },
//   {
//     objectId: '3',
//     name: 'Name 3',
//     country: 'Egypt',
//     users: 241,
//     dateJoined: '22 Oct 2020',
//     status: 'Pending',
//   },
// ];

interface TableProps {
  onOrganizationsLoaded: (count: number) => void;
}

const ContentTable = ({ onOrganizationsLoaded }: TableProps) => {
  const [organizations, setOrganizations] = useState<Parse.Object[]>([]);
  const [initial, setIniitial] = useState<any[]>([]);
  const [search, setsearch] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const filterData = useSelector(selectOrganizationFilter);
  const sortData = useSelector(sortCommission);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // const handleSearch = (search: any) => {
  //   const filteredData = iniitial.filter((item) => item.name?.toLowerCase().includes(search?.toLowerCase()));

  //   setOrganizations(filteredData);
  // };

  const fetchOrganization = async () => {
    const organizationRepository = new OrganizationRepository();
    const allOrganizations = await organizationRepository.getAllOrganizations();
    if (allOrganizations) {
      setIniitial(organizations);
      setOrganizations(allOrganizations);
      onOrganizationsLoaded(allOrganizations.length);
    }
    setLoading(false);
  };

  const useDebounce = (callback: Function, delay: number) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    return (...args: any[]) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  };

  const fetchOrganizationWithFilters = async () => {
    const queryFilters = {
      status: filterData?.status?.toLowerCase(),
      usersMin: filterData?.usersMin,
      usersMax: filterData?.usersMax,
    };
    const organizationRepository = new OrganizationRepository();
    const organizations = await organizationRepository.getAllOrganizations(
      search,
      queryFilters
    );
    // console.log('organizations', organizations);
    if (organizations) {
      setOrganizations(organizations);
    }
    setLoading(false);
  };

  const debouncedHandleSearch = useDebounce(fetchOrganizationWithFilters, 300);

  useEffect(() => {
    fetchOrganization();
  }, []);

  // useEffect(() => {
  //   handleSearch(search);
  // }, [search]);

  useEffect(() => {
    debouncedHandleSearch();
  }, [filterData, search]);

  return (
    <Box
      bgcolor={
        mode === "dark" ? globalColors?.blackLight : globalColors?.lightgray
      }
      // border={`1px solid ${globalColors?.border.gray}`}
      borderRadius={"16px"}
      boxShadow={"#303030"}
      paddingLeft={{ xs: 0, md: "30px" }}
      paddingRight={{ xs: 0, md: "30px" }}
      paddingTop={{ xs: 0, md: "30px" }}
      height={"100%"}
    >
      {loading ? (
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          height={"200px"}
        >
          <Loader />
        </Box>
      ) : (
        <>
          <Grid
            container
            paddingBottom={"15px"}
            paddingTop={"10px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item xs={12} sm={6}>
              <Box display={"flex"} alignItems={"center"} gap={"1rem"}>
                <Heading text={"Organizations"} />
                <MdAddCircle
                  size={"1.5rem"}
                  style={{ cursor: "pointer" }}
                  color={color || globalColors.blue}
                  onClick={() => {
                    dispatch(setAddNew({ addNew: true, path: "/dashboard" }));
                    navigate("/organizations/add");
                  }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              paddingTop={{ xs: "1rem", sm: 0 }}
              display={"flex"}
              justifyContent={"end"}
            >
              <Box
                component={"span"}
                display={"flex"}
                gap={"20px"}
                sx={{
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                  },
                  width: {
                    xs: "100%",
                    md: "auto",
                  },
                  marginTop: {
                    xs: "20px",
                    md: "0px",
                  },
                }}
              >
                <Box
                  sx={{
                    display: {
                      xs: "flex",
                    },
                    gap: {
                      xs: "5px",
                      md: `15px`,
                    },
                  }}
                >
                  <SearchInput
                    value={search}
                    onChange={(e) => {
                      setsearch(e.target.value);
                    }}
                    className="input-search"
                    placeholder="Search"
                    bgColor="black"
                  />
                </Box>
                <Box
                  display={"flex"}
                  width={"100%"}
                  justifyContent={"center"}
                  gap={"20px"}
                >
                  <FilterOrganization setOpen={setOpen} open={open} />
                </Box>
              </Box>
            </Grid>
          </Grid>

          {organizations.length > 0 ? (
            <TableContainer sx={{ paddingBottom: "30px" }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {headerContent.map((val) => (
                      <TableCell key={val} sx={{ color: globalColors.gray }}>
                        {val}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {organizations.map((item, idx) => (
                    <ContentRow data={item} key={idx} id={item.id} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <NotFoundLottie
              showButton={true}
              text="Looks like you don't have any employees added yet. Press the button below to add a new employee."
              buttonText="Add New Employee"
              navigateTo=""
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ContentTable;
