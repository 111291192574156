import { MenuItem, Select, SelectChangeEvent, Typography, styled } from '@mui/material';
import { ChangeEvent } from 'react';
import { globalColors } from '../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectThemeMode } from '../../store/selector';

interface FieldSelectOptionProps {
  id: string;
  name: string;
  onChange?: (e: ChangeEvent<HTMLSelectElement> | SelectChangeEvent<string>) => void;
  placeholder?: string;
  value?: string;
  className?: string;
  options: Option[];
  renderId?: boolean;
  error?: string;
}
interface FieldInptStyleProps {
  mode: string;
  value: string;
}
export interface Option {
  id: string;
  value: string;
}

const FieldSelectOption = ({id, name, onChange, placeholder, value, className, options, renderId = false, error}: FieldSelectOptionProps) => {
  const mode = useSelector(selectThemeMode);
  return (
    <FieldSelectInputStyle className={className} mode={mode} value={value ?? ''}>
      <Select
        // labelId={id}
        id={id}
        name={name}
        value={value}
        // label={placeholder}
        className="input-wrapper"
        onChange={onChange}
        displayEmpty
        // renderValue={(selected) => {
        //   if (selected?.length === 0) {
        //     return <span className="placeholder">{placeholder}</span>;
        //   }

        //   return selected;
        // }}
      >
        <MenuItem value='' disabled sx={{color: globalColors.text.secondary, backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight, opacity: 0.1}}>Select</MenuItem>
        {options.map((option, index) => (
          <MenuItem sx={{ 
            border: 'none', 
            backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white, 
            color: mode === 'dark' ? option.value == value ? globalColors.black : globalColors.white : globalColors.black,  
            '&:hover': {
              backgroundColor: mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight, 
            },
            
          }} 
            key={option.id} className="item" value={option.value}>
            {renderId ? option.id : option.value}
          </MenuItem>
        ))}
      </Select>
      {error && 
        <StyledTypography>{error}</StyledTypography>
      }
    </FieldSelectInputStyle>
  );
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: 'red',
  fontSize: '0.75rem',
  fontWeight: 400,
  lineHeight: 1.66,
  letterSpacing: '0.03333em',
  paddingTop: '2px'
}));

const FieldSelectInputStyle = styled('div')<FieldInptStyleProps>(({ mode, value }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  '& .placeholder': {
    color: globalColors.text.secondary,
    fontSize: '13px',
  },

  '& .input-wrapper': {
    fontSize: "13px",
    width: '100%',
    color: value === '' ? globalColors.text.secondary : mode === 'dark' ? globalColors.white : globalColors.black,
    padding: '0 1rem',
    backgroundColor: mode === 'dark' ? globalColors.black : globalColors.white,
    border: mode === 'dark' ? '2px solid #303030' : '1px solid #303030',
    height: '48px',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '1rem',
    cursor: 'pointer',

    '& .MuiSelect-select': {
      padding: '16px',
    },

    '& .MuiSvgIcon-root path': {
      color: mode === 'dark' ? globalColors.white : globalColors.black,
    },
  },
}));

export default FieldSelectOption;
