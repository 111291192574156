import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import UserModel from '../utils/api/models/userModel';

export enum UserRole {
  admin = 'admin',
  manager = 'manager',
  executive = 'executive',
  super = 'super',
}

interface UserState {
  data: UserModel | null;
  type: string;
}

const initialState: UserState = {
  data: {
    objectId: '',
    username: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    organization: '',
    profilePicture: null,
    layoutPreference: '',
    colorPreference: '',
    themePreference: '',
    role: '',
    permissions: [],
  },
  type: ''
};
const UserReducer = createSlice({
  name: 'User',
  initialState,
  reducers: {
    setUserData(state, action: PayloadAction<Parse.Object>) {
      const userValues = action.payload;

      if (userValues) {
        const dataUser = userValues.toJSON();
        const user = {
          objectId: dataUser.objectId,
          createdAt: dataUser.createdAt,
          updatedAt: dataUser.updatedAt,
          username: dataUser?.username,
          email: dataUser?.email,
          phone: dataUser?.phone,
          firstName: dataUser?.firstName,
          lastName: dataUser?.lastName,
          organization: dataUser?.organization,
          layoutPreference: dataUser?.layoutPreference,
          themePreference: dataUser?.themePreference,
          colorPreference: dataUser?.colorPreference,
          role: dataUser?.role,
        } as UserModel;

        state.data = user;
      }
    },

    resetUserData(state) {
      state.data = null;
    },

    setUserType(state, action: PayloadAction<string>) {
      state.type = action.payload;
    }
  },
});

export const { setUserData, resetUserData, setUserType } = UserReducer.actions;
export default UserReducer.reducer;
