import { useEffect, useState } from "react";
import {
  Box,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material";
import Heading from "../../../../../atoms/heading";
import { useDispatch, useSelector } from "react-redux";
import { globalColors } from "../../../../../../utils/constants/color";
import {
  selectAddNew,
  selectColor,
  selectThemeMode,
} from "../../../../../../store/selector";
import ContentRow from "./contentRow";
import NotFoundLottie from "../../../../../atoms/notFound";
import PaymentModel from "../../../../../../utils/api/models/paymentModel";
import { Button } from "../../../../../atoms";

const headerContent = ["Payment ID", "Type", "Amount", "Date", "Status"];

interface TableProps {
  data: PaymentModel[];
  onAddPayment: () => void;
}

const ContentTable = ({ data, onAddPayment }: TableProps) => {
  const mode = useSelector(selectThemeMode);

  return (
    <Box
      marginTop={'3%'}
      bgcolor={mode === 'dark' ? globalColors?.blackLight : globalColors?.lightgray}
      borderRadius={'16px'}
      boxShadow={'#303030'}
      paddingLeft={'30px'}
      paddingRight={'30px'}
      paddingTop={'30px'}
      height={'100%'}
    >
      <>
        <Box display={'flex'} justifyContent={'end'} width={'100%'} marginBottom={'1rem'}>
          <Button type="button" onClick={onAddPayment}>Add Payment</Button>
        </Box>
        {data.length > 0 ? (
          <TableContainer sx={{ paddingBottom: '30px' }}>
            <Table>
              <TableHead>
                <TableRow>
                  {headerContent.map((val) => (
                    <TableCell sx={{ color: globalColors.gray }}>{val}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((item, index) => (
                  <ContentRow data={item} key={index} id={item?.objectId} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NotFoundLottie
            showButton={false}
            text="Looks like this organization has made no payments yet. Press the button above to add a new payment manually."
            buttonText=""
            navigateTo=""
          />
        )}

      </>
    </Box>
  );
};

export default ContentTable;
