import {
  Typography,
  TableContainer,
  TableBody,
  TableRow,
  Table,
  TableHead,
  Paper,
  styled,
  Box,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Sidebar from "../../sidebar";
import { globalColors } from "../../../../utils/constants/color";
import {
  selectColor,
  selectNavigationLayout,
  selectThemeMode,
} from "../../../../store/selector";
import { drawerWidth } from "../../../../utils/constants/drawerWidth";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import OrganizationRepository from "../../../../utils/api/repositories/organizationRepository";
import {
  PermissionsInterface,
  PricingSchedules,
  PricingTiers,
  getPermissionsList,
} from "../../../../utils/helpers";
import UserRepository from "../../../../utils/api/repositories/userRepository";
import { UserRole } from "../../../../store/user";
import Loader from "../../../atoms/loader";
import NoPermission from "../../../atoms/noPermission";
import IosSwitch from "../../IosSwitch";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Form, Formik, FormikHelpers, FormikProps } from "formik";
import GroupInput from "../../groupInput";
import { Button, FieldInput, Toast } from "../../../atoms";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setAddNew } from "../../../../store/routes";
import Dropdowndata from "../../../atoms/dropdown2";
import NotFoundLottie from "../../../atoms/notFound";
import Dropdown from "../../../atoms/dropdown";
import { generateCountryList } from "../../../../utils/helpers/countries";
import OrganizationModel from "../../../../utils/api/models/organizationModel";

const permissionsList = getPermissionsList();

interface CountsInterface {
  total: number;
  existing: number;
}

interface ValuesAddOrganization {
  adminEmail: string;
  adminPassword: string;
  name: string;
  businessLocation: string;
  memberCount: string;
  nextPaymentDue: string;
  ownerName: string;
  ownerEmail: string;
  paymentCycle: string;
  perMonthAmount: number;
  tier: string;
  amountPaid: number;
}

interface ErrorsAddOrganization {
  adminEmail: string;
  adminPassword: string;
  name: string;
  businessLocation: string;
  memberCount: string;
  nextPaymentDue: string;
  ownerName: string;
  ownerEmail: string;
  paymentCycle: string;
  perMonthAmount: string;
  tier: string;
  amountPaid: string;
}

type Manager = {
  id: string;
  adminEmail: string;
  adminPassword: string;
  user: any;
};

const AddOrganization = () => {
  const formikRef = useRef<FormikProps<ValuesAddOrganization>>(null);
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const navigation = useSelector(selectNavigationLayout);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [counts, setCounts] = useState<CountsInterface>({
    total: -1,
    existing: -1,
  });
  const [hasPermission, setHasPermission] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const style = {
    fontFamily: "Poppins",
    fontWeight: "500",
    fontSize: "12px",
    color: mode === "dark" ? globalColors.white : globalColors.black,
    whiteSpace: "nowrap",
    textTransform: "capitalize",
    sm: {
      width: "150px",
      maxWidth: "150px",
    },
    lg: {
      width: "250px",
      maxWidth: "250px",
    },
  };

  return (
    <Box sx={{ display: "flex" }}>
      <div>
        <Sidebar text={"Organizations / Add"} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor:
            mode === "dark" ? globalColors?.black : globalColors?.white,
          minHeight: "100vh",
          overflow: "auto",
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : "",
          },
          paddingTop: navigation
            ? { xl: "80px", lg: "80px", md: "80px", sm: "10px", xs: "10px" }
            : "",
          marginTop: {
            sm: navigation ? "0px" : `130px`,
          },
        }}
      >
        {loading ? (
          <Loader />
        ) : hasPermission ? (
          <Box width={"100%"} padding={3}>
            <FormAddMemberStyle>
              <Formik
                innerRef={formikRef}
                enableReinitialize
                initialValues={{
                  adminEmail: "",
                  adminPassword: "",
                  name: "",
                  businessLocation: "Pakistan",
                  memberCount: "",
                  nextPaymentDue: "",
                  ownerName: "",
                  ownerEmail: "",
                  paymentCycle: "",
                  perMonthAmount: 0,
                  tier: "",
                  amountPaid: 0,
                }}
                validate={(values) => {
                  const errors = {} as ErrorsAddOrganization;
                  const regex = new RegExp(
                    /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
                  );
                  if (!regex.test(values.adminEmail)) {
                    errors.adminEmail = "Please enter a valid email";
                  }
                  const passwordRegex = new RegExp(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}|:<>?]).{8,}$/
                  );
                  if (!passwordRegex.test(values.adminPassword)) {
                    errors.adminPassword =
                      "Admin Password does not meet the Password Policy requirements. Password must be at least 8 characters long, and have at least one uppercase character, one lowercase character, one number and one special character (!, $, #, ^, etc.).";
                  }
                  if (!values.name || !values.name.trim()) {
                    errors.name = "Required";
                  }
                  if (
                    !values.businessLocation ||
                    !values.businessLocation.trim()
                  ) {
                    errors.businessLocation = "Required";
                  }

                  if (!values.ownerName || !values.ownerName.trim()) {
                    errors.ownerName = "Required";
                  }
                  if (!values.ownerEmail || !values.ownerEmail.trim()) {
                    errors.ownerEmail = "Required";
                  }

                  if (
                    !values.paymentCycle ||
                    !values.paymentCycle.trim() ||
                    values.paymentCycle === "Select"
                  ) {
                    errors.paymentCycle = "Required";
                  }
                  if (
                    !values.tier ||
                    !values.tier.trim() ||
                    values.tier === "Select"
                  ) {
                    errors.tier = "Required";
                  }
                  if (
                    !values.memberCount ||
                    !values.memberCount.trim() ||
                    values.memberCount === "Select"
                  ) {
                    errors.memberCount = "Required";
                  }
                  if (!values.perMonthAmount || values.perMonthAmount <= 0) {
                    errors.perMonthAmount = "Please enter a valid amount";
                  }
                  if (!values.amountPaid || values.amountPaid <= 0) {
                    errors.amountPaid = "Please enter a valid amount";
                  }
                  if (!values.nextPaymentDue || !values.nextPaymentDue.trim()) {
                    errors.nextPaymentDue = "Required";
                  }

                  return errors;
                }}
                onSubmit={async (
                  values: ValuesAddOrganization,
                  {
                    setSubmitting,
                    resetForm,
                  }: FormikHelpers<ValuesAddOrganization>
                ) => {
                  try {
                    const organizationRepository = new OrganizationRepository();
                    const organization: OrganizationModel = {
                      businessLocation: values.businessLocation,
                      memberCount:
                        values.memberCount === "1 - 20"
                          ? 20
                          : values.memberCount === "21 - 50"
                          ? 50
                          : values.memberCount === "51 - 100"
                          ? 100
                          : 200,
                      name: values.name,
                      nextPaymentDue: new Date(values.nextPaymentDue),
                      ownerEmail: values.ownerEmail,
                      ownerName: values.ownerName,
                      paymentCycle:
                        values.paymentCycle === "Biannual"
                          ? PricingSchedules.Biannual
                          : PricingSchedules.Annual,
                      perMonthAmount: values.perMonthAmount,
                      tier:
                        values.tier === "Essential"
                          ? PricingTiers.Essential
                          : PricingTiers.Professional,
                    };
                    const signedUpOrganization =
                      await organizationRepository.signUpNewOrganization(
                        organization,
                        values.adminEmail,
                        values.adminPassword,
                        values.amountPaid
                      );
                    if (signedUpOrganization) {
                      Toast(
                        "New organization has been successfully signed up and the admin account has been setup successfully.",
                        "success"
                      );
                      resetForm();
                      dispatch(setAddNew({ addNew: false, path: "/" }));
                      navigate("/dashboard");
                    } else {
                      Toast(
                        "There was an error signing this organization up. Please try again.",
                        "error"
                      );
                    }
                  } catch (error) {
                    Toast(
                      "There was an error signing this organization up. Please try again.",
                      "error"
                    );
                    console.log("ERROR SUBMITTING FORM:", error);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                }) => (
                  <Form onSubmit={handleSubmit}>
                    <Box display="flex" flexDirection="column" gap="1.5rem">
                      <GroupInput className="group-input">
                        <FieldInput
                          label="Admin Email"
                          id="adminEmail"
                          name="adminEmail"
                          placeholder="Admin Email"
                          optional={false}
                          type="text"
                          error={
                            errors.adminEmail && touched.adminEmail
                              ? errors.adminEmail
                              : false
                          }
                        />
                        <FieldInput
                          label="Admin Password"
                          id="adminPassword"
                          name="adminPassword"
                          placeholder="Admin Password"
                          optional={false}
                          isPassword={true}
                          isShowPassword={showPassword}
                          onShowPassword={() => {
                            setShowPassword(!showPassword);
                          }}
                          type={showPassword ? "text" : "password"}
                          error={
                            errors.adminPassword && touched.adminPassword
                              ? errors.adminPassword
                              : false
                          }
                        />
                      </GroupInput>
                      <GroupInput className="group-input">
                        <FieldInput
                          label="Organization Name"
                          id="name"
                          name="name"
                          placeholder="Name"
                          optional={false}
                          type="text"
                          error={
                            errors.name && touched.name ? errors.name : false
                          }
                        />
                        <Dropdown
                          label="Location"
                          id="location"
                          name="location"
                          optional={false}
                          values={generateCountryList()}
                          defaultValue="Pakistan"
                          mode={mode}
                          error={
                            errors.businessLocation && touched.businessLocation
                              ? errors.businessLocation
                              : false
                          }
                        />
                      </GroupInput>
                      <GroupInput className="group-input">
                        <FieldInput
                          label="Owner Name"
                          id="ownerName"
                          name="ownerName"
                          placeholder="Owner Name"
                          optional={false}
                          type="text"
                          error={
                            errors.ownerName && touched.ownerName
                              ? errors.ownerName
                              : false
                          }
                        />
                        <FieldInput
                          label="Owner Email"
                          id="ownerEmail"
                          name="ownerEmail"
                          placeholder="Owner Email"
                          optional={false}
                          type="text"
                          error={
                            errors.ownerEmail && touched.ownerEmail
                              ? errors.ownerEmail
                              : false
                          }
                        />
                      </GroupInput>

                      <GroupInput className="group-input">
                        <Dropdown
                          label="Payment Cycle"
                          id="paymentCycle"
                          name="paymentCycle"
                          optional={false}
                          values={["Select", "Biannual", "Annual"]}
                          defaultValue="Select"
                          mode={mode}
                          error={
                            errors.paymentCycle && touched.paymentCycle
                              ? errors.paymentCycle
                              : false
                          }
                        />
                        <Dropdown
                          label="Tier"
                          id="tier"
                          name="tier"
                          optional={false}
                          values={["Select", "Essential", "Professional"]}
                          defaultValue="Select"
                          mode={mode}
                          error={
                            errors.tier && touched.tier ? errors.tier : false
                          }
                        />
                      </GroupInput>

                      <GroupInput className="group-input">
                        <Dropdown
                          label="Member Count"
                          id="memberCount"
                          name="memberCount"
                          optional={false}
                          values={[
                            "Select",
                            "1 - 20",
                            "21 - 50",
                            "51 - 100",
                            "101 - 200",
                          ]}
                          defaultValue="Select"
                          mode={mode}
                          error={
                            errors.memberCount && touched.memberCount
                              ? errors.memberCount
                              : false
                          }
                        />
                        <FieldInput
                          label="Per Month Amount (USD)"
                          id="perMonthAmount"
                          name="perMonthAmount"
                          placeholder="Per Month Amount (USD)"
                          optional={false}
                          type="number"
                          error={
                            errors.perMonthAmount && touched.perMonthAmount
                              ? errors.perMonthAmount
                              : false
                          }
                        />
                      </GroupInput>

                      <GroupInput className="group-input">
                        <FieldInput
                          label="Amount Paid (USD)"
                          id="amountPaid"
                          name="amountPaid"
                          placeholder="Amount Paid (USD)"
                          optional={false}
                          type="number"
                          error={
                            errors.amountPaid && touched.amountPaid
                              ? errors.amountPaid
                              : false
                          }
                        />
                        <FieldInput
                          label="Next Payment Due"
                          id="nextPaymentDue"
                          name="nextPaymentDue"
                          placeholder="Next Payment Due"
                          optional={false}
                          type="date"
                          error={
                            errors.nextPaymentDue && touched.nextPaymentDue
                              ? errors.nextPaymentDue
                              : false
                          }
                        />
                      </GroupInput>
                    </Box>

                    {/* {role === UserRole.executive && (
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"start"}
                          alignItems={"start"}
                        >
                          <Dropdowndata
                            label="Managed By"
                            id="managedBy"
                            name="managedBy"
                            optional={false}
                            mode={mode}
                            values={[
                              { label: `Select`, value: "" },
                              ...managers.map((user) => ({
                                label: `${user.adminEmail} ${user.adminPassword}`,
                                value: user.id,
                              })),
                            ]}
                            defaultValue=""
                          />
                          <StyledTypography>
                            {touched.managedBy && errors.managedBy
                              ? errors.managedBy
                              : ""}
                          </StyledTypography>
                        </Box>
                      )} */}
                    <Box
                      display={"flex"}
                      justifyContent={"center"}
                      marginTop={"3rem"}
                    >
                      <Button type="submit">
                        {isSubmitting ? "Loading..." : "Add"}
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </FormAddMemberStyle>
          </Box>
        ) : (
          <NoPermission />
        )}
      </Box>
    </Box>
  );
};

const FormAddMemberStyle = styled("div")(({ theme }) => ({
  padding: "2rem",
  borderRadius: "20px",
  backgroundColor: "transparent",
  marginBottom: "2rem",
  // marginTop: '2rem',
  zIndex: 99999999,
  // TODO : style : fix component Link login
  // TODO : Responsive test
  // [theme.breakpoints.up('sm')]: {
  //   width: 250,
  // },
  // [theme.breakpoints.up('md')]: {
  //   width: 350,
  // },
  // [theme.breakpoints.up('lg')]: {
  //   width: 450,
  // },
  // [theme.breakpoints.up('xl')]: {
  //   backgroundColor: 'pink',
  //   width: '1002px',
  // },
  fontSize: "14px",
  overflow: "auto",
  "& input": {
    marginBottom: 0,
  },
  "& .group-input": {
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      // backgroundColor: 'cyan !important',
      flexDirection: "column",
    },
  },
  "& .wrapper-btn-submit": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "2.5rem",
    alignItems: "center",
    "& .btn-submit": {
      marginBottom: "1rem",
      width: "291px",
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  "& button": {
    textTransform: "none",
  },
}));

const Heading = styled(Typography)({
  fontFamily: "Poppins",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "28px",
  letterSpacing: "-0.02em",
  textAlign: "left",
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: "8px",
  color: "red",
  fontSize: "10px",
}));

export default AddOrganization;
