import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import GalleryModel from '../models/galleryModel';
import ImportDataModel from '../models/fileModel';
import UserRepository from './userRepository';

class FileRepository {
  private className = 'File';

  constructor() {}

  public async create(object: ImportDataModel) {

    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      const File = Parse.Object.extend(this.className);
      const file = new File();
  
      const uploadFile = new Parse.File(object.file.name, object.file);
  
      file.set('file', uploadFile);
      file.set('createdBy', currentUser);
      file.set('size', object.file.size);
  
      try {
          return new Promise<Parse.Object | null>((resolve, reject) => {
              file.save(null, { useMasterKey: true }).then(
                  (savedFile: Parse.Object | null) => {
                      // Toast(`Gallery object added successfully!`, 'success');
                      resolve(savedFile);
                  },
                  (error: any) => {
                      Toast('File could not be uploaded successfully. Please try again.', 'error');
                      resolve(null);
                  }
              );
          });
      }
      catch {
          Toast('File could not be uploaded successfully. Please try again.', 'error');
          return null;
      }
    }
    else {
      return null;
    }
  }

  public async getObjectById(objectId: string) {
      const File = Parse.Object.extend(this.className);
      const query = new Parse.Query(File);
      return await query.get(objectId, { useMasterKey: true });
  }

  public async delete(objectId: string) {
    const File = Parse.Object.extend(this.className);
    const query = new Parse.Query(File);
    const file = await query.get(objectId, { useMasterKey: true });
    if (file) {
      const execute = await file.destroy({ useMasterKey: true });
  
      return execute;
    }
  }

  public async getStorageTotal() {
    try {
      const File = Parse.Object.extend(this.className);
      const query = new Parse.Query(File);

      const files = await query.find();

      if (files) {
        let total = 0;
        for (let i = 0; i < files.length; i++) {
          total += files[i].get('size');
        }
        return total;
      }
      else {
        return null;
      }
    }
    catch (e) {
      return null;
    }
  }
}

export default FileRepository;
