import React, { useEffect, useState } from "react";
import {
  Box,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { globalColors } from "../../../../../../utils/constants/color";
import { useDispatch, useSelector } from "react-redux";
import { selectColor, selectThemeMode } from "../../../../../../store/selector";
import { setAddNew } from "../../../../../../store/routes";
import { GoArrowUpRight } from "react-icons/go";
import PaymentModel from "../../../../../../utils/api/models/paymentModel";
import { formatDate } from "../../../../../../utils/helpers";

interface ContentRowProp {
  id?: string;
  data: PaymentModel;
}

const ContentRow: React.FC<ContentRowProp> = ({ data, id }) => {
  // Menu
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const dispatch = useDispatch();

  const style = {
    color: mode === "dark" ? globalColors.white : globalColors.black,
    whiteSpace: "nowrap",
    fontSize: "12px",
    fontWeight: "400",
    textTransform: "none",
    sm: {
      width: "150px",
      maxWidth: "150px",
    },
    lg: {
      width: "250px",
      maxWidth: "250px",
    },
  };

  return (
    <TableRow
      sx={{
        backgroundColor:
          mode === "dark" ? globalColors.black : globalColors.white,
        width: "100%",
        color: mode === "dark" ? globalColors.white : globalColors.black,
        borderRadius: "10px",
        gap: "20px",
        marginBottom: "20px",
        paddingBottom: "20px",
        borderBottom: `20px solid ${
          mode === "dark" ? globalColors.blackLight : globalColors.lightgray
        }`,
      }}
      key={data.objectId}
    >
      {/* payment Id */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={"flex"} gap={"10px"}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "28px",
                letterSpacing: "-2%",
                color:
                  mode === "dark" ? globalColors.white : globalColors.black,
              }}
            >
              {data?.paymentId || "-"}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* Type */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={"flex"} gap={"10px"}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "28px",
                letterSpacing: "-2%",
                color:
                  mode === "dark" ? globalColors.white : globalColors.black,
              }}
            >
              {data?.type || "-"}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* amount */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={"flex"} gap={"10px"}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "28px",
                letterSpacing: "-2%",
                color:
                  mode === "dark" ? globalColors.white : globalColors.black,
              }}
            >
              {data?.amount ? data.amount / 100 : "-"}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* Date */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={"flex"} gap={"10px"}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "28px",
                letterSpacing: "-2%",
                color:
                  mode === "dark" ? globalColors.white : globalColors.black,
              }}
            >
              {data.createdAt ? formatDate(data?.createdAt) : "-"}
            </Typography>
          </Box>
        </Box>
      </TableCell>

      {/* Status */}
      <TableCell sx={style} component="th" scope="row">
        <Box display={"flex"} gap={"10px"}>
          <Box display={"flex"} flexDirection={"column"}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: 500,
                fontSize: "12px",
                lineHeight: "28px",
                letterSpacing: "-2%",
                color:
                  mode === "dark" ? globalColors.white : globalColors.black,
              }}
            >
              {data?.status || "-"}
            </Typography>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ContentRow;
