import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/system";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { Box, Button, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Form, Formik } from "formik";
import {
  selectColor,
  selectCommissionFilter,
  selectOrganizationFilter,
  selectThemeMode,
} from "../../../../../store/selector";
import {
  FilterCommissionDataFtn,
  FilterDataFtn,
  FilterOrganizationDataFtn,
} from "../../../../../store/slices";
import { ButtonFilter, FieldInput } from "../../../../atoms";
import { globalColors } from "../../../../../utils/constants/color";
import Dropdown from "../../../../atoms/dropdown";

interface FilterStyleProps {
  mode: string;
}
interface FilterValues {
  status: string;
  usersMin: number;
  usersMax: number;
}

interface FormValuesErrors {
  status: string;
  usersMin: string;
  usersMax: string;
}

interface FilterLeadsProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function FilterOrganization({
  setOpen,
  open,
}: FilterLeadsProps) {
  const [anchor, setAnchor] = React.useState<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const filterData = useSelector(selectOrganizationFilter);
  const popupRef = useRef<HTMLDivElement>(null);

  const handleFilterValues = async (values: FilterValues) => {
    const { status, usersMin, usersMax } = values;
    dispatch(FilterOrganizationDataFtn({ status, usersMin, usersMax }));
  };

  const handleClick = (event: any) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        popupRef.current &&
        !popupRef.current.contains(event.target as Node)
      ) {
        setAnchor(null);
        if (anchor === null) {
          setOpen(!open);
        }
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [anchor]);

  return (
    <>
      <Box
        // ref={setAnchor}
        onClick={() => setOpen(!open)}
        width={"100%"}
      >
        <ButtonFilter bgColor="black" onClick={handleClick} />
      </Box>
      <BasePopup
        style={{
          left: "-42px",
        }}
        anchor={anchor}
        open={open}
        withTransition
      >
        {(props: any) => (
          <PopAnimation {...props}>
            <PopupBody mode={mode} ref={popupRef}>
              <Formik
                initialValues={{
                  status: filterData?.status || "Select",
                  usersMin: filterData?.usersMin || 0,
                  usersMax: filterData?.usersMax || 0,
                }}
                validate={(values) => {
                  const errors = {} as FormValuesErrors;

                  return errors;
                }}
                onSubmit={async (values, { resetForm, setSubmitting }) => {
                  await handleFilterValues(values);

                  setOpen(false);
                  setSubmitting(false);
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                }) => (
                  <Form>
                    <Box display={"flex"} flexDirection={"column"} gap={"15px"}>
                      <Dropdown
                        showCheckBox={true}
                        checked={values?.status !== "Select"}
                        label="Status"
                        mode={mode}
                        id="status"
                        name="status"
                        values={["Select", "Active", "Un Active"]}
                        defaultValue={values.status || "Select"}
                        onUnchecked={() => {
                          setFieldValue("status", "Select");
                        }}
                      />
                      <FieldInput
                        checked={values.usersMin !== 0}
                        label="Minimum Users"
                        showCheckBox={true}
                        onUnchecked={() => {
                          setFieldValue("usersMin", 0);
                        }}
                        mode={mode}
                        id="usersMin"
                        name="usersMin"
                        placeholder="Min"
                        type="number"
                        min={0}
                      />
                      {touched.usersMin && errors.usersMin && (
                        <Typography
                          color={"red"}
                          fontSize={"12px"}
                          marginTop={"2px"}
                        >
                          {errors.usersMin}
                        </Typography>
                      )}
                      <FieldInput
                        showCheckBox={true}
                        label="Maximum Users"
                        checked={values.usersMax !== 0}
                        onUnchecked={() => {
                          setFieldValue("usersMax", 0);
                        }}
                        mode={mode}
                        id="usersMax"
                        name="usersMax"
                        placeholder="Max"
                        type="number"
                        min={0}
                      />
                      {touched.usersMax && errors.usersMax && (
                        <Typography
                          color={"red"}
                          fontSize={"12px"}
                          marginTop={"2px"}
                        >
                          {errors.usersMax}
                        </Typography>
                      )}

                      <Box
                        display={"flex"}
                        justifyContent={"space-evenly"}
                        gap={"15px"}
                        marginTop={"20px"}
                      >
                        <Button
                          sx={{
                            border: `1px solid ${color || globalColors.blue}`,
                            color:
                              mode === "dark"
                                ? globalColors.white
                                : globalColors.black,
                            width: "100%",
                            borderRadius: "12px",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            "&:hover": {
                              backgroundColor: color || globalColors.blue,
                            },
                          }}
                          type="button"
                          onClick={() => {
                            handleSubmit();
                          }}
                          disabled={isSubmitting}
                        >
                          {isSubmitting ? "Loading..." : "Apply"}
                        </Button>
                      </Box>
                    </Box>
                  </Form>
                )}
              </Formik>
            </PopupBody>
          </PopAnimation>
        )}
      </BasePopup>
    </>
  );
}

function Animated(
  props: React.PropsWithChildren<{
    className?: string;
    requestOpen: boolean;
    onEnter: () => void;
    onExited: () => void;
  }>
) {
  const { requestOpen, onEnter, onExited, children, className } = props;

  React.useEffect(() => {
    if (requestOpen) {
      onEnter();
    }
  }, [onEnter, requestOpen]);

  const handleAnimationEnd = React.useCallback(() => {
    if (!requestOpen) {
      onExited();
    }
  }, [onExited, requestOpen]);

  return (
    <div
      onAnimationEnd={handleAnimationEnd}
      className={className + (requestOpen ? " open" : " close")}
    >
      {children}
    </div>
  );
}

const PopAnimation = styled(Animated)`
  @keyframes open-animation {
    0% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  @keyframes close-animation {
    0% {
      opacity: 1;
      transform: translateY(0) scale(1);
    }

    50% {
      opacity: 1;
      transform: translateY(4px) scale(1.05);
    }

    100% {
      opacity: 0;
      transform: translateY(-8px) scale(0.95);
    }
  }

  &.open {
    animation: open-animation 0.4s ease-in forwards;
  }

  &.close {
    animation: close-animation 0.4s ease-in forwards;
  }
`;

const PopupBody = styled("div")<FilterStyleProps>(
  ({ theme, mode }) =>
    `
    width: max-content;
    padding: 0.5rem 1rem;
    margin: 8px;
    // border: 1px solid ${mode === "dark" ? "" : ""};
     background-color: ${
       mode === "dark" ? globalColors.black : globalColors.lightgray
     };
    border-radius: 8px;
     box-shadow: ${
       mode === "dark"
         ? `0px 4px 8px rgb(0 0 0 / 0.7)`
         : `0px 4px 8px rgb(0 0 0 / 0.1)`
     };
    min-height: 3rem;
    display: flex;
    align-items: center;
`
);
