import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EnumViews, ICover, IImages } from './projects';
import { StatusProduct } from '../utils/types/products';
import { ICBed, ICCoffe, ICShower } from '../assets/Icons';

export interface IAmenities {
  label: string;
  value: string;
  icon?: string;
}

const defaultAmenities = [
  {
    label: 'Kitchen',
    value: '',
  },
  {
    label: 'Bedroom',
    value: '',
  },
  {
    label: 'Bathroom',
    value: '',
  },
];
export interface IFormDetailProperty {
  id: string;
  cover: ICover;
  name: string;
  // type: string;
  country: string;
  state: string;
  city: string;
  address: string;
  amenities: IAmenities[];
  area: string;
  areaUnit: string;
  price: string;
  assignToProject: string;
  // space: string;
  status: StatusProduct | '';
  description: string;
  images: IImages[];
  contactName: string;
  contactPhone: string;
  contactSecondaryPhone: string;
  priority: string;
  sellingPrice?: string;
  soldAt?: string;
  soldBy?: string;
  attachments?: File[]
}
interface FilterData {
  status: string;
  priceLow: number;
  priceHigh: number;
  location: string;
  minArea: number;
  maxArea: number;
}

interface PropertiesDataState {
  isLoading: boolean;
  viewActive: EnumViews;
  formProperty: IFormDetailProperty;
  properties: IFormDetailProperty[];
  filterData: FilterData;
  Search: string;
}

const initialState: PropertiesDataState = {
  isLoading: false,
  viewActive: EnumViews.Cards,
  formProperty: {
    id: '',
    cover: {
      files: [],
      preview: '',
    },
    name: '',
    country: 'Pakistan',
    state: '',
    city: '',
    address: '',
    assignToProject: '',
    amenities: defaultAmenities,
    price: '',
    area: '',
    areaUnit: '',
    status: '',
    description: '',
    contactName: '',
    contactPhone: '',
    contactSecondaryPhone: '',
    images: [],
    priority: '',
    sellingPrice: '0',
    soldAt: `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date()
      .getDate()
      .toString()
      .padStart(2, '0')}`,
    soldBy: '',
    attachments: []
  },
  properties: [],
  filterData: {
    status: '',
    priceLow: 0,
    priceHigh: 0,
    location: '',
    minArea: 0,
    maxArea: 0,
  },
  Search: '',
};

const PropertiesReducer = createSlice({
  name: 'Properties',
  initialState,
  reducers: {
    // * Root project page
    onSwitchView: (state, action: PayloadAction<EnumViews>) => {
      state.viewActive = action.payload;
    },
    updateFieldProperty: (state, action: PayloadAction<{ field: keyof IFormDetailProperty; value: string }>) => {
      const { field, value } = action.payload;

      state.formProperty = { ...state.formProperty, [field]: value };
    },
    updateCoverProperty: (state, action: PayloadAction<{ value: ICover }>) => {
      const { value } = action.payload;

      // * Handling changes images
      state.formProperty = {
        ...state.formProperty,
        cover: value,
      };
    },
    deleteCoverProperty: (state) => {
      state.formProperty = {
        ...state.formProperty,
        cover: {
          files: [],
          preview: '',
        },
      };
    },
    deleteOneImageProperty: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      // delete one picture by id
      const files = [];
      const preview = [];

      const prevStateImages = state.formProperty.images;
      prevStateImages.map((image) => {
        if (image.id !== id) {
          if (image.file) {
            const process = URL.createObjectURL(image.file);
            preview.push(process);

            files.push(image);
          }

          return;
        }
      });
    },
    updateAttachments: (state, action: PayloadAction<{files: File[]}>) => {
      state.formProperty = {
        ...state.formProperty,
        attachments: action.payload.files
      }
    },
    updateImagesProperty: (state, action: PayloadAction<{ newImages: IImages[] }>) => {
      const { newImages } = action.payload;
      const stateImages = state.formProperty.images;
      newImages.forEach((images) => {
        const isImageExist = stateImages.find((image) => image.id === images.id);
        if (!isImageExist) {
          stateImages.push(images);
        }
      });
      // images.push(value);

      // * Handling changes images
      state.formProperty = {
        ...state.formProperty,
        images: stateImages,
      };
    },
    deleteImageProperty: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      const stateImages = state.formProperty.images;
      // delete image by id from array stateImages
      const updatedImages = stateImages.filter((image) => image.id !== id);

      state.formProperty = {
        ...state.formProperty,
        images: updatedImages,
      };
    },
    setDataProperties: (state, action: PayloadAction<IFormDetailProperty[]>) => {
      state.properties = action.payload;
    },
    addNewProperty: (state, action: PayloadAction<IFormDetailProperty>) => {
      state.properties = [...state.properties, action.payload];
    },
    resetFormProperty: (state) => {
      state.formProperty = initialState.formProperty;
    },
    setFormEditProperty: (state, action: PayloadAction<IFormDetailProperty>) => {
      state.formProperty = action.payload;
    },
    updateProperty: (state, action: PayloadAction<IFormDetailProperty>) => {
      state.properties = state.properties.map((property) => (property.id === action.payload.id ? action.payload : property));
    },
    // * Amenities
    addAminitiesItem: (state, action: PayloadAction<IAmenities>) => {
      state.formProperty.amenities = [...state.formProperty.amenities, action.payload];
    },
    changeValueAmenities: (state, action: PayloadAction<{ field: keyof IAmenities; value: string }>) => {
      const { value, field } = action.payload;
      state.formProperty.amenities.map((item) => {
        if (item.label === field) {
          item.value = value;
        }
      });
    },
    onLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    FilterDataFtn: (state, action: PayloadAction<FilterData>) => {
      state.filterData = action.payload;
    },
    SearchProperty: (state, action: PayloadAction<string>) => {
      state.Search = action.payload;
    },
  },
});

export const {
  onSwitchView,
  FilterDataFtn,
  updateFieldProperty,
  updateCoverProperty,
  addNewProperty,
  SearchProperty,
  updateImagesProperty,
  resetFormProperty,
  setFormEditProperty,
  updateProperty,
  addAminitiesItem,
  setDataProperties,
  changeValueAmenities,
  deleteCoverProperty,
  deleteImageProperty,
  onLoading,
  updateAttachments
} = PropertiesReducer.actions;

export default PropertiesReducer.reducer;
