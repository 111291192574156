import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EnumViews } from './projects';
import { EnumCommissionModes } from './commission';

interface FilterData {
  status: string;
  assignedTo: string;
  priority: string;
}

interface FilterEmailData {
  from: string;
  to: string;
  date: string;
}

interface FilterCommissionData {
  role: string;
  commissionPriority: string;
  commissionLow: number;
  commissionHigh: number;
}

interface FilterEmployeeData {
  role: string;
}

interface FilterOrganizationData {
  status: string;
  usersMin: number;
  usersMax: number;
}

export interface RootState {
  UseData: {
    organizationTier: string;
    organizationLogo: string;
    color: string;
    thememode: string;
    navigationlayout: boolean;
    bulkedit: boolean;
    viewActive: EnumViews;
    loader: boolean;
    filterData: FilterData;
    filterEmailData: FilterEmailData;
    filterCommissionData: FilterCommissionData;
    filterOrganizationData: FilterOrganizationData;
    editorValue: string;
    addNew: boolean;
    sortEmailData: string;
    sortCommissionData: string;
    commissionMode: EnumCommissionModes;
    filterEmployeeData: FilterEmployeeData;
  };
}

interface AppDataState {
  organizationTier: string;
  organizationLogo: string;
  color: string;
  thememode: string;
  editorValue: string;
  navigationlayout: boolean;
  bulkedit: boolean;
  viewActive: EnumViews;
  loader: boolean;
  filterData: FilterData;
  filterEmailData: FilterEmailData;
  filterCommissionData: FilterCommissionData;
  filterOrganizationData: FilterOrganizationData;
  sortEmailData: string;
  sortCommissionData: string;
  commissionMode: EnumCommissionModes;
  refreshPermissions: boolean;
  reSortTasks: boolean;
  refreshTasks: boolean;
  filterEmployeeData: FilterEmployeeData;
  newNotifications: boolean;
}
const initialState: AppDataState = {
  organizationTier: '',
  organizationLogo: '',
  color: '',
  editorValue: '',
  thememode: 'dark',
  navigationlayout: true,
  bulkedit: false,
  loader: false,
  viewActive: EnumViews.Cards,
  filterData: {
    status: '',
    assignedTo: '',
    priority: '',
  },
  filterEmailData: {
    from: '',
    to: '',
    date: '',
  },
  filterCommissionData: {
    role: 'Select',
    commissionPriority: 'Select',
    commissionHigh: 100,
    commissionLow: 0,
  },
  filterOrganizationData: {
    status: 'Select',
    usersMin: 0,
    usersMax: 0,
  },
  sortEmailData: 'New',
  sortCommissionData: 'Descending',
  commissionMode: EnumCommissionModes.Individual,
  refreshPermissions: false,
  reSortTasks: false,
  refreshTasks: false,
  filterEmployeeData: {
    role: 'Select',
  },
  newNotifications: false
};

const appDataSlice = createSlice({
  name: 'Persist Data',
  initialState,
  reducers: {
    setOrganizationTier: (state, action: PayloadAction<string>) => {
      state.organizationTier = action.payload;
    },
    setOrganizationLogo: (state, action: PayloadAction<string>) => {
      state.organizationLogo = action.payload;
    },
    ThemeColorFtn: (state, action: PayloadAction<string>) => {
      state.color = action.payload;
    },
    FloraEditorValue: (state, action: PayloadAction<string>) => {
      state.editorValue = action.payload;
    },
    LoadingFtn: (state, action: PayloadAction<boolean>) => {
      state.loader = !state.loader;
    },
    onSwitchView: (state, action: PayloadAction<EnumViews>) => {
      state.viewActive = action.payload;
    },
    ThemeModeFtn: (state, action: PayloadAction<string>) => {
      state.thememode = action.payload;
    },
    NavigationLayoutFtn: (state, action: PayloadAction<boolean>) => {
      state.navigationlayout = action.payload;
    },
    BulkEditFtn: (state, action: PayloadAction<boolean>) => {
      state.bulkedit = action.payload;
    },
    FilterDataFtn: (state, action: PayloadAction<FilterData>) => {
      state.filterData = action.payload;
    },
    FilterEmailDataFtn: (state, action: PayloadAction<FilterEmailData>) => {
      state.filterEmailData = action.payload;
    },
    sortEmailFtn: (state, action: PayloadAction<string>) => {
      state.sortEmailData = action.payload;
    },
    FilterCommissionDataFtn: (state, action: PayloadAction<FilterCommissionData>) => {
      state.filterCommissionData = action.payload;
    },
    FilterOrganizationDataFtn: (state, action: PayloadAction<FilterOrganizationData>) => {
      state.filterOrganizationData = action.payload;
    },
    sortCommissionFtn: (state, action: PayloadAction<string>) => {
      state.sortCommissionData = action.payload;
    },
    onSwitchMode: (state, action: PayloadAction<EnumCommissionModes>) => {
      state.commissionMode = action.payload;
    },
    refreshPermissions(state, action: PayloadAction<boolean>) {
      state.refreshPermissions = !state.refreshPermissions;
    },
    reSortTasks(state, action: PayloadAction<boolean>) {
      state.reSortTasks = !state.reSortTasks;
    },
    refreshTasks(state, action: PayloadAction<boolean>) {
      state.refreshTasks = !state.refreshTasks;
    },
    FilterEmployeeDataFtn: (state, action: PayloadAction<FilterEmployeeData>) => {
      state.filterEmployeeData = action.payload;
    },
    addNewNotifications: (state) => {
      state.newNotifications = true;
    },
    readNewNotifications: (state) => {
      state.newNotifications = false;
    }
  },
});

export const {
  setOrganizationTier,
  setOrganizationLogo,
  ThemeColorFtn,
  ThemeModeFtn,
  LoadingFtn,
  NavigationLayoutFtn,
  BulkEditFtn,
  onSwitchView,
  FilterDataFtn,
  FloraEditorValue,
  FilterEmailDataFtn,
  sortEmailFtn,
  FilterCommissionDataFtn,
  FilterOrganizationDataFtn,
  sortCommissionFtn,
  refreshPermissions,
  reSortTasks,
  refreshTasks,
  FilterEmployeeDataFtn,
  addNewNotifications,
  readNewNotifications
} = appDataSlice.actions;

export default appDataSlice.reducer;
