import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { globalColors } from '../../../../utils/constants/color';
import { useSelector } from 'react-redux';
import { selectColor, selectThemeMode } from '../../../../store/selector';
import { PiBuildings, PiMoney, PiUsersFour, PiUsersThree } from 'react-icons/pi';
import { BsBuilding, BsBuildingX, BsHouse } from 'react-icons/bs';
import Loader from '../../../atoms/loader';
import { PiUsersFourLight } from 'react-icons/pi';
import { IoEarthOutline } from 'react-icons/io5';
import { TbBuildingCommunity, TbNetwork, TbNetworkOff } from 'react-icons/tb';
import { RiCommunityLine } from 'react-icons/ri';
import { MdOutlineNetworkLocked, MdStorage } from 'react-icons/md';

interface HomeCardProps {
  value: number | string;
  title: string;
  iconName: string;
  loading: boolean;
}

type SocialIcons = {
  [key: string]: JSX.Element;
};

const HomeCard: React.FC<HomeCardProps> = ({ value, title, iconName, loading }) => {
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);

  const socialIcons: SocialIcons = {
    Organizations: (
      <Box
        sx={{
          backgroundColor: color || globalColors.blue,
          width: '3rem',
          height: '3rem',
          borderRadius: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TbNetwork size={'2rem'} />
      </Box>
    ),
    Users: (
      <Box
        sx={{
          backgroundColor: color || globalColors.blue,
          width: '3rem',
          height: '3rem',
          borderRadius: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PiUsersFour size={'2rem'} />
      </Box>
    ),
    TotalRevenue: (
      <Box
        sx={{
          backgroundColor: color || globalColors.blue,
          width: '3rem',
          height: '3rem',
          borderRadius: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PiMoney size={'2rem'} />
      </Box>
    ),
    InactiveOrganizations: (
      <Box
        sx={{
          backgroundColor: globalColors.red,
          width: '3rem',
          height: '3rem',
          borderRadius: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <TbNetworkOff size={'2rem'} />
      </Box>
    ),
    TotalLeads: (
      <Box
        sx={{
          backgroundColor: color || globalColors.blue,
          width: '3rem',
          height: '3rem',
          borderRadius: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PiUsersThree size={'2rem'} />
      </Box>
    ),
    TotalProperties: (
      <Box
        sx={{
          backgroundColor: color || globalColors.blue,
          width: '3rem',
          height: '3rem',
          borderRadius: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BsHouse size={'2rem'} />
      </Box>
    ),
    TotalProjects: (
      <Box
        sx={{
          backgroundColor: color || globalColors.blue,
          width: '3rem',
          height: '3rem',
          borderRadius: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <BsBuilding size={'2rem'} />
      </Box>
    ),
    TotalStorage: (
      <Box
        sx={{
          backgroundColor: color || globalColors.blue,
          width: '3rem',
          height: '3rem',
          borderRadius: '30px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <MdStorage size={'2rem'} />
      </Box>
    ),
  };

  return (
    <Box bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.whiteLight} padding={'1rem 2.5%'} borderRadius={'10px'} width={'100%'}>
      {loading ? (
        <Box alignItems={'center'} display={'flex'} gap={'1rem'} height={'74px'}>
          <Loader />
        </Box>
      ) : (
        <Box alignItems={'center'} display={'flex'} gap={'1rem'}>
          {/* Icons */}
          {socialIcons[iconName]}

          {/* Heading */}
          <Box component={'span'} display={'flex'} flexDirection={'column'} gap={'10px'}>
            <Typography
              sx={{
                fontSize: '20px',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
                fontWeight: '600',
                fontFamily: 'poppins',
                lineHeight: '32px',
              }}
            >
              {value}
            </Typography>
            <Typography
              sx={{
                fontSize: '16px',
                color: mode === 'dark' ? globalColors.white : globalColors.black,
                fontWeight: '400',
                fontFamily: 'poppins',
                lineHeight: '32px',
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

const ButtonStyle = styled('button')<any>((props) => ({
  all: 'unset',
  whiteSpace: 'nowrap',
  cursor: props.disabled ? 'not-allowed' : 'pointer',
  opacity: props.disabled ? '0.5' : '1',
  height: '40px',
  background: useSelector(selectColor) ? useSelector(selectColor) : globalColors.blue,
  color: globalColors.white,
  width: '140px',
  padding: '0px 8px',
  display: 'grid',
  marginTop: '2rem',
  placeItems: 'center',
  fontSize: '1rem',
  fontWeight: 500,
  borderRadius: '12px',
  transition: 'opacity 0.3s ease',
  border: props.type === 'reset' ? `1px solid ${props.color ? props.color : globalColors.blue}` : '',
  '&:hover': {
    opacity: props.disabled ? '0.5' : '0.9',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

export default HomeCard;
