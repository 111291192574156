import Parse, { User } from 'parse';
import { Toast } from '../../../components/atoms';
import PropertyModel, { Picture } from '../models/propertyModel';
import { IFormDetailProperty } from '../../../store/property';
import { IMGDefault } from '../../../assets/Images';
import GalleryRepository from './galleryRepository';
import GalleryModel from '../models/galleryModel';
import { IImages } from '../../../store/projects';
import { getYearQuarter, isArray, isFile } from '../../helpers';
import UserRepository from './userRepository';
import FileRepository from './fileRepository';
import FileModel from '../models/fileModel';

class PropertyRepository {
  private className = 'Property';

  constructor() {}

  public async create(object: PropertyModel) {
    const Property = Parse.Object.extend(this.className);
    const Gallery = Parse.Object.extend('Gallery');
    const property = new Property();

    property.set('price', parseFloat(object.price));
    property.set('name', object.name.toString());
    property.set('contactName', object.contactName.toString());
    property.set('contactPhone', object.contactPhone);
    property.set('area', +object.area);
    property.set('areaUnit', object.areaUnit.toString());

    const status = object.status ? object.status : 'Available';
    property.set('status', status);
    let amenitiesFiltered = object.amenities.filter((a) => a.value != '' && a.value != "0");
    property.set('amenities', amenitiesFiltered);
    property.set('contactSecondaryPhone', object.contactSecondaryPhone);
    property.set('country', object.country);
    property.set('state', object.state);
    property.set('city', object.city);
    property.set('address', { address: object.address });
    property.set('description', object.description);
    property.set('notes', object.notes);
    property.set('createdAt', object.createdAt);
    property.set('priority', object.priority);
    if (object.status === 'Sold') {
      property.set('soldAt', object.soldAt);
      property.set('price', parseFloat(object.sellingPrice ?? '-1'));
      property.set('sellingPrice', parseFloat(object.sellingPrice ?? '-1'));
      if (object.soldBy) {
        const seller = User.createWithoutData(object.soldBy)
        property.set('soldBy', seller);
      }
    }

    if (object.attachments && object.attachments.length > 0) {
      const fileRepository = new FileRepository();
      let savedFiles: any[] = [];
      const attachmentFiles = object.attachments as File[];
      await Promise.all(
        attachmentFiles.map(async (file) => {
          const fileModel: FileModel = {
            file: file,
          };
          const savedFile = await fileRepository.create(fileModel);
          if (savedFile) {
            savedFiles.push(savedFile);
          }
        })
      );
      const attachmentsRelation = property.relation('attachments');
      attachmentsRelation.add(savedFiles);
    }

    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      property.set('createdBy', currentUser);
      property.set('updatedBy', currentUser);
      property.set('organization', currentUser.get('organization'));
    } else {
      property.set('createdBy', null);
    }

    // ! Upload Cover Picture
    if (object.coverPicture && isFile(object.coverPicture)) {
      const coverPicture = new Gallery();
      const storeCoverPicture = new Parse.File(object.coverPicture.name, object.coverPicture);
      storeCoverPicture
        .save({ useMasterKey: true })
        .then((savedFile) => {
          // Add the image to the coverPicture relation
          coverPicture.set('file', savedFile);

          // Save the coverPicture
          coverPicture
            .save(null, { useMasterKey: true })
            .then((savedGallery: any) => {
              // Set the coverPicture relation on the property
              property.set('coverPicture', coverPicture);

              // Save the property
              property
                .save(null, { useMasterKey: true })
                .then((savedProperty: any) => {})
                .catch((error: any) => {});
            })
            .catch((error: any) => {});
        })
        .catch((error) => {});
    }

    // ! Upload pictures
    // ! Pictures
    if (object.pictures.length > 0) {
      const galleryRepository = new GalleryRepository();
      let savedFiles: any[] = [];
      const imagesFiles = object.pictures as File[];

      await Promise.all(
        imagesFiles.map(async (file) => {
          const uploadFile = new Parse.File(file.name, file);
          const galleryModel: GalleryModel = {
            file: uploadFile,
          };
          const savedFile = await galleryRepository.createAndReturn(galleryModel);
          if (savedFile) {
            savedFiles.push(savedFile);
          }
        })
      );
      const galleriesRelation = property.relation('pictures');
      galleriesRelation.add(savedFiles);
    }

    await property.save(null, { useMasterKey: true }).then(
      (propertyRes: any) => {
        // Execute any logic that should take place after the object is saved.
        console.log('New object created with objectId: ' + propertyRes.id);
        return Toast(`Property added successfully.`, 'success');
      },
      (error: Error) => {
        console.log(error);
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        return Toast(`Property could not be added successfuly. Please try again.`, 'error');
        // alert('Failed to create new object, with error code: ' + error.message);
      }
    );
  }

  public async getAll(
    search?: string | null | '',
    status?: string | null,
    priceLow?: number | null,
    priceHigh?: number | null,
    location?: string | null,
    minArea?: number | null,
    maxArea?: number | null
  ): Promise<IFormDetailProperty[]> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const Property = Parse.Object.extend(this.className);
      var query = new Parse.Query(Property).descending('createdAt');
      console.log("SEARCH", search, "STATUS", status, "PRICELOW", priceLow, "PRICEHIGH", priceHigh, "LOCATION", location, "MINAREA", minArea, "MAXAREA", maxArea);

      query.include('coverPicture');
      query.include('pictures');
      query.equalTo('organization', currentUser.get('organization'));
      // console.log(status, price);
      if (status && status !== '') {
        query.equalTo('status', status);
      }
      // if (price && price !== 0) {
      //   query.equalTo('price', price);
      // }
      if (priceLow && priceLow !== 0) {
        query.greaterThanOrEqualTo('price', priceLow);
      }
      if (priceHigh && priceHigh !== 0) {
        query.lessThanOrEqualTo('price', priceHigh);
      }
      if (location && location !== '') {
        query.equalTo('location', location);
      }
      if (minArea && minArea !== 0) {
        query.greaterThanOrEqualTo('area', minArea);
      }
      if (maxArea && maxArea !== 0) {
        query.lessThanOrEqualTo('area', maxArea);
      }
      if (search) {
        const regex = new RegExp(search, 'i');
        query.matches('name', regex);
      }

      try {
        const properties = await query.find({ useMasterKey: true });
        const propertiesDataResult: IFormDetailProperty[] = [];
        await Promise.all(
          properties.map(async (property: any) => {
            const propertyData = property.toJSON();

            // ! Handling cover picture
            const coverPicture = property.get('coverPicture');
            let coverPictureUrl = IMGDefault;
            if (coverPicture) {
              coverPictureUrl = coverPicture.toJSON()?.file?.url;
            }

            const picturesRelation = property.relation('pictures');

            // Query the relation to get the gallery
            const urlPictures: IImages[] = [];
            await picturesRelation
              .query()
              .find({ useMasterKey: true })
              .then((gallery: { [x: string]: any }) => {
                if (gallery) {
                  if (gallery && gallery.length > 0) {
                    gallery.forEach((picture: any) => {
                      const getPicture = picture.toJSON();
                      if (getPicture) {
                        const picture = {
                          id: getPicture.objectId,
                          url: getPicture.file?.url,
                          file: getPicture.file,
                        };
                        urlPictures.push(picture);
                      }
                    });
                  }
                } else {
                  console.error('Gallery not found.');
                }
              })
              .catch((error: { message: string }) => {
                console.error('Error querying gallery relation: ' + error.message);
              });

            const data = {
              id: property.id,
              name: propertyData.name ?? '-',
              cover: {
                files: [],
                preview: coverPictureUrl,
              },
              // type: properrtyData.type ?? '-',
              country: propertyData.country ?? '-',
              state: propertyData.state ?? '-',
              city: propertyData.city ?? '-',
              address: propertyData.address?.address ?? '-',
              assignToProject: propertyData?.assignToproperty ?? '-',
              amenities: propertyData?.amenities ?? [],
              area: propertyData.area ?? '-',
              areaUnit: propertyData?.areaUnit ?? '-',
              price: propertyData.price ?? '-',
              status: propertyData.status ?? '-',
              description: propertyData.description ?? '-',
              contactName: propertyData.contactName ?? '-',
              contactPhone: propertyData.contactPhone ?? '-',
              contactSecondaryPhone: propertyData.contactSecondaryPhone ?? '-',
              images: urlPictures ?? null,
              priority: propertyData.priority ?? '-',
            };

            propertiesDataResult.push(data);
          })
        );

        return propertiesDataResult;
        // return projectModels as unknown as ProjectModel[];
      } catch (error) {
        console.error('Error fetching project:', error);
        return [];
      }
    } else {
      return [];
    }
  }

  public async getSome(): Promise<IFormDetailProperty[]> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const Property = Parse.Object.extend(this.className);
      var query = new Parse.Query(Property).descending('createdAt').limit(5);

      query.include('coverPicture');
      query.include('pictures');
      query.equalTo('organization', currentUser.get('organization'));

      try {
        const properties = await query.find({ useMasterKey: true });
        const propertiesDataResult: IFormDetailProperty[] = [];
        await Promise.all(
          properties.map(async (property: any) => {
            const propertyData = property.toJSON();

            // ! Handling cover picture
            const coverPicture = property.get('coverPicture');
            let coverPictureUrl = IMGDefault;
            if (coverPicture) {
              coverPictureUrl = coverPicture.toJSON()?.file?.url;
            }

            const picturesRelation = property.relation('pictures');

            // Query the relation to get the gallery
            const urlPictures: IImages[] = [];
            await picturesRelation
              .query()
              .find({ useMasterKey: true })
              .then((gallery: { [x: string]: any }) => {
                if (gallery) {
                  if (gallery && gallery.length > 0) {
                    gallery.forEach((picture: any) => {
                      const getPicture = picture.toJSON();
                      if (getPicture) {
                        const picture = {
                          id: getPicture.objectId,
                          url: getPicture.file?.url,
                          file: getPicture.file,
                        };
                        urlPictures.push(picture);
                      }
                    });
                  }
                } else {
                  console.error('Gallery not found.');
                }
              })
              .catch((error: { message: string }) => {
                console.error('Error querying gallery relation: ' + error.message);
              });

            const data = {
              id: property.id,
              name: propertyData.name ?? '-',
              cover: {
                files: [],
                preview: coverPictureUrl,
              },
              // type: properrtyData.type ?? '-',
              country: propertyData.country ?? '-',
              state: propertyData.state ?? '-',
              city: propertyData.city ?? '-',
              address: propertyData.address?.address ?? '-',
              assignToProject: propertyData?.assignToproperty ?? '-',
              amenities: propertyData?.amenities ?? [],
              area: propertyData.area ?? '-',
              areaUnit: propertyData?.areaUnit ?? '-',
              price: propertyData.price ?? '-',
              status: propertyData.status ?? '-',
              description: propertyData.description ?? '-',
              contactName: propertyData.contactName ?? '-',
              contactPhone: propertyData.contactPhone ?? '-',
              contactSecondaryPhone: propertyData.contactSecondaryPhone ?? '-',
              images: urlPictures ?? null,
              priority: propertyData.priority ?? '-',
            };

            propertiesDataResult.push(data);
          })
        );

        return propertiesDataResult;
        // return projectModels as unknown as ProjectModel[];
      } catch (error) {
        console.error('Error fetching project:', error);
        return [];
      }
    } else {
      return [];
    }
  }

  public async getByObjectId(objectId: string): Promise<IFormDetailProperty | null> {
    const Property = Parse.Object.extend(this.className);
    const query = new Parse.Query(Property);

    query.include('coverPicture', 'soldBy');
    return await query
      .get(objectId, { useMasterKey: true })
      .then(async (property) => {
        const propertyData = property.toJSON();

        // ! Handling cover picture
        const coverPicture = property.get('coverPicture');
        let coverPictureUrl = IMGDefault;
        if (coverPicture) {
          
          coverPictureUrl = coverPicture.toJSON()?.file?.url;
        }

        const picturesRelation = property.relation('pictures');

        // Query the relation to get the gallery
        const urlPictures: IImages[] = [];
        await picturesRelation
          .query()
          .find({ useMasterKey: true })
          .then((gallery: { [x: string]: any }) => {
            if (gallery) {
              if (gallery && gallery.length > 0) {
                gallery.forEach((picture: any) => {
                  const getPicture = picture.toJSON();
                  if (getPicture) {
                    const picture = {
                      id: getPicture.objectId,
                      url: getPicture.file?.url,
                      file: getPicture.file,
                    };
                    urlPictures.push(picture);
                  }
                });
              }
            } else {
              console.error('Gallery not found.');
            }
          })
          .catch((error: { message: string }) => {
            // console.error('Error querying gallery relation: ' + error.message);
          });

        const data = {
          id: property.id,
          name: propertyData.name ?? '-',
          cover: {
            files: [],
            preview: coverPictureUrl,
          },
          country: propertyData.country ?? '-',
          state: propertyData.state ?? '-',
          city: propertyData.city ?? '-',
          address: propertyData.address?.address ?? '-',
          assignToProject: propertyData?.assignToproperty ?? '-',
          amenities: propertyData?.amenities ?? [],
          area: propertyData.area ?? '-',
          areaUnit: propertyData?.areaUnit ?? '-',
          price: propertyData.price ?? '-',
          status: propertyData.status ?? '-',
          description: propertyData.description ?? '-',
          contactName: propertyData.contactName ?? '-',
          contactPhone: propertyData.contactPhone ?? '-',
          contactSecondaryPhone: propertyData.contactSecondaryPhone ?? '-',
          images: urlPictures ?? null,
          priority: propertyData.priority ?? '-',
          sellingPrice: propertyData.sellingPrice ?? '0',
          soldAt: propertyData.soldAt
            ? propertyData.soldAt.iso.split('T')[0]
            : `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date()
                .getDate()
                .toString()
                .padStart(2, '0')}`,
          soldBy: property.get('soldBy') ? property.get('soldBy').id : ''
        };

        return data;
      })
      .catch((error) => {
        return null;
      });
  }

  public async getObjectByObjectId(objectId: string): Promise<Parse.Object | null> {
    const Property = Parse.Object.extend(this.className);
    const query = new Parse.Query(Property);

    query.include('coverPicture', 'soldBy');
    return await query
      .get(objectId, { useMasterKey: true })
      .then(async (property) => {
        return property;
      })
      .catch((error) => {
        return null;
      });
  }

  public async updateByObjectId(objectId: string, object: PropertyModel): Promise<any> {
    const Property = Parse.Object.extend(this.className);
    const Gallery = Parse.Object.extend('Gallery');
    const query = new Parse.Query(Property);
    const galleryRepository = new GalleryRepository();

    query.include('coverPicture');

    const property = await query.get(objectId, { useMasterKey: true });

    try {
      if (!property) {
        return false;
      }

      if (property) {
        if (object.name !== property.get('name')) {
          property.set('name', object.name.toString());
        }

        if (object.price) {
          property.set('price', parseFloat(object.price));
        }

        if (object.contactName) {
          property.set('contactName', object.contactName.toString());
        }
        if (object.contactPhone) {
          property.set('contactPhone', object.contactPhone);
        }
        if (object.areaUnit) {
          property.set('areaUnit', object.areaUnit.toString());
        }
        if (object.area) {
          property.set('area', +object.area);
        }
        if (object.areaUnit) {
        }

        if (object.status) {
          const status = object.status ? object.status : 'Hold';
          property.set('status', status);
        }

        if (object.amenities) {
          let amenitiesFiltered = object.amenities.filter((a) => a.value != '' && a.value != "0");
          property.set('amenities', amenitiesFiltered);
        }
        if (object.contactSecondaryPhone) {
          property.set('contactSecondaryPhone', object.contactSecondaryPhone);
        }

        if (object.contactSecondaryPhone) {
        }

        if (object.country) {
          property.set('country', object.country);
        }

        if (object.state) {
          property.set('state', object.state);
        }

        if (object.city) {
          property.set('city', object.city);
        }

        if (object.address) {
          property.set('address', { address: object.address });
        }

        if (object.description) {
          property.set('description', object.description);
        }

        if (object.notes) {
          property.set('notes', object.notes);
        }

        if (object.status === 'Sold') {
          if (object.sellingPrice) {
            property.set('sellingPrice', parseFloat(object.sellingPrice ?? '-1'))
            property.set('price', parseFloat(object.sellingPrice ?? '-1'))
          }
          if (object.soldAt) {
            if (object.status === 'Sold') {
              property.set('soldAt', object.soldAt)
            }
            else {
              property.set('soldAt', null)
            }
          }
          if (object.soldBy) {
            const seller = User.createWithoutData(object.soldBy)
            property.set('soldBy', seller);
          }
        }
        else {
          property.set('soldAt', null);
          property.set('soldBy', null);
          property.set('soldAt', null);
        }


        property.set('updatedAt', object.updatedAt);
        // project.set('updatedBy', null);
      }

      if (object.coverPicture && isFile(object.coverPicture)) {
        // ! delete prev images
        const prevCover = property.get('coverPicture');
        let coverPictureUrl = null;
        if (prevCover) {
          coverPictureUrl = prevCover.toJSON();
        }

        const coverPicture = new Gallery();
        const storeCoverPicture = new Parse.File(object.coverPicture.name, object.coverPicture);

        if (storeCoverPicture) {
          coverPicture.set('file', storeCoverPicture);
          await coverPicture.save(null, { useMasterKey: true });
          if (coverPicture) {
            property.set('coverPicture', coverPicture);

            // await galleryRepository.destroyImage(coverPictureUrl?.objectId);
          }
        }
      }

      const imagesFiles = object.pictures as Picture[];
      if (isArray(imagesFiles) && imagesFiles.length > 0) {
        let savedFiles: any[] = [];
        // ! delete prev images
        const picturesRelation = property.relation('pictures');
        const getPrevPictures = await picturesRelation.query().find({ useMasterKey: true });
        if (getPrevPictures.length > 0) {
          await Promise.all(
            getPrevPictures.map(async (picture: any) => {
              const getPicture = picture.toJSON();

              const isPictureExist = imagesFiles.find((picture: any) => picture.id === getPicture.objectId);
              if (!isPictureExist) {
                await galleryRepository.destroyImage(getPicture.objectId);
              }
            })
          );
        }

        await Promise.all(
          imagesFiles.map(async (picture, index) => {
            if (isFile(picture.file)) {
              console.log('if (isFile');
              const uploadFile = new Parse.File(picture.file.name, picture.file);
              const galleryModel: GalleryModel = {
                file: uploadFile,
              };
              console.log('savedFile');
              const savedFile = await galleryRepository.createAndReturn(galleryModel);
              if (savedFile) {
                savedFiles.push(savedFile);
              }
            }
          })
        );

        console.log('create new');
        const galleriesRelation = property.relation('pictures');
        galleriesRelation.add(savedFiles);
      }

      if (object.attachments && object.attachments.length > 0) {
        const fileRepository = new FileRepository();
        let savedFiles: any[] = [];
        const attachmentFiles = object.attachments as File[];
        await Promise.all(
          attachmentFiles.map(async (file) => {
            const fileModel: FileModel = {
              file: file,
            };
            const savedFile = await fileRepository.create(fileModel);
            if (savedFile) {
              savedFiles.push(savedFile);
            }
          })
        );
        const attachmentsRelation = property.relation('attachments');
        attachmentsRelation.add(savedFiles);
      }

      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        property.set('createdBy', currentUser);
        property.set('updatedBy', currentUser);
      } else {
        property.set('createdBy', null);
      }

      await property.save(null, { useMasterKey: true });
      return Toast(`Property updated successfully.`, 'success');
    } catch (error) {
      return Toast(`Property could not be updated. Error: ${error}`, 'error');
    }
  }

  public async delete(id: string) {
    try {
      const Property = Parse.Object.extend(this.className);
      const query = new Parse.Query(Property);

      const property = await query.get(id, { useMasterKey: true });

      if (property) {
        await property.destroy({ useMasterKey: true });
        Toast(`Property Deleted successfully.`, 'success');
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error deleting property:', error);
      return false;
    }
  }

  public async updateMultipleProperties(PropertyIds: string[], status: string | null, price: number | null) {
    const Property = Parse.Object.extend(this.className);
    const query = new Parse.Query(Property).containedIn('objectId', PropertyIds);
    console.log(status);
    try {
      const Properties = await query.find({ useMasterKey: true });
      console.log(Properties);

      var success = true;
      for (const Property of Properties) {
        if (status) {
          Property.set('status', status);
        }
        if (price && price !== 0) {
          Property.set('price', price);
        }

        await Property.save(null, { useMasterKey: true }).then(
          (_: any) => {},
          (error: Error) => {
            success = false;
          }
        );
      }
      if (success) {
        Toast(`Property updated successfully.`, 'success');
        return true;
      } else {
        Toast(`Property could not be updated successfully.`, 'error');
        return false;
      }
    } catch (error) {
      console.error('Error updating Property:', error);
      return false;
    }
  }

  public async deleteMultipleProperties(PropertiesIds: string[]): Promise<boolean | null> {
    const Properties = Parse.Object.extend(this.className);
    const query = new Parse.Query(Properties).containedIn('objectId', PropertiesIds);

    try {
      const Properties = await query.find({ useMasterKey: true });

      return new Promise((resolve, _) => {
        Parse.Object.destroyAll(Properties, { useMasterKey: true }).then(
          (_) => {
            Toast(`Properties deleted successfully.`, 'success');
            resolve(true);
          },
          (error: Error) => {
            Toast(`Properties could not be deleted successfully. The following error occurred: ${error.message}`, 'error');
            resolve(false);
          }
        );
      });
    } catch (error) {
      console.error('Error deleting Properties:', error);
      return false;
    }
  }

  public async countByOrganization(organizationId: string): Promise<number | null> {
    const Property = Parse.Object.extend(this.className);
    const query = new Parse.Query(Property).equalTo('organization', organizationId);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting properties:', error);
      return null;
    }
  }

  public async countSoldByOrganization(organizationId: string): Promise<number | null> {
    const Property = Parse.Object.extend(this.className);
    const query = new Parse.Query(Property).equalTo('organization', organizationId).equalTo('status', 'Sold');

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting properties:', error);
      return null;
    }
  }

  public async getTotalSales(organizationId: string): Promise<number | null> {
    const Property = Parse.Object.extend(this.className);
    const query = new Parse.Query(Property).equalTo('status', 'Sold').equalTo('organization', organizationId).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (properties) => {
            if (properties) {
              let count = 0;
              for (let i = 0; i < properties.length; i++) {
                count += properties[i].get('sellingPrice') ?? 0;
              }
              resolve(count);
            } else {
              resolve(null);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error getting total property sales:', error);
      return null;
    }
  }

  public async getTotalSalesForUser(userId: string): Promise<number | null> {
    const Property = Parse.Object.extend(this.className);
    const query = new Parse.Query(Property).equalTo('status', 'Sold').equalTo('soldBy', userId).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (properties) => {
            if (properties) {
              let count = 0;
              for (let i = 0; i < properties.length; i++) {
                count += properties[i].get('sellingPrice') ?? 0;
              }
              resolve(count);
            } else {
              resolve(null);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error getting total property sales for user:', error);
      return null;
    }
  }

  public async getSalesCountForUser(userId: string): Promise<number | null> {
    const Property = Parse.Object.extend(this.className);
    const query = new Parse.Query(Property).equalTo('status', 'Sold').equalTo('soldBy', userId).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting properties:', error);
      return null;
    }
  }

  public async getSalesCountForUserByYear(userId: string, year: number): Promise<number | null> {
    const Property = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 0, 31);
    const query = new Parse.Query(Property).equalTo('status', 'Sold').equalTo('soldBy', userId).greaterThanOrEqualTo('soldAt', yearStartDate).lessThanOrEqualTo('soldAt', yearEndDate).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting properties:', error);
      return null;
    }
  }

  public async getSalesCountForOrganizationByYear(organizationId: string, year: number): Promise<number | null> {
    const Property = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(Property).equalTo('status', 'Sold').equalTo('organization', organizationId).greaterThanOrEqualTo('soldAt', yearStartDate).lessThanOrEqualTo('soldAt', yearEndDate).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting properties:', error);
      return null;
    }
  }

  public async getTotalSalesForUserByYearFormatted(userId: string, year: number): Promise<any | null> {
    const Property = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(Property).equalTo('status', 'Sold').equalTo('soldBy', userId).greaterThanOrEqualTo('soldAt', yearStartDate).lessThanOrEqualTo('soldAt', yearEndDate).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (properties) => {
            if (properties) {
              let quarterlyData = {'Q1': 0, 'Q2': 0, 'Q3': 0, 'Q4': 0};

              for (let i = 0; i < properties.length; i++) {
                const quarter = getYearQuarter(properties[i].get('soldAt'));
                if (quarter !== '-') {
                  quarterlyData[quarter] += properties[i].get('sellingPrice') ?? 0;
                }
              }
              resolve(quarterlyData);
            } else {
              resolve(null);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting properties:', error);
      return null;
    }
  }

  public async getTotalSalesForOrganizationByYearFormatted(organizationId: string, year: number): Promise<any | null> {
    const Property = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(Property).equalTo('status', 'Sold').equalTo('organization', organizationId).greaterThanOrEqualTo('soldAt', yearStartDate).lessThanOrEqualTo('soldAt', yearEndDate).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (properties) => {
            if (properties) {
              let quarterlyData = {'Q1': 0, 'Q2': 0, 'Q3': 0, 'Q4': 0};

              for (let i = 0; i < properties.length; i++) {
                const quarter = getYearQuarter(properties[i].get('soldAt'));
                if (quarter !== '-') {
                  quarterlyData[quarter] += properties[i].get('sellingPrice') ?? 0;
                }
              }
              resolve(quarterlyData);
            } else {
              resolve(null);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting properties:', error);
      return null;
    }
  }

  public async getAllAttachments(id: string): Promise<Parse.Object[] | null> {
    const Property = Parse.Object.extend(this.className);
    const query = new Parse.Query(Property);
    try {
      const property = await query.get(id, { useMasterKey: true });
      if (property) {
        const attachmentsQuery = property.relation('attachments').query().include('file').descending('createdAt');
        return new Promise((resolve, _) => {
          attachmentsQuery.find({ useMasterKey: true }).then(
            (attachments) => {
              if (attachments) {
                resolve(attachments);
              }
              else {
                resolve(null);
              }
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async countAllProperties() {
    try {
      const Property = Parse.Object.extend(this.className);
      const query = new Parse.Query(Property);

      return await query.count();
    }
    catch (e) {
      return null;
    }
  }
}

export default PropertyRepository;
