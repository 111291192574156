import React, { useEffect, useState } from 'react';
import './App.css';
import { initializeParse } from './services/parse';
import UserRepository from './utils/api/repositories/userRepository';
import Parse from 'parse';
import { RouterProvider } from 'react-router-dom';
import { navigation } from './utils/navigator';
import { NotificationToast } from './components/atoms/notification';
import { useDispatch } from 'react-redux';
import { addNewNotifications } from './store/slices';

initializeParse();

function App() {
  const [sendNotification, setSendNotification] = useState<{ body: string; title: string }>({ body: '', title: '' });
  const dispatch = useDispatch();

  const initLiveQuery = async () => {
    const userRepository = new UserRepository();
    const currentUser = await userRepository.getCurrentUserAsync();
    if (currentUser) {
      let query = new Parse.Query('Notification');
      const subscription = await query.subscribe();
      subscription.on('create', (object) => {
        if (object.get('recipient') && object.get('recipient').id == currentUser.id)
          setSendNotification({ body: object.get('body') ?? 'No Body', title: object.get('title') ?? 'No Title' });
        dispatch(addNewNotifications());
      });
    }
  };
  useEffect(() => {
    try {
      //initialize live query for notifications
      initLiveQuery();
    } catch (e) {
      console.log(e);
    }
  }, []);

  useEffect(() => {
    if (sendNotification.body !== '' || sendNotification.title !== '') {
      NotificationToast(`${sendNotification.title} - ${sendNotification.body}`);
      setSendNotification({title: '', body: ''});
    }
  }, [sendNotification]);


  return (
    <div className="App">
      <RouterProvider router={navigation} />
    </div>
  );
}

export default App;
