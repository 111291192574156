import { Box, Typography, styled } from "@mui/material";
import {
  selectColor,
  selectNavigationLayout,
  selectThemeMode,
} from "../../../../../store/selector";
import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Form, Formik } from "formik";
import Sidebar from "../../../sidebar";
import { drawerWidth } from "../../../../../utils/constants/drawerWidth";
import { Button, FieldInput, Toast } from "../../../../atoms";
import { globalColors } from "../../../../../utils/constants/color";
import { FaGlobe } from "react-icons/fa";
import ClearIcon from "@mui/icons-material/Clear";
import OrganizationModel from "../../../../../utils/api/models/organizationModel";
import OrganizationRepository from "../../../../../utils/api/repositories/organizationRepository";
import { useNavigate, useParams } from "react-router-dom";
import AddTagModal from "./addTagModal";
import { setAddNew } from "../../../../../store/routes";
import { useDispatch } from "react-redux";
import ContentTable from "../organizationHistory/contentTable";
import PaymentRepository from "../../../../../utils/api/repositories/paymentRepository";
import { formatDate, getInitials } from "../../../../../utils/helpers";
import GroupInput from "../../../groupInput";
import ModalConfirmPricing from "./modalConfirmPricing";
import Loader from "../../../../atoms/loader";
import PaymentModel from "../../../../../utils/api/models/paymentModel";
import AddPaymentModal from "./modalAddPayment";
import ModalDeleteConfirm from "../../../../organisms/leads/modalDeleteConfirm";

export interface FormValues {
  name: string;
  address: string;
  joinedAt: string;
  ownerName: string;
  ownerEmail: string;
  perMonthAmount: number;
  email: string;
  phone: string;
  memberCount: number;
  activeMembers: number;
  tier: string;
  paymentCycle: string;
}

export interface FormValuesErrors {
  name: string;
  address: string;
  joinedAt: string;
  ownerName: string;
  ownerEmail: string;
  perMonthAmount: string;
  email: string;
  phone: string;
  memberCount: string;
  activeMembers: string;
  tier: string;
  paymentCycle: string;
}

const OrganizationProfile = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    address: "",
    joinedAt: "",
    ownerName: "",
    ownerEmail: "",
    perMonthAmount: 0,
    email: "",
    phone: "",
    memberCount: 0,
    activeMembers: 0,
    tier: "",
    paymentCycle: "",
  });
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [backgroundImage, setBackgroundImage] = useState<any>(null);
  const [openModal, setOpenModal] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);
  const [isUpdatingTags, setIsUpdatingTags] = useState(false);
  const [orgName, setOrgName] = useState<string>("");
  const [orgCountry, setOrgCountry] = useState<string>("");
  const [newPricing, setNewPricing] = useState(0);
  const [switchPage, setSwitchPage] = useState<string>("Profile");
  const [payments, setPayments] = useState<PaymentModel[]>([]);
  const profileCircle = useRef<HTMLDivElement>(null);
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const [openConfirmPricingModal, setOpenConfirmPricingModal] = useState(false);
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setFormValues({
  //     ...formValues,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const onSubmit = async (values: FormValues) => {
    setIsFormSubmitting(true);

    if (values.perMonthAmount != formValues.perMonthAmount) {
      setNewPricing(values.perMonthAmount);
      setFormValues(values);

      setTimeout(() => {
        setOpenConfirmPricingModal(true);
      }, 1000);
    } else {
      await handleUpdateOrganization(values);
    }
  };

  const handleUpdateOrganization = async (values: FormValues) => {
    const organizationGroup: OrganizationModel = {
      ownerName: values.ownerName,
      ownerEmail: values.email,
      address: values.address,
      perMonthAmount: values.perMonthAmount,
      primaryPhone: values.phone,
    };
    if (id) {
      const organizationRepository = new OrganizationRepository();
      const response = await organizationRepository.update(
        organizationGroup,
        id
      );
      if (response) {
        Toast("Organization updated successfully.", "success");
      } else {
        Toast(
          "Organization could not be updated successfully. Please try again.",
          "error"
        );
      }
      setIsFormSubmitting(false);
      await getOrganizationInformation();
    }
  };

  const handleTags = (tagName: string) => {
    setIsUpdatingTags(true);
    const newValue: string = tagName;
    setTags((prevTags) => [...prevTags, newValue]);
  };

  const handleFileUpload = (e: any) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      // Once the file is loaded, set it as the background image
      setBackgroundImage(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const openFileDialog = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.accept = "image/*";
    fileInput.onchange = handleFileUpload;
    fileInput.click();
  };

  const getOrganizationInformation = async () => {
    setLoading(true);
    if (id) {
      const organizationRepository = new OrganizationRepository();
      const organization = await organizationRepository.getObjectById(id);
      if (organization) {
        const activeMembers =
          await organizationRepository.getSignedUpMembersForOrganization(
            organization.id
          );
        if (activeMembers) {
          setFormValues({
            name: organization?.get("name") || "",
            address:
              organization?.get("address") ||
              organization.get("businessLocation"),
            joinedAt: formatDate(organization.get("createdAt")) ?? "",
            ownerName: organization?.get("ownerName") ?? "",
            ownerEmail: organization?.get("ownerEmail") ?? "",
            perMonthAmount: organization?.get("perMonthAmount") ?? 0,
            email: organization?.get("admin").get("email") ?? "",
            phone: organization?.get("primaryPhone"),
            memberCount: organization?.get("memberCount") ?? 0,
            activeMembers: activeMembers.existing,
            tier: organization?.get("tier") ?? "",
            paymentCycle: organization?.get("paymentCycle") ?? "",
          });
          setOrgCountry(organization?.get("businessLocation"));
          setOrgName(organization?.get("name"));
          setTags(organization?.get("tags") ?? []);
          setBackgroundImage(
            organization?.get("logo")?.get("file")?._url || null
          );

          const paymentRepository = new PaymentRepository();
          const payment = await paymentRepository.getByOrganizationId(id);
          if (payment) {
            // console.log('payment', payment);
            setPayments(payment);
          }
        }
        setLoading(false);
      } else {
        setLoading(false);
        setError(
          "Organization details could not be fetched. Please try again."
        );
        setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
      }
    } else {
      setLoading(false);
      setError("Organization details could not be fetched. Please try again.");
      setTimeout(() => {
        navigate("/dashboard");
      }, 3000);
    }
  };

  // const handleUpdateAccess = async () => {
  //   setIsUpdating(true);
  //   const organizationRepository = new OrganizationRepository();
  //   const userData = {
  //     access: "Block Access",
  //   };
  //   if (id) {
  //     const organizations = await organizationRepository.update(userData, id);
  //     if (organizations) {
  //       console.log("updated", organizations);
  //     }
  //   }
  //   setIsUpdating(false);
  // };

  const handleUpdateTags = async () => {
    const organizationRepository = new OrganizationRepository();
    const userData = {
      tags: tags,
    };
    if (id && isUpdatingTags) {
      const organizations = await organizationRepository.update(userData, id);
      if (organizations) {
        console.log("updated tags", organizations);
      }
    }
    setIsUpdatingTags(false);
  };

  const handleDeleteOrganization = async () => {
    setIsRemoving(true);
    const organizationRepository = new OrganizationRepository();
    if (id) {
      const deleted = await organizationRepository.deleteOrganization(id);
      if (deleted) {
        setIsRemoving(false);
        Toast("Organization and all its data deleted successfully.", "success");
        dispatch(setAddNew({ addNew: false, path: "/" }));
        navigate("/dashboard");
      } else {
        setIsRemoving(false);
        Toast(
          "Organization could not be deleted successfully. Please try again.",
          "error"
        );
      }
    }
  };

  // let OriginalWidth = -1;
  // useEffect(() => {
  //   if (main.current) {
  //     if (OriginalWidth < 0) {
  //       OriginalWidth = main.current.clientWidth;
  //     }
  //     const currWidth = main.current.clientWidth;
  //     const scaleWidth = currWidth / OriginalWidth;
  //     if (profileCircle.current && profileCircle.current.style) {
  //       profileCircle.current.style.width = `${scaleWidth * profileCircle.current.clientWidth}px`;
  //       profileCircle.current.style.height = `${scaleWidth * profileCircle.current.clientWidth}px`;
  //     }
  //   }
  // }, [sideBar]);

  useEffect(() => {
    console.log("RUNNING USE EFFECT");
    getOrganizationInformation();
  }, []);

  useEffect(() => {
    handleUpdateTags();
  }, [isUpdatingTags, tags]);

  return (
    <Box sx={{ display: "flex" }}>
      <div>
        <Sidebar
          text={
            switchPage === "Profile"
              ? "Organization Profile"
              : "Organization Payments"
          }
        />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor:
            mode === "dark" ? globalColors?.black : globalColors?.white,
          minHeight: "100vh",
          overflow: "auto",
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : "",
          },
          paddingTop: navigation
            ? { xl: "80px", lg: "80px", md: "80px", sm: "10px", xs: "10px" }
            : "",
          marginTop: {
            sm: navigation ? "0px" : `130px`,
          },
        }}
      >
        {loading ? (
          <Box
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Loader />
          </Box>
        ) : error !== "" ? (
          <Box
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            padding={"2rem"}
          >
            <Box>
              <Loader />
              <Typography
                variant="h6"
                textAlign={"center"}
                color={globalColors.gray}
              >
                {error}
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            width={"100%"}
            paddingTop={3}
            paddingLeft={3}
            paddingRight={3}
            paddingBottom={1}
          >
            <Box marginBottom={"10px"}></Box>
            {/* Top Container */}

            <Box
              sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "space-between",
                flexDirection: { md: "row", xs: "column" },
                alignItems: { xs: "center", md: "end" },
                gap: {
                  xl: "0px",
                  lg: "0px",
                  md: "0px",
                  sm: "10px",
                  xs: "10px",
                },
              }}
            >
              {/* 1st */}
              {/* 1st */}
              <Box
              // sx={{ width: { xl: '18%', lg: '18%', md: '18%', sm: '100%', xs: '100%' } }}
              >
                {backgroundImage && (
                  <Box
                    ref={profileCircle}
                    sx={{
                      width: "208px",
                      height: "208px",
                      border: `1px solid ${globalColors.black}`,
                      backgroundColor:
                        mode === "dark"
                          ? globalColors.background.gray
                          : globalColors.whiteLight,
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundSize: "cover",
                      borderRadius: "110px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    {/* {backgroundImage === null ? <ProfileIcon mode={mode} /> : <></>}
                  <Box
                    sx={{
                      width: '56px',
                      height: '56px',
                      backgroundColor: globalColors.text.secondary,
                      borderRadius: '80px',
                      position: 'absolute',
                      bottom: '0%',
                      right: '8%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={openFileDialog}
                  >
                    <CiCamera size={'2rem'} color={globalColors.black} />
                  </Box> */}
                  </Box>
                )}
                {!backgroundImage && (
                  <Box
                    ref={profileCircle}
                    sx={{
                      width: "208px",
                      height: "208px",
                      border: `1px solid ${globalColors.black}`,
                      backgroundColor:
                        mode === "dark"
                          ? globalColors.background.gray
                          : globalColors.whiteLight,
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundSize: "cover",
                      borderRadius: "110px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <Typography
                      fontWeight={600}
                      fontSize={"60px"}
                      color={color || globalColors.blue}
                    >
                      {getInitials(orgName)}
                    </Typography>
                    {/* {backgroundImage === null ? <ProfileIcon mode={mode} /> : <></>}
                  <Box
                    sx={{
                      width: '56px',
                      height: '56px',
                      backgroundColor: globalColors.text.secondary,
                      borderRadius: '80px',
                      position: 'absolute',
                      bottom: '0%',
                      right: '8%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={openFileDialog}
                  >
                    <CiCamera size={'2rem'} color={globalColors.black} />
                  </Box> */}
                  </Box>
                )}
              </Box>

              {/* 2nd */}
              <Box
                sx={{
                  width: {
                    xl: "55%",
                    lg: "55%",
                    md: "55%",
                    sm: "100%",
                    xs: "100%",
                  },
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    marginTop: "2%",
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: {
                      xl: "row",
                      lg: "row",
                      md: "row",
                      sm: "column",
                      xs: "column",
                    },
                    gap: {
                      xl: "0px",
                      lg: "0px",
                      md: "0px",
                      sm: "10px",
                      xs: "10px",
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: {
                        md: "60%",
                        sm: "100%",
                        xs: "100%",
                      },
                      height: "64px",
                      borderRadius: "50px",
                      backgroundColor:
                        mode === "dark"
                          ? globalColors.blackLight
                          : globalColors.whiteLight,
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: 700,
                        letterSpacing: "0em",
                        textAlign: "left",
                        color:
                          mode === "dark"
                            ? globalColors.white
                            : globalColors.black,
                      }}
                    >
                      {orgName || "-"}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      width: {
                        md: "35%",
                        sm: "100%",
                        xs: "100%",
                      },
                      height: "64px",
                      borderRadius: "50px",
                      backgroundColor:
                        mode === "dark"
                          ? globalColors.blackLight
                          : globalColors.whiteLight,
                      display: "flex",
                      alignItems: "center",
                      padding: "0px 18px",
                    }}
                  >
                    <FaGlobe
                      size={"1.3rem"}
                      color={
                        mode === "dark"
                          ? globalColors.white
                          : globalColors.black
                      }
                    />
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 500,
                        // lineHeight: '30px',
                        letterSpacing: "0em",
                        textAlign: "left",
                        color: color || globalColors.blue,
                        marginLeft: "10px",
                      }}
                    >
                      {orgCountry ?? "-"}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {/* Tags */}
                  <Box
                    display={"flex"}
                    sx={{
                      flexDirection: "row",
                      // marginTop: '5%',
                      // width: '100%',
                    }}
                    gap={"10px"}
                    justifyContent={"start"}
                    flexWrap={"wrap"}
                    rowGap={"20px"}
                  >
                    {tags?.map((e) => {
                      return (
                        <Box
                          sx={{
                            padding: "8px",
                            borderRadius: "12px",
                            transition: "opacity 0.3s ease",
                            border: `1px solid ${
                              color ? color : globalColors.blackLight
                            }`,
                            backgroundColor:
                              mode === "dark"
                                ? globalColors?.blackLight
                                : globalColors.whiteLight,
                            color:
                              mode === "dark"
                                ? globalColors?.white
                                : globalColors.black,
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <span
                            style={{
                              fontFamily: "Poppins",
                              fontWeight: "400",
                              fontSize: "11px",
                              padding: "10px",
                            }}
                          >
                            {e}
                          </span>
                          <ClearIcon
                            sx={{ cursor: "pointer", fontSize: "16px" }}
                            onClick={() => {
                              setTags(tags.filter((item: any) => item !== e));
                              setIsUpdatingTags(true);
                            }}
                          />
                        </Box>
                      );
                    })}
                  </Box>

                  {/* Add Tags */}
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "13px",
                      fontWeight: 500,
                      lineHeight: "19px",
                      letterSpacing: "0em",
                      textAlign: "left",
                      textDecoration: "underline",
                      color:
                        mode === "dark"
                          ? globalColors.white
                          : globalColors.black,
                      cursor: "pointer",
                      width: {
                        xl: "10%",
                        lg: "10%",
                        md: "10%",
                        sm: "20%",
                        xs: "20%",
                      },
                      whiteSpace: "nowrap",
                    }}
                    onClick={() => setOpenModal(true)}
                  >
                    + Add Tag
                  </Typography>
                </Box>
              </Box>

              {/* 3rd */}
              <Box
                sx={{
                  width: { md: "20%", xs: "100%" },
                  paddingTop: { xs: "2rem", md: "0px" },
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                }}
              >
                <Button
                  onClick={() =>
                    setSwitchPage(
                      switchPage === "Profile" ? "Payroll" : "Profile"
                    )
                  }
                  type="button"
                >
                  {switchPage === "Profile" ? "View Payments" : "View Profile"}
                </Button>
              </Box>
            </Box>

            {/* Assignment Container */}
            {switchPage === "Profile" ? (
              <>
                <AssignmentContainer
                  bgcolor={
                    mode === "dark"
                      ? globalColors.blackLight
                      : globalColors.lightgray
                  }
                  padding={"20px"}
                  borderRadius={"16px"}
                  color={globalColors.white}
                  fontSize={"12px"}
                  boxShadow={"#171717"}
                  marginTop={"1rem"}
                >
                  <Formik
                    enableReinitialize
                    initialValues={{
                      name: formValues.name,
                      address: formValues.address,
                      joinedAt: formValues.joinedAt,
                      ownerName: formValues.ownerName,
                      ownerEmail: formValues.ownerEmail,
                      perMonthAmount: formValues.perMonthAmount,
                      email: formValues.email,
                      phone: formValues.phone,
                      memberCount: formValues.memberCount,
                      activeMembers: formValues.activeMembers,
                      tier: formValues.tier,
                      paymentCycle: formValues.paymentCycle,
                    }}
                    validate={(values) => {
                      const errors = {} as FormValuesErrors;

                      if (!values.address.trim()) {
                        errors.address = "Required";
                      }

                      if (!values.ownerName.trim()) {
                        errors.ownerName = "Required";
                      }

                      const regex = new RegExp(
                        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/
                      );
                      if (!regex.test(values.ownerEmail.trim())) {
                        errors.ownerEmail = "Please enter a valid email";
                      }

                      if (
                        values.perMonthAmount <= 0 ||
                        !values.perMonthAmount
                      ) {
                        errors.perMonthAmount =
                          "Please enter a valid amount greater than 0";
                      }

                      if (!values.phone) {
                        errors.phone = "Required";
                      }

                      return errors;
                    }}
                    onSubmit={onSubmit}
                  >
                    {({ values, errors, touched, setFieldValue }) => (
                      <Form>
                        <Box
                          borderRadius={"20px"}
                          marginBottom={"2px"}
                          bgcolor={
                            mode === "dark"
                              ? globalColors.blackLight
                              : globalColors.lightgray
                          }
                          padding={{ xs: "0px", md: "20px" }}
                          display={"flex"}
                          flexDirection={"column"}
                          gap={"1rem"}
                        >
                          <GroupInput className="group-input">
                            <FieldInput
                              label="Name"
                              id="name"
                              value={values.name}
                              mode={mode}
                              name={"name"}
                              placeholder={"Name"}
                              type="text"
                              disabled
                            />
                            <FieldInput
                              label="Address"
                              id="address"
                              value={values.address}
                              mode={mode}
                              name={"address"}
                              placeholder={"Address"}
                              type="text"
                              error={
                                touched.address && errors.address
                                  ? errors.address
                                  : false
                              }
                            />
                          </GroupInput>
                          <GroupInput className="group-input">
                            <FieldInput
                              label="Member Since"
                              id="joinedAt"
                              value={values.joinedAt}
                              mode={mode}
                              name={"joinedAt"}
                              placeholder={"Joining Date"}
                              type="text"
                              disabled
                            />
                            <FieldInput
                              label="Owner Name"
                              id="ownerName"
                              value={values.ownerName}
                              mode={mode}
                              name={"ownerName"}
                              placeholder={"Owner Name"}
                              type="text"
                              error={
                                touched.ownerName && errors.ownerName
                                  ? errors.ownerName
                                  : false
                              }
                            />
                          </GroupInput>
                          <GroupInput className="group-input">
                            <FieldInput
                              label="Owner Email"
                              id="ownerEmail"
                              value={values.ownerEmail}
                              mode={mode}
                              name={"ownerEmail"}
                              placeholder={"Owner Email"}
                              type="text"
                              error={
                                touched.ownerEmail && errors.ownerEmail
                                  ? errors.ownerEmail
                                  : false
                              }
                            />
                            <FieldInput
                              label="Per Month Amount (USD)"
                              id="perMonthAmount"
                              value={values.perMonthAmount.toString()}
                              mode={mode}
                              name={"perMonthAmount"}
                              placeholder={"Per Month Amount (USD)"}
                              type="number"
                              error={
                                touched.perMonthAmount && errors.perMonthAmount
                                  ? errors.perMonthAmount
                                  : false
                              }
                            />
                          </GroupInput>
                          <GroupInput className="group-input">
                            <FieldInput
                              label="Admin Email"
                              id="email"
                              value={values.email}
                              mode={mode}
                              name={"email"}
                              placeholder={"Admin Email"}
                              type="text"
                              disabled
                            />
                            <FieldInput
                              label="Primary Phone"
                              id="phone"
                              value={values.phone}
                              mode={mode}
                              name={"phone"}
                              placeholder={"Phone"}
                              type="number"
                              error={
                                touched.phone && errors.phone
                                  ? errors.phone
                                  : false
                              }
                            />
                          </GroupInput>
                          <GroupInput className="group-input">
                            <FieldInput
                              label="Maximum Members"
                              id="memberCount"
                              value={values.memberCount.toString()}
                              mode={mode}
                              name={"memberCount"}
                              placeholder={"Member Count"}
                              type="number"
                              disabled
                            />
                            <FieldInput
                              label="Active Members"
                              id="activeMembers"
                              value={values.activeMembers.toString()}
                              mode={mode}
                              name={"activeMembers"}
                              placeholder={"Active Members"}
                              type="number"
                              disabled
                            />
                          </GroupInput>
                          <GroupInput className="group-input">
                            <FieldInput
                              label="Tier"
                              id="tier"
                              value={values.tier}
                              mode={mode}
                              name={"tier"}
                              placeholder={"Tier"}
                              type="text"
                              disabled
                            />
                            <FieldInput
                              label="Payment Cycle"
                              id="paymentCycle"
                              value={values.paymentCycle}
                              mode={mode}
                              name={"paymentCycle"}
                              placeholder={"Payment Cycle"}
                              type="text"
                              disabled
                            />
                          </GroupInput>
                          <Box
                            sx={{
                              width: "100%",
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <Button type="submit" disabled={isFormSubmitting}>
                              {isFormSubmitting ? "Loading..." : "Update"}
                            </Button>
                          </Box>
                        </Box>
                      </Form>
                    )}
                  </Formik>
                </AssignmentContainer>

                <Box mt={2} display={"flex"} justifyContent={"end"}>
                  <Button
                    type="button"
                    buttonColor={globalColors.red}
                    onClick={() => {
                      setOpenDeleteModal(true);
                    }}
                    // disabled={}
                  >
                    {isUpdating ? "Deleting..." : "Delete"}
                  </Button>
                </Box>
              </>
            ) : (
              <ContentTable
                data={payments}
                onAddPayment={() => {
                  setOpenAddPaymentModal(true);
                }}
              />
            )}
          </Box>
        )}
        <AddTagModal
          open={openModal}
          onClose={() => {
            setOpenModal(false);
          }}
          mode={mode}
          color={color}
          handleTags={handleTags}
        />
        <ModalConfirmPricing
          open={openConfirmPricingModal}
          onClose={() => {
            setOpenConfirmPricingModal(false);
            setIsFormSubmitting(false);
          }}
          mode={mode}
          color={color}
          newPricing={newPricing}
          onConfirm={async () => {
            setOpenConfirmPricingModal(false);
            await handleUpdateOrganization(formValues);
          }}
        />
        <AddPaymentModal
          open={openAddPaymentModal}
          onClose={() => {
            setOpenAddPaymentModal(false);
          }}
          mode={mode}
          color={color}
          onSuccess={async () => {
            setOpenAddPaymentModal(false);
            await getOrganizationInformation();
          }}
          organizationId={id ?? ""}
        />
        <ModalDeleteConfirm
          open={openDeleteModal}
          onClose={() => {
            setOpenDeleteModal(false);
          }}
          mode={mode}
          color={color}
          onDelete={async () => {
            await handleDeleteOrganization();
          }}
          extraText={
            "Warning: Deleting this organization would delete all users, properties, projects, leads and all other data associated with this organization permanently. Consider blocking access for this organization instead if you want to do this temporarily."
          }
        />
      </Box>
    </Box>
  );
};

const AssignmentContainer = styled(Box)(({ theme }) => ({
  height: "100%",
  width: "100%",
  "& .group-input": {
    gap: "1rem",
    [theme.breakpoints.down("sm")]: {
      // backgroundColor: 'cyan !important',
      flexDirection: "column",
    },
  },
}));

const ButtonStyle = styled("button")<any>(({ color, disabled, type }) => ({
  all: "unset",
  whiteSpace: "nowrap",
  cursor: disabled ? "not-allowed" : "pointer",
  opacity: disabled ? "0.5" : "1",
  height: "56px",
  backgroundColor:
    color !== "default" && color !== ""
      ? color
      : type === "reset"
      ? globalColors.blackLight
      : globalColors.blue,
  color: globalColors.white,
  width: "181px",
  padding: "0px 8px",
  display: "grid",
  placeItems: "center",
  fontSize: "1rem",
  fontWeight: 500,
  borderRadius: "25px",
  transition: "opacity 0.3s ease",
  border:
    type === "reset"
      ? `1px solid ${color ? color : globalColors.lightblue}`
      : "",
  "&:hover": {
    opacity: disabled ? "0.5" : "0.9",
  },
  "@media (max-width: 768px)": {
    width: "100%",
  },
}));

export default OrganizationProfile;
