import { Toast } from '../../../components/atoms';
import Parse from 'parse';
import LeadModel from '../models/leadModel';
import UserRepository from './userRepository';
import OrganizationRepository from './organizationRepository';
import SalesExecutiveRepository from './salesExecutiveRepository';
import FileRepository from './fileRepository';
import FileModel from '../models/fileModel';
import { UserRole } from '../../../store/user';
import PropertyRepository from './propertyRepository';
import ProjectRepository from './projectRepository';
import NoteModel from '../models/noteModel';
import NoteRepository from './noteRepository';
import CommunicationModel from '../models/communicationModel';
import CommunicationRepository from './communicationRepository';

class LeadRepository {
  private className = 'Lead';

  constructor() {}

  public async create(object: LeadModel) {
    try {
      const Lead = Parse.Object.extend(this.className);
      const lead = new Lead();

      lead.set('firstName', object.firstName);
      lead.set('lastName', object.lastName);
      lead.set('primaryPhone', object.primaryPhone?.toString());
      lead.set('secondaryPhone', object.secondaryPhone?.toString());
      lead.set('email', object.email);
      lead.set('cnic', object.cnic?.toString());
      lead.set('category', object.category);
      if (object.propertyProject) {
        if (object.category === 'Property') {
          const propertyRepository = new PropertyRepository();
          const property = await propertyRepository.getObjectByObjectId(object.propertyProject?.toString() ?? '');
          lead.set('property', property);
        } else {
          const projectRepository = new ProjectRepository();
          const project = await projectRepository.getObjectByObjectId(object.propertyProject?.toString() ?? '');
          lead.set('project', project);
        }
      }
      lead.set('projectDetails', object.projectDetails);
      lead.set('country', object.country);
      lead.set('state', object.state);
      lead.set('city', object.city);
      lead.set('address', { address: object.address });
      lead.set('source', object.source);
      lead.set('purpose', object.purpose);
      lead.set('interestedIn', object.interestedIn);
      const priority = object.priority ? object.priority : 'Moderate';
      lead.set('priority', priority);
      lead.set('status', 'New');
      const userRepository = new UserRepository();
      const assignedToUser = await userRepository.getObjectById(object.assignedTo);
      if (!assignedToUser) {
        Toast(`Lead could not be added successfully!`, 'error');
        return null;
      }
      lead.set('assignedTo', assignedToUser);
      lead.set('assignedAt', new Date());
      lead.set('budgetFrom', object.budgetFrom);
      lead.set('budgetTo', object.budgetTo);

      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        lead.set('createdBy', currentUser);
        lead.set('updatedBy', currentUser);
        lead.set('organization', currentUser.get('organization'));
      } else {
        lead.set('createdBy', null);
      }

      if (object.attachments && object.attachments.length > 0) {
        const fileRepository = new FileRepository();
        let savedFiles: any[] = [];
        const attachmentFiles = object.attachments as File[];
        await Promise.all(
          attachmentFiles.map(async (file) => {
            const fileModel: FileModel = {
              file: file,
            };
            const savedFile = await fileRepository.create(fileModel);
            if (savedFile) {
              savedFiles.push(savedFile);
            }
          })
        );
        const attachmentsRelation = lead.relation('attachments');
        attachmentsRelation.add(savedFiles);
      }

      if (assignedToUser.get('role') === UserRole.manager) {
        lead.set('managedBy', assignedToUser);
      } else if (assignedToUser.get('role') === UserRole.executive) {
        const salesExecutiveRepository = new SalesExecutiveRepository();
        const executive = await salesExecutiveRepository.getObjectByUserId(assignedToUser.id);
        if (executive) {
          lead.set('managedBy', executive.get('manager').get('user'));
        } else {
          return null;
        }
      }

      return new Promise((resolve, _) => {
        lead.save(null, { useMasterKey: true }).then(
          async (savedLead: any) => {
            const organizationRepository = new OrganizationRepository();
            const savedOrganization = await organizationRepository.addLead(savedLead);
            if (savedOrganization) {
              Toast(`Lead added successfully.`, 'success');
              resolve(savedLead);
            } else {
              Toast(`Lead could not be added successfully. Please try again.`, 'error');
              resolve(null);
            }
          },
          (error: Error) => {
            return Toast(`Lead could not be added successfully. Please try again. The following error occured ${error.message}`, 'error');
          }
        );
      });
    } catch (error) {
      Toast(`Error creating lead. ${error}`, 'error');
      return null;
    }
  }

  public async update(id: string, updates: LeadModel) {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead);
    try {
      const lead = await query.get(id, { useMasterKey: true });
      if (lead) {
        // Update each field from the updates object
        if (updates.firstName) {
          lead.set('firstName', updates.firstName);
        }
        if (updates.lastName) {
          lead.set('lastName', updates.lastName);
        }
        if (updates.primaryPhone) {
          lead.set('primaryPhone', updates.primaryPhone.toString());
        }
        if (updates.secondaryPhone) {
          lead.set('secondaryPhone', updates.secondaryPhone.toString());
        }
        if (updates.email) {
          lead.set('email', updates.email);
        }
        if (updates.cnic) {
          lead.set('cnic', updates.cnic.toString());
        }
        if (updates.category) {
          lead.set('category', updates.category);
        }
        if (updates.paidAmount) {
          lead.set('paidAmount', updates.paidAmount);
        }
        if (updates.conversionTime) {
          lead.set('conversionTime', updates.conversionTime);
        }

        // Check the category and set either property or project
        if (updates.propertyProject) {
          if (updates.category === 'Property') {
            const propertyRepository = new PropertyRepository();
            const property = await propertyRepository.getObjectByObjectId(updates.propertyProject?.toString() ?? '');
            lead.set('property', property);
            lead.set('project', null);
          } else {
            const projectRepository = new ProjectRepository();
            const project = await projectRepository.getObjectByObjectId(updates.propertyProject?.toString() ?? '');
            lead.set('project', project);
            lead.set('property', null);
          }
        }
        if (updates.country) {
          lead.set('country', updates.country);
        }
        if (updates.state) {
          lead.set('state', updates.state);
        }
        if (updates.city) {
          lead.set('city', updates.city);
        }
        if (updates.address) {
          lead.set('address', { address: updates.address });
        }
        if (updates.source) {
          lead.set('source', updates.source);
        }
        if (updates.purpose) {
          lead.set('purpose', updates.purpose);
        }
        if (updates.interestedIn) {
          lead.set('interestedIn', updates.interestedIn);
        }
        if (updates.budgetFrom) {
          lead.set('budgetFrom', updates.budgetFrom);
        }
        if (updates.budgetTo) {
          lead.set('budgetTo', updates.budgetTo);
        }

        if (updates.assignedTo) {
          const userRepository = new UserRepository();
          const user = await userRepository.getObjectById(updates.assignedTo);
          if (user) {
            lead.set('assignedTo', user);
            lead.set('assignedAt', new Date());
            if (user.get('role') === UserRole.manager) {
              lead.set('managedBy', user);
            } else if (user.get('role') === UserRole.executive) {
              const salesExecutiveRepository = new SalesExecutiveRepository();
              const executive = await salesExecutiveRepository.getObjectByUserId(user.id);
              if (executive) {
                lead.set('managedBy', executive.get('manager').get('user'));
              } else {
                return null;
              }
            } else if (user.get('role') === UserRole.admin) {
              lead.set('managedBy', null);
            }
          } else {
            return null;
          }
        }

        if (updates.attachments && updates.attachments.length > 0) {
          const fileRepository = new FileRepository();
          let savedFiles: any[] = [];
          const attachmentFiles = updates.attachments as File[];
          await Promise.all(
            attachmentFiles.map(async (file) => {
              const fileModel: FileModel = {
                file: file,
              };
              const savedFile = await fileRepository.create(fileModel);
              if (savedFile) {
                savedFiles.push(savedFile);
              }
            })
          );
          const attachmentsRelation = lead.relation('attachments');
          attachmentsRelation.add(savedFiles);
        }

        await lead.save(null, { useMasterKey: true });
      } else {
        return false;
      }

      return Toast(`Lead updated successfully!`, 'success');
    } catch (error) {
      return Toast(`Lead could not be updated. Error: ${error}`, 'error');
    }
  }

  public async addFollowUp(id: string, followUpDate: string, contactMethod: string, note: NoteModel): Promise<Parse.Object | null> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead);
    try {
      const lead = await query.get(id, { useMasterKey: true });
      if (lead) {
        lead.set('followUpDate', followUpDate);
        let savedNote = null;
        const noteRepository = new NoteRepository();
        savedNote = await noteRepository.create(note);
        if (savedNote) {
          lead.relation('notes').add(savedNote);
        } else {
          return null;
        }
        const communication: CommunicationModel = {
          note: savedNote,
          method: contactMethod,
        };
        const communicationRepository = new CommunicationRepository();
        const savedCommunication = await communicationRepository.create(communication);
        if (savedCommunication) {
          lead.relation('communicationHistory').add(savedCommunication);
        } else {
          return null;
        }

        return new Promise((resolve, _) => {
          lead.save(null, { useMasterKey: true }).then(
            (savedLead: any) => {
              resolve(savedLead);
            },
            (error: any) => {
              console.error('Error adding follow up date for lead:', error);
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error adding follow up date for lead:', error);
      return null;
    }
  }

  public async convertLead(id: string, convertedOn: string, propertyProject: string, soldBy: string, sellingPrice: number, soldAt: string, followUpDate: string, contactMethod: string, note: NoteModel): Promise<Parse.Object | null> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead);
    try {

      let propertyProjectObject: Parse.Object | null = null;
      if (convertedOn === 'Property') {
        const propertyRepository = new PropertyRepository();
        propertyProjectObject = await propertyRepository.getObjectByObjectId(propertyProject);
      }
      else if (convertedOn === 'Project') {
        const projectRepository = new ProjectRepository();
        propertyProjectObject = await projectRepository.getObjectByObjectId(propertyProject);
      }

      if (!propertyProjectObject) {
        return null;
      }

      const lead = await query.get(id, { useMasterKey: true });
      if (lead) {
        lead.set('followUpDate', followUpDate);
        lead.set('priority', 'Cold');
        if (convertedOn === 'Property') {
          lead.set('category', 'Property');
          lead.set('property', propertyProjectObject);
        }
        else if (convertedOn === 'Project') {
          lead.set('category', 'Project');
          lead.set('project', propertyProjectObject);
        }
        let savedNote = null;
        const noteRepository = new NoteRepository();
        savedNote = await noteRepository.create(note);
        if (savedNote) {
          lead.relation('notes').add(savedNote);
        } else {
          return null;
        }
        const communication: CommunicationModel = {
          note: savedNote,
          method: contactMethod,
        };
        const communicationRepository = new CommunicationRepository();
        const savedCommunication = await communicationRepository.create(communication);
        if (savedCommunication) {
          lead.relation('communicationHistory').add(savedCommunication);
        } else {
          return null;
        }

        return new Promise((resolve, _) => {
          lead.save(null, { useMasterKey: true }).then(
            async (savedLead: any) => {
              const user = Parse.User.createWithoutData(soldBy);
              if (propertyProjectObject) {
                propertyProjectObject.set('status', 'Sold');
                propertyProjectObject.set('soldAt', new Date(soldAt));
                propertyProjectObject.set('soldBy', user);
                propertyProjectObject.set('sellingPrice', sellingPrice);
                propertyProjectObject.set('price', sellingPrice);
                await propertyProjectObject.save(null, { useMasterKey: true });
                resolve(savedLead);
              }
              else {
                resolve(savedLead);
              }
            },
            (error: any) => {
              console.error('Error adding follow up date for lead:', error);
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error adding follow up date for lead:', error);
      return null;
    }
  }

  public async getAll(
    user?: Parse.User | null,
    searchName?: string | null,
    status?: string | null,
    assignedTo?: string | null,
    priority?: string | null
  ): Promise<LeadModel[]> {
    if (!user) {
      return [];
    }
    const userRole = user.get('role');
    const Lead = Parse.Object.extend(this.className);
    var query = new Parse.Query(Lead).descending('createdAt').equalTo('organization', user.get('organization'))

    
    if (searchName) {
      const regex = new RegExp(searchName, 'i');

      const firstNameQuery = new Parse.Query(Lead);
      firstNameQuery.matches('firstName', regex);

      const lastNameQuery = new Parse.Query(Lead);
      lastNameQuery.matches('lastName', regex);

      query = Parse.Query.or(firstNameQuery, lastNameQuery);
    }
    if (userRole === UserRole.admin) {
      const userOrganization = user.get('organization');
      query.equalTo('organization', userOrganization);
    } else if (userRole === UserRole.manager) {
      query.equalTo('managedBy', user);
    } else if (userRole === UserRole.executive) {
      query.equalTo('assignedTo', user);
    } else {
      return [];
    }

    if (status && status !== '') {
      query.equalTo('status', status);
    }
    if (assignedTo && assignedTo !== '') {
      query.equalTo('assignedTo', assignedTo);
    }
    if (priority && priority !== '') {
      query.equalTo('priority', priority);
    }

    query.include('assignedTo', 'property', 'project');

    try {
      const leads = await query.find({ useMasterKey: true });

      const leadModels = leads.map((lead: any) => {
        return {
          id: lead.id,
          firstName: lead.get('firstName'),
          lastName: lead.get('lastName'),
          primaryPhone: lead.get('primaryPhone'),
          secondaryPhone: lead.get('secondaryPhone'),
          email: lead.get('email'),
          cnic: lead.get('cnic'),
          category: lead.get('category'),
          projectDetails: lead.get('projectDetails'),
          country: lead.get('country'),
          state: lead.get('state'),
          city: lead.get('city'),
          address: lead.get('address'),
          source: lead.get('source'),
          purpose: lead.get('purpose'),
          interestedIn: lead.get('interestedIn'),
          budgetFrom: lead.get('budgetFrom'),
          budgetTo: lead.get('budgetTo'),
          propertyProject:
            lead.get('category') === 'Property' ? lead.get('property') : lead.get('category') === 'Project' ? lead.get('project') : null,
          organization: lead.get('propertyProject'),
          status: lead.get('status'),
          assignedTo: lead.get('assignedTo'),
          priority: lead.get('priority') || '-',
          paidAmount: lead.get('paidAmount') || '-',
          conversionTime: lead.get('conversionTime') || '-',
        };
      });

      return leadModels;
    } catch (error) {
      console.error('Error fetching leads:', error);
      return [];
    }
  }

  public async getSome(): Promise<LeadModel[]> {
    try {
      const userRepository = new UserRepository();
      const currentUser = userRepository.getCurrentUser();
      if (currentUser) {
        const userOrganization = currentUser.get('organization');

        const Lead = Parse.Object.extend(this.className);
        const query = new Parse.Query(Lead).descending('createdAt').limit(3).include('assignedTo').equalTo('organization', userOrganization);

        if (currentUser.get('role') === UserRole.admin) {
          query.equalTo('organization', userOrganization);
        } else if (currentUser.get('role') === UserRole.manager) {
          query.equalTo('managedBy', currentUser);
        } else if (currentUser.get('role') === UserRole.executive) {
          query.equalTo('assignedTo', currentUser);
        } else {
          return [];
        }
        const leads = await query.find({ useMasterKey: true });

        const leadModels = leads.map((lead: any) => {
          return {
            id: lead.id,
            firstName: lead.get('firstName'),
            lastName: lead.get('lastName'),
            primaryPhone: lead.get('primaryPhone'),
            secondaryPhone: lead.get('secondaryPhone'),
            email: lead.get('email'),
            cnic: lead.get('cnic'),
            category: lead.get('category'),
            projectDetails: lead.get('projectDetails'),
            country: lead.get('country'),
            state: lead.get('state'),
            city: lead.get('city'),
            address: lead.get('address'),
            source: lead.get('source'),
            purpose: lead.get('purpose'),
            interestedIn: lead.get('interestedIn'),
            budgetFrom: lead.get('budgetFrom'),
            budgetTo: lead.get('budgetTo'),
            propertyProject: lead.get('propertyProject') || null,
            assignedTo: lead.get('assignedTo'),
            status: lead.get('status'),
          };
        });
        return leadModels;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching leads:', error);
      return [];
    }
  }

  public async delete(id: string) {
    try {
      const Lead = Parse.Object.extend(this.className);
      const query = new Parse.Query(Lead);

      const lead = await query.get(id, { useMasterKey: true });

      if (lead) {
        await lead.destroy({ useMasterKey: true });
        Toast(`Lead deleted successfully.`, 'success');
        return true;
      } else {
        return false;
      }
    } catch (error) {
      Toast(`Lead could not be deleted successfully. Please try again.`, 'error');
      console.error('Error deleting lead:', error);
      return false;
    }
  }

  public async getModelById(id: string): Promise<LeadModel | null> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead).include(['assignedTo']);

    try {
      const lead = await query.get(id, { useMasterKey: true });

      // Check if a lead with the given ID exists
      if (lead) {
        return {
          id: lead.id,
          firstName: lead.get('firstName'),
          lastName: lead.get('lastName'),
          primaryPhone: lead.get('primaryPhone'),
          status: lead.get('status'),
          priority: lead.get('priority'),
          secondaryPhone: lead.get('secondaryPhone'),
          email: lead.get('email'),
          cnic: lead.get('cnic'),
          category: lead.get('category'),
          projectDetails: lead.get('projectDetails'),
          country: lead.get('country'),
          state: lead.get('state'),
          city: lead.get('city'),
          address: lead.get('address'),
          source: lead.get('source'),
          purpose: lead.get('purpose'),
          interestedIn: lead.get('interestedIn'),
          budgetFrom: lead.get('budgetFrom'),
          budgetTo: lead.get('budgetTo'),
          propertyProject:
            lead.get('category') == 'Property'
              ? lead.get('property')
                ? lead.get('property').id
                : ''
              : lead.get('project')
              ? lead.get('project').id
              : '',
          followUpDate: lead.get('followUpDate') || null,
          assignedTo: lead.get('assignedTo'),
          paidAmount: lead.get('paidAmount'),
          conversionTime: lead.get('conversionTime'),
        };
      } else {
        return null;
      }
    } catch (error) {
      console.error('Error fetching lead by ID:', error);
      return null;
    }
  }

  public async getObjectById(id: string): Promise<Parse.Object | null> {
    try {
      const Lead = Parse.Object.extend(this.className);
      const query = new Parse.Query(Lead);
      const lead = await query.get(id, { useMasterKey: true });
      return lead;
    } catch (error) {
      console.error('Error fetching lead by ID:', error);
      return null;
    }
  }

  public async getNotes(id: string): Promise<Parse.Object[]> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead);
    try {
      const lead = await query.get(id, { useMasterKey: true });
      if (lead) {
        const notesQuery = lead.relation('notes').query().include(['createdBy']).ascending('createdAt');
        return new Promise<[]>((resolve, _) => {
          notesQuery.find({ useMasterKey: true }).then(
            (notes: any) => {
              resolve(notes);
            },
            (error: any) => {
              Toast(`Lead notes could not be fetched successfully.`, 'error');
              resolve([]);
            }
          );
        });
      } else {
        return [];
      }
    } catch (error) {
      // Toast(`Lead notes could not be fetched successfully.`, 'error');
      return [];
    }
  }

  public async getCommunicationHistory(id: string): Promise<{ name: string; values: Parse.Object[] }[]> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead);
    try {
      const lead = await query.get(id, { useMasterKey: true });
      if (lead) {
        const communicationQuery = lead.relation('communicationHistory').query().include('doneBy', 'note').descending('createdAt');
        return new Promise((resolve, _) => {
          communicationQuery.find({ useMasterKey: true }).then(
            (history) => {
              let response = [
                { name: 'Calls', values: <any>[] },
                { name: 'Emails', values: <any>[] },
                { name: 'Meetings', values: <any>[] },
              ];
              for (let i = 0; i < history.length; i++) {
                if (history[i].get('method') === 'Call') {
                  response[0].values.push(history[i]);
                } else if (history[i].get('method') === 'Email') {
                  response[1].values.push(history[i]);
                } else if (history[i].get('method') === 'Meeting') {
                  response[2].values.push(history[i]);
                }
              }
              resolve(response);
            },
            (error: any) => {
              Toast(`Lead communication history could not be fetched successfully.`, 'error');
              resolve([]);
            }
          );
        });
      } else {
        return [];
      }
    } catch (error) {
      // Toast(`Lead notes could not be fetched successfully.`, 'error');
      return [];
    }
  }

  public async getLatestCommunication(id: string): Promise<Parse.Object | null> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead);
    try {
      const lead = await query.get(id, { useMasterKey: true });
      if (lead) {
        const communicationQuery = lead.relation('communicationHistory').query().include('doneBy', 'note').descending('createdAt');
        return new Promise((resolve, _) => {
          communicationQuery.first({ useMasterKey: true }).then(
            (latestCommunication) => {
              if (latestCommunication) {
                resolve(latestCommunication);
              } else {
                resolve(null);
              }
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      // Toast(`Lead notes could not be fetched successfully.`, 'error');
      return null;
    }
  }

  public async changeStatus(id: string, status: string): Promise<Parse.Object | null> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead);
    try {
      const lead = await query.get(id, { useMasterKey: true });
      if (lead) {
        lead.set('status', status);
        return new Promise((resolve, _) => {
          lead.save(null, { useMasterKey: true }).then(
            (savedLead: any) => {
              Toast(`Status updated successfully.`, 'success');
              resolve(savedLead);
            },
            (error: Error) => {
              Toast(`Status could not be updated successfully. Please try again. The following error occured ${error.message}`, 'error');
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async changePriority(id: string, priority: string): Promise<Parse.Object | null> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead);
    try {
      const lead = await query.get(id, { useMasterKey: true });
      if (lead) {
        lead.set('priority', priority);
        return new Promise((resolve, _) => {
          lead.save(null, { useMasterKey: true }).then(
            (savedLead: any) => {
              Toast(`Priority updated successfully.`, 'success');
              resolve(savedLead);
            },
            (error: Error) => {
              Toast(`Priority could not be updated successfully. Please try again. The following error occured ${error.message}`, 'error');
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async updateMultipleLeads(
    leadIds: string[],
    priority: string,
    status: string | null,
    comment: string | null,
    followUp: Date | null | string,
    assignedTo: any
  ) {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead).containedIn('objectId', leadIds);

    try {
      const leads = await query.find({ useMasterKey: true });
      var success = true;
      for (const lead of leads) {
        if (status) {
          lead.set('status', status);
        }
        if (followUp) {
          lead.set('followUpDate', followUp);
        }
        if (assignedTo && assignedTo.length > 0) {
          lead.set('assignedTo', assignedTo[0].user);
          if (assignedTo[0].role === UserRole.manager) {
            lead.set('managedBy', assignedTo[0].user);
          } else if (assignedTo[0].role === UserRole.executive) {
            const salesExecutiveRepository = new SalesExecutiveRepository();
            const executive = await salesExecutiveRepository.getObjectByUserId(assignedTo[0].user.id);
            if (executive) {
              lead.set('managedBy', executive.get('manager').get('user'));
            } else {
              return null;
            }
          }
        }
        if (comment) {
          const Note = Parse.Object.extend('Note');
          const note = new Note();
          note.set('body', comment);
          const userRepository = new UserRepository();
          const currentUser = userRepository.getCurrentUser();
          if (currentUser) {
            note.set('createdBy', currentUser);
            note.set('updatedBy', currentUser);
          }
          await note.save(null, { useMasterKey: true });

          lead.relation('notes').add(note);
        }
        if (priority) {
          lead.set('priority', priority);
        }

        await lead.save(null, { useMasterKey: true }).then(
          (_: any) => {},
          (error: Error) => {
            success = false;
          }
        );
      }
      if (success) {
        Toast(`Leads updated successfully.`, 'success');
        return true;
      } else {
        Toast(`Leads could not be updated successfully.`, 'error');
        return false;
      }
    } catch (error) {
      console.error('Error updating leads:', error);
      return false;
    }
  }

  public async deleteMultipleLeads(leadIds: string[]): Promise<boolean | null> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead).containedIn('objectId', leadIds);

    try {
      const leads = await query.find({ useMasterKey: true });

      return new Promise((resolve, _) => {
        Parse.Object.destroyAll(leads, { useMasterKey: true }).then(
          (_) => {
            Toast(`Leads deleted successfully.`, 'success');
            resolve(true);
          },
          (error: Error) => {
            Toast(`Leads could not be deleted successfully. The following error occurred: ${error.message}`, 'error');
            resolve(false);
          }
        );
      });
    } catch (error) {
      console.error('Error deleting leads:', error);
      return false;
    }
  }

  public async countByOrganization(organizationId: string): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).equalTo('organization', organizationId);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getAllAssignedTo(objectId: String): Promise<LeadModel[]> {
    const Lead = Parse.Object.extend(this.className);
    var query = new Parse.Query(Lead).equalTo('assignedTo', objectId).limit(10000);

    try {
      const leads = await query.find({ useMasterKey: true });

      let leadModels: LeadModel[] = [];
      for (let i = 0; i < leads.length; i++) {
        const lead: LeadModel = {
          id: leads[i].get('objectId'),
          firstName: leads[i].get('firstName'),
          lastName: leads[i].get('lastName'),
        };

        leadModels.push(lead);
      }
      return leadModels;

      //   const leadModels = leads.map((lead: any) => {
      //     return {
      //       id: lead.id,
      //       firstName: lead.get('firstName'),
      //       lastName: lead.get('lastName'),
      //     };
      //   });

      return leadModels;
    } catch (error) {
      console.error('Error fetching leads:', error);
      return [];
    }
  }

  public async countByUserId(userId: string): Promise<number | null> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead).equalTo('assignedTo', userId).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting leads:', error);
      return null;
    }
  }

  public async countByUserIdByYear(userId: string, year: number): Promise<number | null> {
    const Lead = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 0, 31);
    const query = new Parse.Query(Lead)
      .equalTo('assignedTo', userId)
      .greaterThanOrEqualTo('assignedAt', yearStartDate)
      .lessThanOrEqualTo('assignedAt', yearEndDate)
      .limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting leads:', error);
      return null;
    }
  }

  public async countByOrganizationByYear(organizationId: string, year: number): Promise<number | null> {
    const Lead = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 0, 31);
    const query = new Parse.Query(Lead)
      .equalTo('organization', organizationId)
      .greaterThanOrEqualTo('assignedAt', yearStartDate)
      .lessThanOrEqualTo('assignedAt', yearEndDate)
      .limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting leads:', error);
      return null;
    }
  }

  public async getCountsByStatus(organizationId: string): Promise<{ name: string; value: number }[] | null> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead).equalTo('organization', organizationId).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (leads) => {
            let counts = [
              { name: 'New', value: 0 },
              { name: 'Contacted', value: 0 },
              { name: 'Qualified', value: 0 },
              { name: 'Converted', value: 0 },
              { name: 'Lost', value: 0 },
            ];
            for (let i = 0; i < leads.length; i++) {
              const status = leads[i].get('status');
              if (status === 'New') {
                counts[0].value += 1;
              } else if (status === 'Contacted') {
                counts[1].value += 1;
              } else if (status === 'Qualified') {
                counts[2].value += 1;
              } else if (status === 'Converted') {
                counts[3].value += 1;
              } else if (status === 'Lost') {
                counts[4].value += 1;
              }
            }
            resolve(counts);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting leads:', error);
      return null;
    }
  }

  public async getCountsByPriority(organizationId: string): Promise<{ name: string; value: number }[] | null> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead).equalTo('organization', organizationId).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (leads) => {
            let counts = [
              { name: 'Cold', value: 0 },
              { name: 'Moderate', value: 0 },
              { name: 'Hot', value: 0 },
              { name: 'Very Hot', value: 0 },
              
            ];
            for (let i = 0; i < leads.length; i++) {
              const priority = leads[i].get('priority');
              if (priority === 'Very Hot') {
                counts[3].value += 1;
              } else if (priority === 'Hot') {
                counts[2].value += 1;
              } else if (priority === 'Moderate') {
                counts[1].value += 1;
              } else if (priority === 'Cold') {
                counts[0].value += 1;
              }
            }
            resolve(counts);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting leads:', error);
      return null;
    }
  }

  public async getAllAttachments(id: string): Promise<Parse.Object[] | null> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead);
    try {
      const lead = await query.get(id, { useMasterKey: true });
      if (lead) {
        const attachmentsQuery = lead.relation('attachments').query().include('file').descending('createdAt');
        return new Promise((resolve, _) => {
          attachmentsQuery.find({ useMasterKey: true }).then(
            (attachments) => {
              if (attachments) {
                resolve(attachments);
              } else {
                resolve(null);
              }
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async changeAllManagedByForAssignedTo(assignedTo: string, newManagerId: string): Promise<boolean> {
    const Lead = Parse.Object.extend(this.className);
    const query = new Parse.Query(Lead).equalTo('assignedTo', assignedTo);
    try {
      const managerUser = Parse.User.createWithoutData(newManagerId);
      const leads = await query.find({ useMasterKey: true });
      if (leads) {
        for (let i = 0; i < leads.length; i++) {
          leads[i].set('managedBy', managerUser);
          const savedLead = await leads[i].save(null, { useMasterKey: true });
          if (!savedLead) {
            return false;
          }
        }
        return true;
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  }

  public async countAllLeads() {
    try {
      const Lead = Parse.Object.extend(this.className);
      const query = new Parse.Query(Lead);

      return await query.count();
    }
    catch (e) {
      return null;
    }
  }
}

export default LeadRepository;
