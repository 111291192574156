import { Box, Button, Modal, Stack, Typography, styled } from "@mui/material";
import { Field, Formik, FormikHelpers, FormikProps } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { globalColors } from "../../../../utils/constants/color";
import { FieldInput, FieldInputUnit } from "../../../atoms";
import { HeadingFormAuth } from "../../../molecules";
import Dropdowndata from "../../../atoms/dropdown2";
import OrganizationRepository from "../../../../utils/api/repositories/organizationRepository";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxWidth: "525px",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  padding: "1.5rem",
  borderRadius: "20px",

  "@media (max-width: 576px)": {
    width: "80%",
  },
};

interface FormValues {
  reason: string;
  otherReason: string;
}

interface ModalStatusChange {
  open: boolean;
  onClose: () => void;
  mode: string;
  color: string;
  newStatus: string;
  onSubmit: (value: string) => Promise<void>;
}

const ModalStatusChange = ({
  open,
  onClose,
  mode,
  color,
  onSubmit,
  newStatus,
}: ModalStatusChange) => {
  const [reasons, setReasons] = useState<string[]>([]);

  useEffect(() => {
    if (newStatus === "active") {
      setReasons(["Payment Made", "Compliance Issues Resolved", "Other"]);
    } else {
      setReasons(["Non-Payment", "Compliance Issues", "Other"]);
    }
  }, [newStatus]);

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-nameledby="modal-add-template"
      aria-describedby="modal-request-add-template"
      className="content"
    >
      <ModalAddTemplateStyle>
        <Box
          sx={style}
          bgcolor={
            mode === "dark" ? globalColors.blackLight : globalColors.white
          }
          className="content"
        >
          <HeadingFormAuth
            mode={mode}
            title="Change Status"
            subTitle={`Please provide a reason for changing this status to ${newStatus}. This information would be visible to the organization admin. This change would affect all the users of this organization.`}
            onGoBack={null}
          />

          <Box gap={2}>
            <Formik
              initialValues={{
                reason: "",
                otherReason: "",
              }}
              validate={(values) => {
                const errors = {} as FormValues;

                if (!values.reason || !values.reason.trim()) {
                  errors.reason = "Please enter a valid reason";
                }

                if (values.reason === "Other" && !values.otherReason.trim()) {
                  errors.otherReason = "Please enter a valid reason";
                }

                return errors;
              }}
              onSubmit={async (
                values: FormValues,
                { setSubmitting }: FormikHelpers<FormValues>
              ) => {
                await onSubmit(
                  values.reason === "Other" ? values.otherReason : values.reason
                );
                onClose();
              }}
            >
              {({ values, errors, touched, handleSubmit, setFieldValue }) => (
                <Box display={"flex"} flexDirection={"column"} gap={"1rem"}>
                  <Box>
                    <Dropdowndata
                      label="Reason"
                      id="reason"
                      name="reason"
                      optional={false}
                      mode={mode}
                      onChange={(e) => {
                        setFieldValue("reason", e.target.value);
                      }}
                      values={[
                        { label: "Select", value: "" },
                        ...reasons.map((reason) => {
                          return { label: reason, value: reason };
                        }),
                      ]}
                      defaultValue={values.reason}
                    />
                    {touched.reason && errors.reason && (
                      <Typography pt={"0.8rem"} color={"red"} fontSize={"12px"}>
                        {errors?.reason}
                      </Typography>
                    )}
                  </Box>
                  {values.reason === "Other" && (
                    <FieldInput
                      label="Specify Reason"
                      className="field-input"
                      placeholder="Reason explanation"
                      type="text"
                      name="otherReason"
                      id="otherReason"
                      optional={false}
                      mode={mode}
                      value={values.otherReason}
                      error={
                        touched.otherReason && errors.otherReason
                          ? errors.otherReason
                          : false
                      }
                    />
                  )}
                  <Box
                    display={"flex"}
                    justifyContent={"space-evenly"}
                    gap={"15px"}
                    marginTop={"30px"}
                  >
                    <Button
                      sx={{
                        border: `1px solid ${globalColors.red}`,
                        color:
                          mode == "dark"
                            ? globalColors.white
                            : globalColors.black,
                        width: "100%",
                        borderRadius: "12px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        "&:hover": {
                          backgroundColor: globalColors.red,
                        },
                      }}
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      sx={{
                        border: `1px solid ${color || globalColors.blue}`,
                        color:
                          mode == "dark"
                            ? globalColors.white
                            : globalColors.black,
                        width: "100%",
                        borderRadius: "12px",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        "&:hover": {
                          backgroundColor: `${color || globalColors.blue}`,
                        },
                      }}
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Done
                    </Button>
                  </Box>
                </Box>
              )}
            </Formik>
          </Box>
          {/* <Button variant="contained" type="button" className="btn-add" onClick={handleAddAmenities}>
            Add Amenity
          </Button>
          <Button variant="contained" type="button" className="btn-add" onClick={handleAddAmenities}>
            Add Amenity
          </Button> */}
        </Box>
      </ModalAddTemplateStyle>
    </Modal>
  );
};

const ModalAddTemplateStyle = styled("div")(({ theme }) => ({
  "& .btn-add": {
    marginTop: "1rem",
    width: "100%",
  },
}));

export default ModalStatusChange;
