import { Box, Grid, Typography } from '@mui/material';
import Sidebar from '../../components/molecules/sidebar';
import { globalColors } from '../../utils/constants/color';
import { selectColor, selectNavigationLayout, selectThemeMode } from '../../store/selector';
import { drawerWidth } from '../../utils/constants/drawerWidth';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import HomeCard from '../../components/molecules/admin/dashboard/homeCards';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import RevenueGraph from '../../components/molecules/admin/dashboard/revenueGraph';
import { formatFileSize, formatMoney } from '../../utils/helpers';
import VisitorsChart from '../../components/molecules/admin/dashboard/visitorsChart';
import ContentTable from '../../components/molecules/admin/dashboard/contentTable';
import UserRepository from '../../utils/api/repositories/userRepository';
import { useNavigate } from 'react-router-dom';
import { UserRole } from '../../store/user';
import Loader from '../../components/atoms/loader';
import PaymentRepository from '../../utils/api/repositories/paymentRepository';
import LeadRepository from '../../utils/api/repositories/leadRepository';
import PropertyRepository from '../../utils/api/repositories/propertyRepository';
import ProjectRepository from '../../utils/api/repositories/projectRepository';
import FileRepository from '../../utils/api/repositories/fileRepository';

const AdminDashboard = () => {
  const mode = useSelector(selectThemeMode);
  const navigation = useSelector(selectNavigationLayout);
  const color = useSelector(selectColor);
  const [loadingFigures, setLoadingFigures] = useState(true);
  const [salesCount, setSalesCount] = useState(0);
  const [leadsCount, setLeadsCount] = useState(0);
  const [propertiesCount, setPropertiesCount] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  const [organizationCount, setOrganizationCount] = useState(0);
  const [inactiveOrganizationCount, setInactiveOrganizationCount] = useState(0);
  const [organizations, setOrganizations] = useState<Parse.Object[]>([]);
  const [usersCount, setUsersCount] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalStorage, setTotalStorage] = useState('');
  const [countriesCount, setCountriesCount] = useState(0);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const fetchUserType = async () => {
    const userRepository = new UserRepository();
    const currentUser = await userRepository.getCurrentUserAsync();
    if (currentUser && currentUser.get('role') == UserRole.super) {
      setLoading(false);
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    fetchUserType();
  }, []);

  useEffect(() => {
    const fetchCompanyFigures = async () => {
      const userRepository = new UserRepository();
      const users = await userRepository.countAllUsers();
      if (users) {
        setUsersCount(users);
      }

      const paymentRepository = new PaymentRepository();
      const revenue = await paymentRepository.getPaymentsTotal();
      if (revenue) {
        setTotalRevenue(revenue);
      }

      const leadRepository = new LeadRepository();
      const leads = await leadRepository.countAllLeads();
      if (leads) {
        setLeadsCount(leads);
      }

      const propertyRepository = new PropertyRepository();
      const properties = await propertyRepository.countAllProperties();
      if (properties) {
        setPropertiesCount(properties);
      }

      const projectRepository = new ProjectRepository();
      const projects = await projectRepository.countAllProjects();
      if (projects) {
        setProjectsCount(projects);
      }

      const fileRepository = new FileRepository();
      const storage = await fileRepository.getStorageTotal();
      if (storage) {
        setTotalStorage(formatFileSize(storage));
      }

      const organizationRepository = new OrganizationRepository();
      const inactiveOrganizations = await organizationRepository.countAllInactiveOrganizations();
      if (inactiveOrganizations) {
        setInactiveOrganizationCount(inactiveOrganizations);
      }

      setLoadingFigures(false);
      // setLoading(false);
    };

    fetchCompanyFigures();
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <div>
        <Sidebar text={'Dashboard'} />
      </div>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          mt: 10,
          backgroundColor: mode === 'dark' ? globalColors?.black : globalColors?.white,
          minHeight: '100vh',
          overflow: 'auto',
          width: {
            sm: navigation ? `calc(100% - ${drawerWidth}px)` : '',
          },
          paddingTop: navigation ? { xl: '80px', lg: '80px', md: '80px', sm: '10px', xs: '10px' } : '',
          marginTop: {
            sm: navigation ? '0px' : `130px`,
          },
        }}
      >
        {loading ? (
          <Box height={'100%'} display={'flex'} alignItems={'center'}>
            <Loader />
          </Box>
        ) : (
          <Box width={'100%'} padding={3}>
            <Box display={'flex'} sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }} justifyContent={'space-between'} gap={'30px'}>
              <HomeCard value={organizationCount} title={'Organizations'} iconName={'Organizations'} loading={loadingFigures} />
              <HomeCard value={usersCount} title={'Users'} iconName={'Users'} loading={loadingFigures} />
              <HomeCard value={formatMoney(totalRevenue)} title={'Total Revenue'} iconName={'TotalRevenue'} loading={loadingFigures} />
              <HomeCard value={inactiveOrganizationCount} title={'Inactive Organizations'} iconName={'InactiveOrganizations'} loading={loadingFigures} />
            </Box>
            <Box paddingTop={3} display={'flex'} sx={{ display: 'flex', flexDirection: { xs: 'column', lg: 'row' } }} justifyContent={'space-between'} gap={'30px'}>
              <HomeCard value={leadsCount} title={'Total Leads'} iconName={'TotalLeads'} loading={loadingFigures} />
              <HomeCard value={propertiesCount} title={'Total Properties'} iconName={'TotalProperties'} loading={loadingFigures} />
              <HomeCard value={projectsCount} title={'Total Projects'} iconName={'TotalProjects'} loading={loadingFigures} />
              <HomeCard value={totalStorage} title={'Storage Used'} iconName={'TotalStorage'} loading={loadingFigures} />
            </Box>

            {/* <Box width={'100%'} paddingTop={1} paddingBottom={1}>
              <Box marginTop={'1%'}>
                <VisitorsChart />
              </Box>
            </Box> */}

            <Box width={'100%'} paddingTop={1} paddingBottom={1}>
              <Box marginTop={'1%'}>
                <RevenueGraph />
              </Box>
            </Box>

            <Box width={'100%'} paddingTop={1} paddingBottom={1}>
              <Box
                bgcolor={mode === 'dark' ? globalColors.blackLight : globalColors.lightgray}
                padding={'20px'}
                borderRadius={'10px'}
                color={globalColors.white}
                fontSize={'12px'}
                boxShadow={'#171717'}
                marginTop={'1rem'}
              >
                <ContentTable onOrganizationsLoaded={(count) => {setOrganizationCount(count)}} />
              </Box>
            </Box>

            <Box sx={{ width: '100%', marginTop: '1%' }}></Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AdminDashboard;
