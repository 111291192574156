import { createBrowserRouter } from 'react-router-dom';
import App from '../App';
import LoginPage from '../views/auth/login';
import AuthGuard from '../components/atoms/authGuard';
import AdminDashboard from '../views/admin';
import PageNotFound from '../components/atoms/pageNotFound';
import OrganizationProfile from '../components/molecules/perfomance/profile/organization';
import AddOrganization from '../components/molecules/admin/addOrganization';

export const navigation = createBrowserRouter([
  {
    element: (
      <PageNotFound />
    ),
    path: '*'
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={true} shouldNavigateToLogin={false}>
        <LoginPage />
      </AuthGuard>
    ),
    path: '/',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <AdminDashboard />
      </AuthGuard>
    ),
    path: '/dashboard',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <AddOrganization />
      </AuthGuard>
    ),
    path: '/organizations/add',
  },
  {
    element: (
      <AuthGuard shouldNavigateToDashboard={false} shouldNavigateToLogin={true}>
        <OrganizationProfile />
      </AuthGuard>
    ),
    path: '/organizations/:id',
  },
]);
