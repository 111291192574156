import { RootState } from './reducers';

export const selectColor = (state: RootState) => state.UseData.color;
export const selectEditorValue = (state: RootState) => state.UseData.editorValue;
export const selectThemeMode = (state: RootState) => state.UseData.thememode;
export const selectNavigationLayout = (state: RootState) => state.UseData.navigationlayout;
export const selectUserRole = (state: RootState) => state?.user?.data?.role;
export const selectCalendarView = (state: RootState) => state.calendar.view;
export const selectBulkEdit = (state: RootState) => state?.UseData?.bulkedit;
export const selectShowModalBulkEdit = (state: RootState) => state.projects.showModalBulkEdit;
export const selectUserData = (state: RootState) => state.user.data;
export const selectUserType = (state: RootState) => state.user.type;
export const selectPropertyState = (state: RootState) => state.properties;
export const selectProjectState = (state: RootState) => state.projects;
export const selectLeadState = (state: RootState) => state.UseData.viewActive;
export const selectLoading = (state: RootState) => state.UseData.loader;
export const selectPropertyloader = (state: RootState) => state.properties.isLoading;
export const selectProjectloader = (state: RootState) => state.projects.isLoading;
export const selectLeadsFilter = (state: RootState) => state.UseData.filterData;
export const selectPropertiesFilter = (state: RootState) => state.properties.filterData;
export const selectProjectFilter = (state: RootState) => state.projects.filterData;
export const selectSearchProject = (state: RootState) => state.projects.Search;
export const selectSearchProperties = (state: RootState) => state.properties.Search;
export const selectAddNew = (state: RootState) => state.route.data;
export const selectOpenSettings = (state: RootState) => state.route.openSettings;
export const selectEmailFilter = (state: RootState) => state.UseData.filterEmailData;
export const selectCommissionFilter = (state: RootState) => state.UseData.filterCommissionData;
export const sortEmail = (state: RootState) => state.UseData.sortEmailData;
export const sortCommission = (state: RootState) => state.UseData.sortCommissionData;
export const selectOpenSidebar = (state: RootState) => state.route.openSidebar;
export const selectCommissionMode = (state: RootState) => state.commission.commissionMode;
export const selectPermissionsState = (state: RootState) => state.UseData.refreshPermissions;
export const selectSortTasksState = (state: RootState) => state.UseData.reSortTasks;
export const selectRefreshTasksState = (state: RootState) => state.UseData.refreshTasks;
export const selectEmployeeFilter = (state: RootState) => state.UseData.filterEmployeeData;
export const selectOrganizationFilter = (state: RootState) => state.UseData.filterOrganizationData;
export const selectNewNotificationsState = (state: RootState) => state.UseData.newNotifications;
export const selectOrganizationTier = (state: RootState) => state.UseData.organizationTier;
export const selectOrganizationLogo = (state: RootState) => state.UseData.organizationLogo;

