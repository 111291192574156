import React from 'react';
import UserModel from '../../utils/api/models/userModel';
import UserRepository from '../../utils/api/repositories/userRepository';
import ManagerRepository from '../../utils/api/repositories/managerRepository';
import ManagerModel from '../../utils/api/models/managerModel';
import OrganizationModel from '../../utils/api/models/organizationModel';
import OrganizationRepository from '../../utils/api/repositories/organizationRepository';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { NavigationLayoutFtn, ThemeColorFtn, ThemeModeFtn, setOrganizationLogo, setOrganizationTier } from '../../store/slices';
import * as Yup from 'yup';
import { UserRole, setUserData, setUserType } from '../../store/user';
import SalesExecutiveRepository from '../../utils/api/repositories/salesExecutiveRepository';
import SalesExecutiveModel from '../../utils/api/models/salesExecutiveModel';
import { Toast } from '../../components/atoms';
import { globalColors } from '../../utils/constants/color';
import { LoginErrors, OrganizationActions, PricingTiers } from '../../utils/helpers';
import { current } from '@reduxjs/toolkit';

const useLogin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // ! Validation schema form
  const validationSchema = Yup.object({
    credentials: Yup.string().trim().required('Required'),
    password: Yup.string().trim().required('Required'),
    // email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  const handleDummySignUp = async () => {
    const user: UserModel = {
      username: 'omer@tactech.tech',
      email: 'omer@tactech.tech',
      phone: '+921231234567',
      firstName: 'Muhammad',
      lastName: 'Omer',
      role: 'super',
    };
    const repository = new UserRepository();
    await repository.signUp(user);
  };

  const handleDummyFlow = async () => {
    const organizationName = 'PROPERTY CLOUD';
    const admins: UserModel[] = [
      {
        username: 'demo_account@propertycloud.tech',
        email: 'demo_account@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Demo',
        lastName: 'Account',
        role: UserRole.admin,
      },
    ];
    const managers: UserModel[] = [
      {
        username: 'manager1@propertycloud.tech',
        email: 'manager1@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Manager',
        lastName: 'One',
        role: UserRole.manager,
      },
      {
        username: 'manager2@propertycloud.tech',
        email: 'manager2@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Manager',
        lastName: 'Two',
        role: UserRole.manager,
      },
    ];
    const salesExecutives: UserModel[] = [
      {
        username: 'sales1@propertycloud.tech',
        email: 'sales1@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Sales',
        lastName: 'One',
        role: UserRole.executive,
      },
      {
        username: 'sales2@propertycloud.tech',
        email: 'sales2@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Sales',
        lastName: 'Two',
        role: UserRole.executive,
      },
      {
        username: 'sales3@propertycloud.tech',
        email: 'sales3@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Sales',
        lastName: 'Three',
        role: UserRole.executive,
      },
      {
        username: 'sales4@propertycloud.tech',
        email: 'sales4@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Sales',
        lastName: 'Four',
        role: UserRole.executive,
      },
      {
        username: 'sales5@propertycloud.tech',
        email: 'sales5@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Sales',
        lastName: 'Five',
        role: UserRole.executive,
      },
      {
        username: 'sales6@propertycloud.tech',
        email: 'sales6@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Sales',
        lastName: 'Six',
        role: UserRole.executive,
      },
      {
        username: 'sales7@propertycloud.tech',
        email: 'sales7@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Sales',
        lastName: 'Seven',
        role: UserRole.executive,
      },
      {
        username: 'sales8@propertycloud.tech',
        email: 'sales8@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Sales',
        lastName: 'Eight',
        role: UserRole.executive,
      },
      {
        username: 'sales9@propertycloud.tech',
        email: 'sales9@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Sales',
        lastName: 'Nine',
        role: UserRole.executive,
      },
      {
        username: 'sales10@propertycloud.tech',
        email: 'sales10@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Sales',
        lastName: 'Ten',
        role: UserRole.executive,
      },
      {
        username: 'sales11@propertycloud.tech',
        email: 'sales11@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Sales',
        lastName: 'Eleven',
        role: UserRole.executive,
      },
      {
        username: 'sales12@propertycloud.tech',
        email: 'sales12@propertycloud.tech',
        phone: '+921231234567',
        firstName: 'Sales',
        lastName: 'Twelve',
        role: UserRole.executive,
      },
    ];
    const userRepository = new UserRepository();
    const savedAdmins: any[] = [];
    const savedManagers: any[] = [];
    const savedSalesExecutives: any[] = [];

    for (let i = 0; i < admins.length; i++) {
      const savedAdmin = await userRepository.signUp(admins[i]);
      if (savedAdmin) {
        savedAdmins.push(savedAdmin);
      }
    }

    for (let i = 0; i < salesExecutives.length; i++) {
      const savedSalesExecutive = await userRepository.signUp(salesExecutives[i]);
      if (savedSalesExecutive) {
        savedSalesExecutives.push(savedSalesExecutive);
      }
    }

    for (let i = 0; i < managers.length; i++) {
      const savedManager = await userRepository.signUp(managers[i]);
      if (savedManager) {
        savedManagers.push(savedManager);
      }
    }

    const managerRepository = new ManagerRepository();
    const savedManagerObjects: any[] = [];

    const firstManager: ManagerModel = {
      user: savedManagers[0],
      managing: [savedSalesExecutives[0], savedSalesExecutives[1], savedSalesExecutives[2], savedSalesExecutives[3], savedSalesExecutives[4]],
    };
    const savedFirstManagerObject = await managerRepository.create(firstManager);
    if (savedFirstManagerObject) {
      savedManagerObjects.push(savedFirstManagerObject);
    }

    const secondManager: ManagerModel = {
      user: savedManagers[1],
      managing: [
        savedSalesExecutives[5],
        savedSalesExecutives[6],
        savedSalesExecutives[7],
        savedSalesExecutives[8],
        savedSalesExecutives[9],
        savedSalesExecutives[10],
        savedSalesExecutives[11],
      ],
    };
    const savedSecondManagerObject = await managerRepository.create(secondManager);
    if (savedSecondManagerObject) {
      savedManagerObjects.push(savedSecondManagerObject);
    }

    const salesExecutiveRepository = new SalesExecutiveRepository();
    const savedSalesExecutiveObjects: any[] = [];

    for (let i = 0; i < savedSalesExecutives.length; i++) {
      if (i < 5) {
        const salesExecutive: SalesExecutiveModel = {
          user: savedSalesExecutives[i],
          manager: savedFirstManagerObject,
        };
        const savedSalesExecutiveObject = await salesExecutiveRepository.create(salesExecutive);
        savedSalesExecutiveObjects.push(savedSalesExecutiveObject);
      } else {
        const salesExecutive: SalesExecutiveModel = {
          user: savedSalesExecutives[i],
          manager: savedSecondManagerObject,
        };
        const savedSalesExecutiveObject = await salesExecutiveRepository.create(salesExecutive);
        savedSalesExecutiveObjects.push(savedSalesExecutiveObject);
      }
    }

    const firstManagerUpdated: ManagerModel = {
      user: savedManagers[0],
      managing: [
        savedSalesExecutiveObjects[0],
        savedSalesExecutiveObjects[1],
        savedSalesExecutiveObjects[2],
        savedSalesExecutiveObjects[3],
        savedSalesExecutiveObjects[4],
      ],
    };

    const secondManagerUpdated: ManagerModel = {
      user: savedManagers[1],
      managing: [
        savedSalesExecutiveObjects[5],
        savedSalesExecutiveObjects[6],
        savedSalesExecutiveObjects[7],
        savedSalesExecutiveObjects[8],
        savedSalesExecutiveObjects[9],
        savedSalesExecutiveObjects[10],
        savedSalesExecutiveObjects[11],
      ],
    };

    const updatedFirstManagerObject = await managerRepository.addManaging(savedManagerObjects[0], firstManagerUpdated);
    const updatedSecondManagerObject = await managerRepository.addManaging(savedManagerObjects[1], secondManagerUpdated);

    const organization: OrganizationModel = {
      name: organizationName,
      admin: savedAdmins[0],
      managers: savedManagerObjects,
      salesExecutives: savedSalesExecutiveObjects,
      status: 'active',
      tier: PricingTiers.Professional,
    };

    const organizationRepository = new OrganizationRepository();
    const savedOrganization = await organizationRepository.create(organization);
    if (savedOrganization) {
      for (let i = 0; i < savedAdmins.length; i++) {
        savedAdmins[i].set('organization', savedOrganization);
        await savedAdmins[i].save(null, { useMasterKey: true });
      }
      for (let i = 0; i < savedManagers.length; i++) {
        savedManagers[i].set('organization', savedOrganization);
        await savedManagers[i].save(null, { useMasterKey: true });
      }
      for (let i = 0; i < savedSalesExecutives.length; i++) {
        savedSalesExecutives[i].set('organization', savedOrganization);
        await savedSalesExecutives[i].save(null, { useMasterKey: true });
      }
    }
  };

  const handleSubmitLogin = async (values: { credentials: string; password: string }) => {
    try {
      const repository = new UserRepository();
      const potentialUser = await repository.getObjectByEmail(values.credentials);
      if (potentialUser) {
        if (potentialUser.get('role') === UserRole.super) {
          const success = await repository.login(values.credentials, values.password);
          if (success) {
            const currentUser = repository.getCurrentUser();
            if (currentUser) {
              dispatch(ThemeColorFtn(currentUser.get('colorPreference') !== 'default' ? currentUser.get('colorPreference') : globalColors.blue));
              dispatch(ThemeModeFtn(currentUser.get('themePreference')));
              dispatch(setUserData(currentUser));
              navigate('/dashboard');
            } else {
              throw 'User not logged in successfully.';
            }
          } else {
            Toast('User could not be logged in successfully. Please enter an email that has been signed up and try again.', 'error');
          }
        } else {
          throw 'User not qualified';
        }
      } else {
        Toast('User could not be logged in successfully. Please enter an email that has been signed up and try again.', 'error');
      }
    } catch (error) {
      Toast('There was an error logging you in. Please try again.', 'error');
    }
  };

  return { handleDummyFlow, handleDummySignUp, handleSubmitLogin, validationSchema };
};

export default useLogin;
