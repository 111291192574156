import {
  Box,
  Divider,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import moment from "moment";
import styled from "@emotion/styled";
import { globalColors } from "../../../../../utils/constants/color";
import {
  Button,
  FieldInput,
  FieldInputTextarea,
  Toast,
} from "../../../../atoms";
import { Form, Formik, FormikHelpers } from "formik";
import { useDispatch } from "react-redux";
import Loader from "../../../../atoms/loader";
import { useParams } from "react-router-dom";
import Dropdowndata from "../../../../atoms/dropdown2";
import SalaryRepository from "../../../../../utils/api/repositories/salaryRepository";
import SalaryModel from "../../../../../utils/api/models/salaryModel";
import UserRepository from "../../../../../utils/api/repositories/userRepository";
import OrganizationRepository from "../../../../../utils/api/repositories/organizationRepository";
import { PricingSchedules, formatDate } from "../../../../../utils/helpers";
import { BsExclamationCircle } from "react-icons/bs";
import PaymentModel from "../../../../../utils/api/models/paymentModel";

interface ModalDetailLeadProps {
  open: boolean;
  mode: string;
  onClose: () => void;
  organizationId: string;
  color: string;
  onSuccess: () => void;
}

interface ValuesAddTag {
  amountPaid: number;
  method: string;
  nextDueAt: string;
}

interface ErrorsAddTag {
  amountPaid: string;
  method: string;
  nextDueAt: string;
}

const AddPaymentModal = ({
  open,
  onClose,
  mode,
  organizationId,
  color,
  onSuccess,
}: ModalDetailLeadProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState("");
  const [tier, setTier] = useState("");
  const [paymentCycle, setPaymentCycle] = useState("");
  const [perMonth, setPerMonth] = useState(0);
  const [total, setTotal] = useState(0);
  const [nextDue, setNextDue] = useState("");
  const [error, setError] = useState("");

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    maxWidth: "728px",
    maxHeight: "90vh",
    bgcolor: mode === "dark" ? "background.paper" : globalColors.white,
    boxShadow: 24,
    p: 4,
    backgroundColor:
      mode === "dark" ? globalColors.blackLight : globalColors.white,
    padding: "1.5rem",
    borderRadius: "20px",
    overflow: "auto",

    "@media (max-width: 576px)": {
      width: "80%",
      height: "600px",
    },
  };

  useEffect(() => {
    const fetchOrganizationDetails = async () => {
      if (organizationId !== "") {
        const organizationRepository = new OrganizationRepository();
        const organization = await organizationRepository.getObjectById(
          organizationId
        );
        if (organization) {
          setName(organization.get("name") ?? "-");
          setTier(organization.get("tier") ?? "-");
          setPaymentCycle(organization.get("paymentCycle") ?? "-");
          setPerMonth(organization.get("perMonthAmount"));
          if (organization.get("paymentCycle") === PricingSchedules.Annual) {
            setTotal(12 * organization.get("perMonthAmount"));
          } else {
            setTotal(6 * organization.get("perMonthAmount"));
          }
          setNextDue(formatDate(organization.get("nextPaymentDue")));
        }
      } else {
        setError(
          "Organization details could not be fetched successfully. Please try again."
        );
      }
      setLoading(false);
    };

    fetchOrganizationDetails();
  }, []);

  return (
    <Modal
      keepMounted={false}
      open={open}
      onClose={onClose}
      aria-labelledby="modal-add-amenities"
      aria-describedby="modal-request-add-amenities"
      className="content scroll"
    >
      {loading ? (
        <Box sx={style}>
          <Loader />
        </Box>
      ) : (
        <Box
          sx={style}
          className="content"
          display={"flex"}
          flexDirection={"column"}
          width={"100%"}
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 500,
              lineHeight: "30px",
              letterSpacing: "0em",
              textAlign: "left",
              color: mode === "dark" ? globalColors.white : globalColors.black,
            }}
          >
            New Manual Payment
          </Typography>
          <Box
            sx={{
              width: "100%",
              marginTop: "10px",
              borderBottom: `1px solid ${globalColors.background.gray}`,
            }}
          ></Box>
          <Box
            display={"flex"}
            width={"100%"}
            flexDirection={"column"}
            paddingTop={"1rem"}
            paddingBottom={"1rem"}
            alignItems={"start"}
          >
            <Typography
              variant="h6"
              sx={{
                color:
                  mode === "dark" ? globalColors.white : globalColors.black,
              }}
            >
              {name}
            </Typography>
            <Typography sx={{ color: globalColors.gray }}>
              Tier <span style={{ color: color }}>{tier}</span>
            </Typography>
            <Typography sx={{ color: globalColors.gray }}>
              Payment Cycle <span style={{ color: color }}>{paymentCycle}</span>
            </Typography>
            <Typography sx={{ color: globalColors.gray }}>
              Per Month Amount (USD){" "}
              <span style={{ color: color }}>{perMonth}</span>
            </Typography>
            <Typography sx={{ color: globalColors.gray }}>
              Due Date <span style={{ color: color }}>{nextDue}</span>
            </Typography>
            <Typography sx={{ color: globalColors.gray }}>
              Payment Due (USD) <span style={{ color: color }}>{total}</span>
            </Typography>
          </Box>
          <Formik
            initialValues={{
              amountPaid: 0,
              method: "",
              nextDueAt: "",
            }}
            validate={(values) => {
              const errors = {} as ErrorsAddTag;

              if (!values.method || values.method === "Select") {
                errors.method = "Required";
              }

              if (!values.amountPaid || values.amountPaid < 0) {
                errors.amountPaid =
                  "Please enter a valid amount greater than 0";
              }

              if (!values.nextDueAt) {
                errors.nextDueAt = "Required";
              }

              return errors;
            }}
            onSubmit={async (
              values: ValuesAddTag,
              { setSubmitting, resetForm }: FormikHelpers<ValuesAddTag>
            ) => {
              const organizationRepository = new OrganizationRepository();
              const newPayment: PaymentModel = {
                amount: values.amountPaid * 100,
                nature: 'renewal',
                type: values.method,
              }
              const savedPayment = await organizationRepository.createManualPayment(newPayment, organizationId, new Date(values.nextDueAt));
              if (savedPayment) {
                onSuccess();
              }
              onClose();
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              setFieldValue,
            }) => (
              <Form>
                <Box
                  borderRadius={"20px"}
                  marginBottom={"2px"}
                  width={"100%"}
                  display={"flex"}
                  flexDirection={"column"}
                  gap={"1.5rem"}
                >
                  <FieldInput
                    optional={false}
                    label="Payment Amount"
                    id="amountPaid"
                    mode={mode}
                    name={"amountPaid"}
                    placeholder="Payment Amount"
                    type="number"
                    min={0}
                    error={
                      touched.amountPaid && errors.amountPaid
                        ? errors.amountPaid
                        : false
                    }
                  />
                  {values.amountPaid != 0 && values.amountPaid != total && (
                    <Typography
                      fontSize={"12px"}
                      color={
                        mode === "dark"
                          ? globalColors.white
                          : globalColors.black
                      }
                      display={"flex"}
                      alignItems={"center"}
                      gap={"10px"}
                    >
                      <BsExclamationCircle
                        color={
                          mode === "dark"
                            ? globalColors.white
                            : globalColors.black
                        }
                        size={"1.5rem"}
                      />
                      You are charging{" "}
                      {values.amountPaid < total ? "less" : "more"} than the
                      amount this organization owes at its due date.
                    </Typography>
                  )}
                  <Box>
                    <Dropdowndata
                      label="Payment Method"
                      id="method"
                      name="method"
                      mode={mode}
                      optional={false}
                      values={[
                        "",
                        "Bank Transfer",
                        "Cash",
                        "Cheque",
                        "Other",
                      ].map((method) => {
                        return {
                          label: method === "" ? "Select" : method,
                          value: method,
                        };
                      })}
                      value={values.method}
                      onChange={(e) => {
                        setFieldValue("method", e.target.value);
                      }}
                      defaultValue=""
                    />
                    <StyledTypography>{errors.method}</StyledTypography>
                  </Box>
                  <FieldInput
                    optional={false}
                    label="Next Due Date"
                    id="nextDueAt"
                    mode={mode}
                    name={"nextDueAt"}
                    placeholder="Next Due Date"
                    type="date"
                    min={new Date()}
                    error={
                      touched.nextDueAt && errors.nextDueAt
                        ? errors.nextDueAt
                        : false
                    }
                  />
                  <Box display={"flex"} justifyContent={"center"}>
                    <Button
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Loading..." : "Create"}
                    </Button>
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </Modal>
  );
};

const StyledTypography = styled(Typography)(({ theme }) => ({
  paddingTop: "8px",
  color: "red",
  fontSize: "0.75rem",
}));

export default AddPaymentModal;
