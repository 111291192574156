import React, { useEffect } from 'react';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
// import { useMediaQuery } from '@mui/material';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
// import Home from '../../assets/Icons/Home_Icon.svg';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { FaChevronLeft, FaChevronRight, FaRegBell, FaSignOutAlt, FaUserCheck } from 'react-icons/fa';
import { IoSettingsOutline } from 'react-icons/io5';
import logo from '../../assets/Images/logo.svg';
import { MdOutlineMailOutline } from 'react-icons/md';
import Navbar from './navbar';
import { globalColors } from '../../utils/constants/color';
import { useNavigate } from 'react-router-dom';
import { Button, useMediaQuery } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import UpperSidebar from './upperSidebar';
import UserRepository from '../../utils/api/repositories/userRepository';
import { UserRole, resetUserData } from '../../store/user';
import {
  selectAddNew,
  selectColor,
  selectNavigationLayout,
  selectOpenSettings,
  selectOpenSidebar,
  selectOrganizationLogo,
  selectOrganizationTier,
  selectThemeMode,
  selectUserData,
} from '../../store/selector';
import { SettingsSubMenu } from './settingsSubMenu';
import WestIcon from '@mui/icons-material/West';
import { setAddNew, setOpenSettings, setOpenSidebar } from '../../store/routes';
import { useLocation } from 'react-router-dom';
import { SettingsSideSubMenu } from './settingsSideSubMenu';
import { LuLayoutDashboard } from 'react-icons/lu';
import { IoHomeOutline } from 'react-icons/io5';
import { RiBuilding4Line } from 'react-icons/ri';
import {
  PiShareNetwork,
  PiUserCircleBold,
  PiUserCircleGear,
  PiUserCirclePlus,
  PiUserCirclePlusBold,
  PiUserCirclePlusThin,
  PiUserGear,
  PiUsersThreeLight,
} from 'react-icons/pi';
import { AiOutlineSchedule } from 'react-icons/ai';
import { IoShareSocialOutline } from 'react-icons/io5';
import { IoAnalyticsOutline } from 'react-icons/io5';
import { BsBuilding, BsBuildingGear, BsHouse, BsHouseGear } from 'react-icons/bs';
import AlertRepository from '../../utils/api/repositories/alertsRepository';
import { PricingTiers, showNotification } from '../../utils/helpers';
import { BiNetworkChart, BiSolidUserPin, BiTime } from 'react-icons/bi';
import { FaUserGear, FaUserGroup, FaUsersGear } from 'react-icons/fa6';
import { GrUserSettings } from 'react-icons/gr';
import Loader from '../atoms/loader';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

interface SidebarProps {
  text: string;
}

export default function Sidebar({ text }: SidebarProps) {
  const theme = useTheme();
  const sideBar = useSelector(selectOpenSidebar);
  const organizationTier = useSelector(selectOrganizationTier);
  const organizationLogo = useSelector(selectOrganizationLogo);
  // const logoRef = React.useRef();
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(sideBar || false);
  // const [active_item, setActive_item] = React.useState(true);
  const navigate = useNavigate();
  const color = useSelector(selectColor);

  const mode = useSelector(selectThemeMode);
  const showArrow = useSelector(selectAddNew);
  const navigation = useSelector(selectNavigationLayout);
  const openSetting = useSelector(selectOpenSettings);
  const [drawerWidth, setDrawerWidth] = React.useState(280);
  const location = useLocation();
  const [openPerfomance, setOpenPerfomance] = React.useState(false);
  const user = useSelector(selectUserData);

  // dispatch(setAddNew({ path: '/test' }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // backgroundColor: "#171717",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  }));

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })<AppBarProps>(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin', 'background-color'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
      delay: theme.transitions.duration.shortest,
    }),
    backgroundColor: open ? globalColors?.darkblack : globalColors?.darkblack,
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin', 'background-color'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
        delay: theme.transitions.duration.shortest,
      }),
    }),
  }));

  const validBackPaths = [
    '/properties/new',
    '/properties/edit',
    '/projects/new',
    '/projects/edit',
    '/leads/new',
    '/leads/edit',
    '/activity/task',
    '/email/new',
    '/socialmedia/new',
    '/settings/commission/newTeam',
    '/email/schedule',
    '/email/setup',
    '/alerts/newGroup',
    '/settings/commission/edit',
    '/projects/edit',
    '/performance/company/report',
    '/hr/members/addMember',
    '/performance/company/manage',
    '/settings/payments/changePlan',
    '/organization',
    '/organization/add',
    '/performance/organization/history',
    '/manage/conversions/page',
    '/hr/member',
  ];

  const handleBackClick = () => {
    navigate(showArrow?.path);
    dispatch(setAddNew({ addNew: false, path: '' }));
  };

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    backgroundColor: globalColors?.darkblack,
    transition: theme.transitions.create(['width', 'background-color', 'opacity'], {
      easing: theme.transitions.easing.easeIn,
      duration: theme.transitions.duration.complex,
      delay: theme.transitions.duration.shortest,
    }),
    overflowX: 'hidden',
  });

  const closedMixin = (theme: Theme): CSSObject => ({
    backgroundColor: globalColors?.darkblack,
    transition: theme.transitions.create(['width', 'background-color', 'opacity'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.complex,
      delay: theme.transitions.duration.shortest,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(8)} + 1px)`,
    },
  });

  const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    backgroundColor: globalColors?.black,
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }));

  const isMobile = useMediaQuery('(max-width:900px)');

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(setOpenSidebar());
  };

  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(setOpenSidebar());
  };

  // useEffect(() => {
  //   setOpen(false);
  // }, [navigation]);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 900;

      if (isMobile) {
        setDrawerWidth(window.innerWidth);
      } else {
        setDrawerWidth(280);
      }
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors.lightgray,
          height: '80px',
          margin: 'auto',
          paddingTop: 1,
        }}
        open={open}
      >
        <Toolbar>
          {navigation && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: '5px',
                ...(open && { display: 'none' }),
                backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors.lightgray,
              }}
            >
              <FaChevronRight color={mode === 'dark' ? globalColors?.white : globalColors.black} />
              {/* <BiMenuAltRight color={mode === 'dark' ? globalColors?.white : globalColors.black} size={'2rem'} /> */}
            </IconButton>
          )}
          <Box display={'flex'} justifyContent={'space-between'} width={'100%'} margin={'auto'} alignItems={'center'}>
            {!navigation ? (
              <img style={{ marginLeft: '32px' }} src={logo} alt="logo" />
            ) : (
              // <Grow in={!navigation}></Grow>
              <Box sx={{ display: 'flex', alignItems: 'center', width: isMobile ? '70%' : '30%' }}>
                {showArrow.addNew &&
                (validBackPaths.includes(window.location.pathname) ||
                  validBackPaths.some((backPath) => window.location.pathname.includes(backPath))) ? (
                  <WestIcon sx={{ cursor: 'pointer', color: mode === 'dark' ? globalColors.white : globalColors.black }} onClick={handleBackClick} />
                ) : (
                  <></>
                )}
                <Typography
                  variant="h6"
                  noWrap
                  component="div"
                  marginTop={'auto'}
                  marginBottom={'auto'}
                  color={mode === 'dark' ? globalColors?.white : globalColors.black}
                  sx={{ marginLeft: '2%' }}
                >
                  {text}
                </Typography>
              </Box>
            )}
            <Navbar mode={mode} bgcolor={mode === 'dark' ? globalColors.black : globalColors?.white} color={color} />
          </Box>
        </Toolbar>
      </AppBar>
      {navigation ? (
        <Drawer
          variant="permanent"
          className={!open ? 'sidebar' : ''}
          open={open}
          sx={{
            '& .MuiDrawer-paper': { backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors.lightgray },
          }}
        >
          <DrawerHeader
            sx={{
              backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors.lightgray,
            }}
          >
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <FaChevronRight color={mode === 'dark' ? globalColors?.white : globalColors.black} />
              ) : (
                <FaChevronLeft color={mode === 'dark' ? globalColors?.white : globalColors.black} />
              )}
            </IconButton>
          </DrawerHeader>
          <div
            style={{
              height: !open ? '100%' : 'auto',
              backgroundColor: mode === 'dark' ? globalColors?.blackLight : globalColors.lightgray,
            }}
          >
            <Box display={'flex'} marginTop={open ? '-50px' : '10px'} paddingLeft={'10px'}>
              {open && (
                // <Grow in={true} timeout={1000}><img width={open ? 120 : 60} src={logo} alt="" /></Grow>
                <img height={72} loading={'lazy'} src={logo} alt="" />
              )}
            </Box>
            <List
              sx={{
                margin: 'auto',
                display: 'flex',
                marginTop: '10px',
                flexDirection: 'column',
                justifyContent: openSetting ? 'start' : 'space-between',
              }}
            >

              {!openSetting ? (
                <Box display={'flex'} justifyContent={'center'} flexDirection={'column'} gap={'5px'}>
                  {/* Dashboard */}
                  <>
                    {open === false ? (
                      <Button
                        onClick={() => navigate('/dashboard')}
                        sx={{
                          marginLeft: '-10px',
                          color: text.includes('Dashboard') ? color || globalColors?.blue : mode === 'dark' ? globalColors.white : globalColors.black,
                          transition: 'color 0.3s',
                          '&:hover': {
                            color: mode === 'dark' ? globalColors?.white : globalColors.black,
                          },
                          border: '1px solid transparent',
                        }}
                      >
                        <LuLayoutDashboard size={'1.5rem'} style={{ cursor: 'pointer' }} />
                      </Button>
                    ) : open === true ? (
                      <Button
                        onClick={() => navigate('/dashboard')}
                        sx={{
                          textTransform: 'none',
                          display: 'flex',
                          fontWeight: 'bolder',
                          cursor: 'pointer',
                          alignItems: 'center',
                          backgroundColor: text.includes('Dashboard') ? color || globalColors?.blue : '',
                          color: mode === 'dark' ? globalColors.white : text.includes('Dashboard') ? globalColors.white : globalColors.black,
                          borderRadius: '12px',
                          // margin: '0px',
                          marginLeft: '15px',
                          marginRight: '20px',
                          paddingLeft: '15px',
                          paddingRight: '20px',
                          border: '1px solid transparent',
                          transition: 'background-color 0.3s',
                          '&:hover': {
                            backgroundColor: 'transparent',
                            border: `1px solid ${color || globalColors?.blue}`,
                            color: color || globalColors?.blue,
                          },
                        }}
                      >
                        <LuLayoutDashboard size={'1.5rem'} />
                        <ListItemText sx={{ textAlign: 'right' }} primary={'Dashboard'} />
                      </Button>
                    ) : (
                      <></>
                    )}

                  </>
                </Box>
              ) : (
                <></>
              )}
            </List>
          </div>
        </Drawer>
      ) : (
        <UpperSidebar />
      )}
      {/* <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
      </Box> */}
    </Box>
  );
}
