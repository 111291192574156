// import { styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
// import { globalColors } from '../../utils/constants/color';
// import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserRepository from '../../utils/api/repositories/userRepository';
import { Box } from '@mui/material';
import Loader from './loader';
import { UserRole, setUserData } from '../../store/user';
import { LoginErrors } from '../../utils/helpers';
import { useDispatch } from 'react-redux';
import { setOrganizationLogo, setOrganizationTier } from '../../store/slices';
import { selectOrganizationLogo } from '../../store/selector';

interface AuthGuardProps {
  children: React.ReactNode;
  shouldNavigateToLogin: Boolean;
  shouldNavigateToDashboard: Boolean;
}

const AuthGuard = ({ children, shouldNavigateToLogin = true, shouldNavigateToDashboard = false }: AuthGuardProps) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const checkStatus = async () => {
    const repository = new UserRepository();
    const currentUser = repository.getCurrentUser();
    if (currentUser) {
      dispatch(setUserData(currentUser));
      if (currentUser.get('role') === UserRole.super) {
        setLoading(false);
        if (shouldNavigateToDashboard) {
          navigate('/dashboard');
        }
      } else {
        setLoading(false);
        if (shouldNavigateToLogin) {
          navigate('/');
        }
      }
    } else {
      setLoading(false);
      if (shouldNavigateToLogin) {
        navigate('/');
      }
    }
  };

  useEffect(() => {
    checkStatus();
  }, []);

  if (loading) {
    return (
      <Box height={'100vh'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
        <Loader />
      </Box>
    );
  }
  return <>{children}</>;
};

export default AuthGuard;
