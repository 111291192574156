import moment from 'moment';
import { UserRole } from '../../store/user';
import { globalColors } from '../constants/color';
import UserRepository from '../api/repositories/userRepository';

export function isFile(variable: any): variable is File {
  return variable instanceof File;
}

export function isArray(variable: any): variable is any[] {
  return Array.isArray(variable);
}

export const formatEmailDate = (i: any) => moment(i).fromNow();

export const showNotification = (body: any, title: any) => {
  var options: any = {
    body,
    icon: 'https://www.vkf-renzel.com/out/pictures/generated/product/1/356_356_75/r12044336-01/general-warning-sign-10836-1.jpg?    auto=compress&cs=tinysrgb&dpr=1&w=500',
    dir: 'ltr',
  };
  if (Notification.permission !== 'granted') {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        new Notification(title, options);
      }
    });
  } else {
    new Notification(title, options);
  }
};

export const formatRole = (role: string) => {
    if (role === UserRole.executive) {
        return 'Executive';
    }
    else if (role === UserRole.manager) {
        return 'Manager';
    }
    else if (role === UserRole.admin) {
        return 'Admin';
    }
    else {
        return '-'
    }
}

export const getInitials = (title: string) => {
    const names = title.split(' ');
    const initials = names.map(name => name.charAt(0).toUpperCase()).join('');
    if (initials.length > 2) {
      return initials[0] + initials[initials.length - 1];
    }
    return initials;
}

export const getYearQuarter = (date: any) => {

  if (!(date instanceof Date)) {
    return '-';
  }

  const month = date.getMonth();

  if (month >= 0 && month <= 2) {
    return 'Q1';
  } else if (month >= 3 && month <= 5) {
    return 'Q2';
  } else if (month >= 6 && month <= 8) {
    return 'Q3';
  } else {
    return 'Q4';
  }
}

export const removeDuplicates = (array: any[]) => {
    return Array.from(new Set(array))
}

export const getYearsArray = () => {
  let startYear = 2023;
  // if (currentUser) {
  //   startYear = new Date(currentUser.get('organization')['createdAt']).getFullYear();
  // }
  const currentYear = new Date().getFullYear();

  const yearsArray = [];
  for (let year = currentYear; year >= startYear; year--) {
    yearsArray.push(year);
  }

  return yearsArray;
}

export const formatMoney = (amount: number) => {
  return amount.toLocaleString('en-PK', {
    maximumFractionDigits: 2
  });
}

export const confirmFilesTotalSize = (files: File[], limit: number = 40) => {
  const maxSizeInBytes = limit * 1000 * 1000;
  let totalSize = 0;
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    totalSize += file.size;
  }
  return totalSize <= maxSizeInBytes;
}

export const confirmFileSize = (file: File, limit: number = 2) => {
  const maxSizeInBytes = limit * 1000 * 1000;
  return file.size <= maxSizeInBytes;
}

export enum PricingTiers {
  Essential = 'Essential',
  Professional = 'Professional'
}

export enum PricingSchedules {
  Annual = 'Annual',
  Biannual = 'Biannual'
}

export const getColorForPriority = (priority: string) => {
  const COLORS = ['#0455C6', '#599BF7', '#C94800', '#AE002A'];

  if (priority === 'Very Hot') {
    return COLORS[3];
  }
  else if (priority === 'Hot') {
    return COLORS[2];
  }
  else if (priority === 'Moderate') {
    return COLORS[1];
  }
  else if (priority === 'Cold') {
    return COLORS[0];
  }
  return null;
}

export const getColorForOrganizationStatus = (status: string) => {
  

  if (status === 'active') {
    return globalColors.green;
  }
  else if (status === 'inactive') {
    return globalColors.red;
  }
  return null;
}

export const getColorForStatus = (status: string) => {

  if (status === 'New') {
    return '#599BF7';
  } else if (status === 'Contacted') {
    return globalColors.blue;
  } else if (status === 'Qualified') {
    return '#00C0EB';
  } else if (status === 'Converted') {
    return globalColors.green;
  } else if (status === 'Lost') {
    return globalColors.red;
  } 
  return null;
}

export const getColorForTaskStatus = (status: string) => {

  if (status === TaskStatus.inProgress) {
    return globalColors.blue;
  } else if (status === TaskStatus.completed) {
    return '#0097A0';
  }  else if (status === TaskStatus.blocked) {
    return globalColors.red;
  } 
  return null;
}

export function removeIdentifier(filename: string) {
  const parts = filename.split('_');
  let fileName = parts.slice(1).join('_');

  if (fileName.length > 20) {
    return fileName.substring(0, 17) + '...';
  }
  else {
    return fileName;
  }
}

export interface PermissionsInterface {
  name: string,
  permissions: string[];
}

export const getPermissionsList = () => {
  return [
    {
      name: 'Leads',
      permissions: ['View', 'Create', 'Update', 'Delete']
    },
    {
      name: 'Properties',
      permissions: ['View', 'Create', 'Update', 'Delete']
    },
    {
      name: 'Projects',
      permissions: ['View', 'Create', 'Update', 'Delete']
    },
    {
      name: 'Email',
      permissions: ['View', 'Create', 'Update', 'Delete']
    },
    {
      name: 'Social Media',
      permissions: ['View', 'Create', 'Update', 'Delete']
    },
    {
      name: 'Tasks',
      permissions: ['View', 'Create', 'Update', 'Delete']
    },
  ];
}

export const hasPermissionForAction = (permissionName: 'Leads' | 'Properties' | 'Projects' | 'Email' | 'Social Media' | 'Tasks', action: 'View' | 'Create' | 'Update' | 'Delete' ) => {
  const userRepository = new UserRepository();
  const currentUser = userRepository.getCurrentUser();
  if (currentUser) {
    if (currentUser.get('role') === UserRole.admin) {
      return true;
    }
    const permissions = currentUser.get('permissions');
    if (permissions) {
      for (let i = 0; i < permissions.length; i++) {
        if (permissions[i].name === permissionName) {
          return permissions[i].permissions.includes(action);
        }
      }
      return false;
    }
    else {
      return false;
    }
  }
  return false;
}

export enum TaskStatus {
  inProgress = "In Progress",
  blocked = "Blocked",
  completed = "Completed"
}

export enum OrganizationActions {
  offboard = "offboard",
}

export enum LoginErrors {
  paymentDue = '45891034',
  actionRequired = '92512890'
}

export const formatDate = (dateString: string | Date) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const getPaymentTiers = () => {
  return [
    {
      title: PricingTiers.Essential,
      price: 100,
      content: [
        'Projects & Properties',
        'Inventory Management',
        'Leads Management',
        'Lead Scoring',
        'Documents Upload',
        'Reports & Analytics',
        'Dashboard Customization',
        'Social Media Integration',
        'HR & Payroll'
      ],
      extraContent: []
    },
    {
      title: PricingTiers.Professional,
      price: 120,
      content: [
        'Projects & Properties',
        'Inventory Management',
        'Leads Management',
        'Lead Scoring',
        'Documents Upload',
        'Reports & Analytics',
        'Dashboard Customization',
        'Social Media Integration',
        'HR & Payroll'
      ],
      extraContent: [
        'Cloud AI Lead Assignment',
        'Company-wide Alerts',
        'Social Media Management',
        'Task Assignment',
        'Emails & Templates',
        'Calendar Scheduling'
      ]
    },
  ];
}

export function differenceInMonths(startDate: Date, endDate: Date) {
  const startYear = startDate.getFullYear();
  const startMonth = startDate.getMonth();
  const endYear = endDate.getFullYear();
  const endMonth = endDate.getMonth();

  const yearDiff = endYear - startYear;
  const monthDiff = endMonth - startMonth;

  return yearDiff * 12 + monthDiff;
}

export function formatFileSize(bytes: number) {
  let suffixes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

  let exponent = 0;
  let temp = bytes;
  while (temp > 1000) {
    temp /= 1000;
    exponent += 1;
  }

  return `${(bytes / 1000 ** exponent).toFixed(0)} ${suffixes[exponent]}`;
}