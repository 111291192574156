import { Box, Typography, styled, Checkbox } from "@mui/material";
import React, { useEffect } from "react";
import { globalColors } from "../../../utils/constants/color";
import ICEyeOpen from "../../../assets/Icons/ic-open-eye 1.svg";
import ICEyeClose from "../../../assets/Icons/ic-close-eye.svg";
import { Field, useField } from "formik";
import { useSelector } from "react-redux";
import { selectColor } from "../../../store/selector";
import { useState } from "react";

interface FieldInputProps {
  id: string;
  name: string;
  label?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  value?: string;
  className?: string;
  icon?: string;
  iconComponent?: any;
  optional?: true | false;
  iconPosition?: "left" | "right";
  type?: string;
  mode?: string;
  min?: any;
  max?: any;
  isPassword?: boolean;
  isShowPassword?: boolean;
  onShowPassword?: () => void;
  error?: string | boolean;
  defaultValue?: string;
  showCheckBox?: true | false;
  checked?: true | false;
  onUnchecked?: () => void;
  disabled?: boolean;
}

const FieldInput = ({
  label,
  placeholder,
  checked,
  showCheckBox = false,
  onChange,
  value,
  name,
  min,
  max,
  id,
  mode = "dark",
  className,
  icon,
  iconComponent,
  iconPosition,
  type,
  isShowPassword,
  isPassword,
  optional = true,
  onShowPassword,
  error,
  defaultValue,
  onUnchecked,
  disabled,
}: FieldInputProps) => {
  const [field, meta, helpers] = useField(name);
  const [checkBox, setCheckedBox] = useState(
    !showCheckBox ? true : checked || false
  );
  const color = useSelector(selectColor);
  useEffect(() => {
    if (value) {
      helpers.setValue(value);
    }
  }, []);

  useEffect(() => {
    if (!checkBox) {
      if (onUnchecked) {
        onUnchecked();
      }
    }
  }, [checkBox]);

  return (
    <FieldInputStyle className={className} mode={mode ? mode : ""}>
      <Box
        display="flex"
        justifyContent={optional ? "space-between" : ""}
        gap="10px"
        mb={"1rem"}
        fontSize="1rem"
      >
        <Typography
          color={mode === "dark" ? globalColors.white : globalColors.black}
        >
          {label}
        </Typography>
        {!optional && <Typography sx={{ color: "red" }}>*</Typography>}
        {showCheckBox && (
          <Checkbox
            size="small"
            sx={{
              color: color || globalColors.blue,
              "&.Mui-checked": {
                color: color || globalColors.secondBlue,
              },
              marginTop: "-7px",
            }}
            onChange={() => setCheckedBox((prev: boolean) => !prev)}
            checked={checkBox || false}
          />
        )}
      </Box>
      {checkBox && (
        <div className={"group-input"}>
          {iconComponent && iconPosition && iconComponent}
          {icon && iconPosition === "left" && (
            <div>
              <img className="icon" src={icon} alt={icon} />
            </div>
          )}
          <Field
            min={min}
            max={max}
            id={id}
            name={name}
            placeholder={placeholder}
            className="input"
            type={type}
            defaultValue={defaultValue}
            disabled={disabled}
            autoComplete={isPassword && 'new-password'}
          />

          {/* Icon */}
          {icon && iconPosition === "right" && (
            <div>
              <img className="icon" src={icon} alt={icon} />
            </div>
          )}
          {isPassword && (
            <button
              type="button"
              className="btn-show-password"
              onClick={onShowPassword}
            >
              <img
                className="icon"
                src={isShowPassword ? ICEyeOpen : ICEyeClose}
                alt={icon}
              />
            </button>
          )}
        </div>
      )}
      {error && (
        <Typography variant="caption" className="error">
          {error}
        </Typography>
      )}
    </FieldInputStyle>
  );
};

const FieldInputStyle = styled("div")<{ mode: string }>((props) => ({
  width: "100%",
  color: "white",
  "& .group-input": {
    padding: "0 1rem",
    backgroundColor:
      props?.mode === "dark" ? globalColors.black : globalColors.white,
    border: props?.mode === "dark" ? "2px solid #303030" : "1px solid #303030",
    // height: '48px',
    borderRadius: "12px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "1rem",
    "& img.icon": {
      width: "1rem",
      height: "1rem",
      marginTop: "5px",
    },
    "& .input": {
      color: props.mode === "dark" ? globalColors.white : globalColors.black,
      backgroundColor: "transparent !important",
      // height: '13px',
      fontSize: "14px",
      padding: "0.8rem",
      border: "none",
      flex: 1,
      width: "100%",
      "&:focus": {
        outline: "none",
        backgroundColor: "transparent",
      },
      "&:-webkit-autofill": {
        outline: "none",
        backgroundColor: "transparent",
        "-webkit-background-clip": "text",
        "-webkit-text-fill-color": "#ffffff",
        transition: "background-color 5000s ease-in-out 0s",
      },
      "&:-webkit-autofill:focus ": {
        outline: "none",
        backgroundColor: "transparent",
      },

      "&:active": {
        outline: "none",
        backgroundColor: "transparent",
      },
      "&:target": {
        outline: "none",
        backgroundColor: "transparent",
      },
    },

    "& .btn-show-password": {
      all: "unset",
      cursor: "pointer",
    },
  },

  ".error": {
    color: "red",
  },
}));

export default FieldInput;
