import Parse, { User } from 'parse';
import { IMGDefault } from '../../../assets/Images';
import { Toast } from '../../../components/atoms';
import { IFormDetailProject, IImages } from '../../../store/projects';
import { getYearQuarter, isArray, isFile } from '../../helpers';
import GalleryModel from '../models/galleryModel';
import ProjectModel from '../models/projectModel';
import GalleryRepository from './galleryRepository';
import { Picture } from '../models/propertyModel';
import UserRepository from './userRepository';
import FileRepository from './fileRepository';
import FileModel from '../models/fileModel';

class ProjectRepository {
  private className = 'Project';

  constructor() {}

  public async create(object: ProjectModel) {
    const Project = Parse.Object.extend(this.className);
    const Gallery = Parse.Object.extend('Gallery');
    const project = new Project();

    project.set('floorsBlocks', [object.floorsBlocks]);
    project.set('name', object.name.toString());
    project.set('contactName', object.contactName.toString());
    project.set('contactPhone', object.contactPhone);
    project.set('areaUnit', object.areaUnit.toString());
    project.set('area', +object.area);
    project.set('areaUnit', object.areaUnit.toString());
    const status = object.status ? object.status : 'Available';
    project.set('status', status);
    project.set('consistOf', object.consistOf);
    project.set('consistOfUnit', object.consistOfUnit);
    project.set('purpose', object.purpose);
    project.set('category', object.category);
    project.set('type', object.type);
    project.set('contactSecondaryPhone', object.contactSecondaryPhone);
    project.set('country', object.country);
    project.set('state', object.state);
    project.set('city', object.city);
    project.set('address', { address: object.address });
    project.set('description', object.description);
    project.set('notes', object.notes);
    // const priority = object.priority ? object.priority : 'hot';
    project.set('priority', object.priority);
    project.set('createdAt', object.createdAt);
    if (object.status === 'Sold') {
        project.set('soldAt', object.soldAt);
        project.set('price', parseFloat(object.sellingPrice ?? '-1'))
        project.set('sellingPrice', parseFloat(object.sellingPrice ?? '-1'));
        if (object.soldBy) {
          const seller = User.createWithoutData(object.soldBy)
          project.set('soldBy', seller);
        }
    }

    if (object.attachments && object.attachments.length > 0) {
      const fileRepository = new FileRepository();
      let savedFiles: any[] = [];
      const attachmentFiles = object.attachments as File[];
      await Promise.all(
        attachmentFiles.map(async (file) => {
          const fileModel: FileModel = {
            file: file,
          };
          const savedFile = await fileRepository.create(fileModel);
          if (savedFile) {
            savedFiles.push(savedFile);
          }
        })
      );
      const attachmentsRelation = project.relation('attachments');
      attachmentsRelation.add(savedFiles);
    }

    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();
    if (currentUser) {
      project.set('createdBy', currentUser);
      project.set('updatedBy', currentUser);
      project.set('organization', currentUser.get('organization'));
    } else {
      project.set('createdBy', null);
    }

    // ! Cover Picture
    console.log('object.coverPicture', isFile(object.coverPicture));
    if (object.coverPicture && isFile(object.coverPicture)) {
      const coverPicture = new Gallery();
      const storeCoverPicture = new Parse.File(object.coverPicture.name, object.coverPicture);
      await storeCoverPicture
        .save({ useMasterKey: true })
        .then((savedFile) => {
          // Add the image to the coverPicture relation
          coverPicture.set('file', savedFile);

          console.log('coverPicture',coverPicture)

          // Save the coverPicture
          coverPicture
            .save(null, { useMasterKey: true })
            .then((savedGallery: any) => {
              // Set the coverPicture relation on the property
              project.set('coverPicture', coverPicture);

              // Save the project
              project
                .save(null, { useMasterKey: true })
                .then((savedProperty: any) => {})
                .catch((error: any) => {});
            })
            .catch((error: any) => {});
        })
        .catch((error) => {});
    }

    // ! Pictures
    if (isArray(object.pictures) && object.pictures.length > 0) {
      const galleryRepository = new GalleryRepository();
      let savedFiles: any[] = [];
      const imagesFiles = object.pictures as File[];

      await Promise.all(
        imagesFiles.map(async (file) => {
          const uploadFile = new Parse.File(file.name, file);
          const galleryModel: GalleryModel = {
            file: uploadFile,
          };
          const savedFile = await galleryRepository.createAndReturn(galleryModel);
          if (savedFile) {
            savedFiles.push(savedFile);
          }
        })
      );
      const galleriesRelation = project.relation('pictures');
      galleriesRelation.add(savedFiles);
    }

    await project.save(null, { useMasterKey: true }).then(
      (project: any) => {
        // Execute any logic that should take place after the object is saved.
        console.log('New object created with objectId: ' + project.id);
        return Toast(`Project added successfully.`, 'success');
      },
      (error: Error) => {
        console.log(error);
        // Execute any logic that should take place if the save fails.
        // error is a Parse.Error with an error code and message.
        return Toast(`Project could not be added successfuly. Please try again.`, 'error');
        // alert('Failed to create new object, with error code: ' + error.message);
      }
    );
  }

  public async getAll(search?: string | null, status?: string | null, type?: string | null, purpose?: string | null): Promise<IFormDetailProject[]> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const Project = Parse.Object.extend(this.className);
      var query = new Parse.Query(Project).descending('createdAt');

      query.include('coverPicture');
      query.include('pictures');
      query.equalTo('organization', currentUser.get('organization'));
      if (status && status !== '') query.equalTo('status', status);
      if (type && type !== '') query.equalTo('type', type);
      if (purpose && purpose !== '') query.equalTo('purpose', purpose);
      if (search) {
        const regex = new RegExp(search, 'i');
        query.matches('name', regex);
      }
      try {
        const projects = await query.find({ useMasterKey: true });
        // Convert the Parse objects to ProjectModel
        const projectDataResult: IFormDetailProject[] = [];
        await Promise.all(
          projects.map(async (project: any) => {
            const projectData = project.toJSON();

            // ! Handling cover picture
            const coverPicture = project.get('coverPicture');
            let coverPictureUrl = IMGDefault;
            if (coverPicture) {
              coverPictureUrl = coverPicture.toJSON()?.file?.url;
            }

            // ! Handling pictures
            const picturesRelation = project.relation('pictures');

            // Query the relation to get the gallery
            const urlPictures: IImages[] = [];
            await picturesRelation
              .query()
              .find({ useMasterKey: true })
              .then((gallery: { [x: string]: any }) => {
                if (gallery) {
                  if (gallery && gallery.length > 0) {
                    gallery.forEach((picture: any) => {
                      const getPicture = picture.toJSON();
                      if (getPicture) {
                        const picture = {
                          id: getPicture.objectId,
                          url: getPicture.file?.url,
                          file: getPicture.file,
                        };
                        urlPictures.push(picture);
                      }
                    });
                  }
                } else {
                  console.error('Gallery not found.');
                }
              })
              .catch((error: { message: string }) => {
                console.error('Error querying gallery relation: ' + error.message);
              });

            const data = {
              id: project.id,
              name: projectData.name ?? '-',
              cover: {
                files: [],
                preview: coverPictureUrl,
              },
              type: projectData.type ?? '-',
              country: projectData.country ?? '-',
              state: projectData.state ?? '-',
              city: projectData.city ?? '-',
              address: projectData.address?.address ?? '-',
              consistOf: projectData.consistOf ?? '-', // TODO : add created
              consistOfUnit: projectData.consistOfUnit ?? '-', // TODO : add created
              area: projectData.area ?? '-',
              areaUnit: projectData.areaUnit ?? '-',
              purpose: projectData.purpose ?? '-', // TODO : add created
              description: projectData.description ?? '-',
              contactName: projectData.contactName ?? '-',
              contactPhone: projectData.contactPhone ?? '-',
              contactSecondaryPhone: projectData.contactSecondaryPhone ?? '-',
              status: projectData.status ?? '-',
              images: urlPictures ?? null,
              priority: projectData.priority ?? '-',
            };

            // if (project.get('pictures').length > 0) {
            //   project.get('pictures').forEach((picture: any) => {
            //     data.images.push(picture.attributes.file._url);
            //   });
            // }
            projectDataResult.push(data);
          })
        );

        return projectDataResult;
        // return projectModels as unknown as ProjectModel[];
      } catch (error) {
        console.error('Error fetching project:', error);
        return [];
      }
    } else {
      return [];
    }
  }

  public async getSome(): Promise<IFormDetailProject[]> {
    const userRepository = new UserRepository();
    const currentUser = userRepository.getCurrentUser();

    if (currentUser) {
      const Project = Parse.Object.extend(this.className);
      var query = new Parse.Query(Project).descending('createdAt').limit(5);

      query.include('coverPicture');
      query.include('pictures');
      query.equalTo('organization', currentUser.get('organization'));

      try {
        const projects = await query.find({ useMasterKey: true });
        // Convert the Parse objects to ProjectModel
        const projectDataResult: IFormDetailProject[] = [];
        await Promise.all(
          projects.map(async (project: any) => {
            const projectData = project.toJSON();

            // ! Handling cover picture
            const coverPicture = project.get('coverPicture');
            let coverPictureUrl = IMGDefault;
            if (coverPicture) {
              coverPictureUrl = coverPicture.toJSON()?.file?.url;
            }

            // ! Handling pictures
            const picturesRelation = project.relation('pictures');

            // Query the relation to get the gallery
            const urlPictures: IImages[] = [];
            await picturesRelation
              .query()
              .find({ useMasterKey: true })
              .then((gallery: { [x: string]: any }) => {
                if (gallery) {
                  if (gallery && gallery.length > 0) {
                    gallery.forEach((picture: any) => {
                      const getPicture = picture.toJSON();
                      if (getPicture) {
                        const picture = {
                          id: getPicture.objectId,
                          url: getPicture.file?.url,
                          file: getPicture.file,
                        };
                        urlPictures.push(picture);
                      }
                    });
                  }
                } else {
                  console.error('Gallery not found.');
                }
              })
              .catch((error: { message: string }) => {
                console.error('Error querying gallery relation: ' + error.message);
              });

            const data = {
              id: project.id,
              name: projectData.name ?? '-',
              cover: {
                files: [],
                preview: coverPictureUrl,
              },
              type: projectData.type ?? '-',
              country: projectData.country ?? '-',
              state: projectData.state ?? '-',
              city: projectData.city ?? '-',
              address: projectData.address?.address ?? '-',
              consistOf: projectData.consistOf ?? '-', // TODO : add created
              consistOfUnit: projectData.consistOfUnit ?? '-', // TODO : add created
              area: projectData.area ?? '-',
              areaUnit: projectData.areaUnit ?? '-',
              purpose: projectData.purpose ?? '-', // TODO : add created
              description: projectData.description ?? '-',
              contactName: projectData.contactName ?? '-',
              contactPhone: projectData.contactPhone ?? '-',
              contactSecondaryPhone: projectData.contactSecondaryPhone ?? '-',
              status: projectData.status ?? '-',
              images: urlPictures ?? null,
              priority: projectData.priority ?? '-',
            };

            // if (project.get('pictures').length > 0) {
            //   project.get('pictures').forEach((picture: any) => {
            //     data.images.push(picture.attributes.file._url);
            //   });
            // }
            projectDataResult.push(data);
          })
        );

        return projectDataResult;
        // return projectModels as unknown as ProjectModel[];
      } catch (error) {
        console.error('Error fetching project:', error);
        return [];
      }
    } else {
      return [];
    }
  }

  public async getByObjectId(objectId: string): Promise<IFormDetailProject | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project);

    query.include('coverPicture');

    return await query
      .get(objectId, { useMasterKey: true })
      .then(async (project) => {
        const projectData = project.toJSON();

        // ! Handling cover picture
        const coverPicture = project.get('coverPicture');
        let coverPictureUrl = null;
        if (coverPicture) {
          coverPictureUrl = coverPicture.toJSON()?.file?.url;
        }

        // ! Handling pictures
        const picturesRelation = project.relation('pictures');

        // Query the relation to get the gallery
        const urlPictures: IImages[] = [];
        await picturesRelation
          .query()
          .find({ useMasterKey: true })
          .then((gallery: { [x: string]: any }) => {
            if (gallery) {
              if (gallery && gallery.length > 0) {
                gallery.forEach((picture: any) => {
                  const getPicture = picture.toJSON();
                  if (getPicture) {
                    const picture = {
                      id: getPicture.objectId,
                      url: getPicture.file?.url,
                      // file: getPicture.file,
                    };
                    urlPictures.push(picture);
                  }
                });
              }
            } else {
              console.error('Gallery not found.');
            }
          })
          .catch((error: { message: string }) => {
            console.error('Error querying gallery relation: ' + error.message);
          });

        const data = {
          id: project.id,
          name: projectData.name ?? '-',
          cover: {
            files: [],
            preview: coverPictureUrl,
          },
          type: projectData.type ?? '-',
          country: projectData.country ?? '-',
          state: projectData.state ?? '-',
          city: projectData.city ?? '-',
          address: projectData.address?.address ?? '-',
          consistOf: projectData.consistOf ?? '-',
          consistOfUnit: projectData.consistOfUnit ?? '-',
          area: projectData.area ?? '-',
          areaUnit: projectData.areaUnit ?? '-',
          purpose: projectData.purpose ?? '-',
          description: projectData.description ?? '-',
          contactName: projectData.contactName ?? '-',
          contactPhone: projectData.contactPhone ?? '-',
          contactSecondaryPhone: projectData.contactSecondaryPhone ?? '-',
          status: projectData.status ?? '-',
          images: urlPictures ?? null,
          priority: projectData.priority ?? '-',
          sellingPrice: projectData.sellingPrice ?? '0',
          soldAt: projectData.soldAt ? projectData.soldAt.iso.split('T')[0] : `${new Date().getFullYear()}-${(new Date().getMonth() + 1).toString().padStart(2, '0')}-${new Date().getDate().toString().padStart(2, '0')}`,
          soldBy: project.get('soldBy') ? project.get('soldBy').id : ''
        };

        return data;
      })
      .catch((error) => {
        console.log(error)
        // console.error('Error:', error);
        return null;
      });
  }

  public async getObjectByObjectId(objectId: string): Promise<Parse.Object | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project);

    query.include('coverPicture');

    return await query
      .get(objectId, { useMasterKey: true })
      .then(async (project) => {
        return project;
      })
      .catch((error) => {
        console.log(error)
        // console.error('Error:', error);
        return null;
      });
  }

  public async updateByObjectId(objectId: string, object: ProjectModel): Promise<any> {
    // IFormDetailProject | null
    const Project = Parse.Object.extend(this.className);
    const Gallery = Parse.Object.extend('Gallery');
    const query = new Parse.Query(Project);
    const galleryRepository = new GalleryRepository();

    query.include('coverPicture');

    const project = await query.get(objectId, { useMasterKey: true });

    try {
      if (!project) {
        return false;
      }

      if (project) {
        if (object.name !== project.get('name')) {
          project.set('name', object.name.toString());
        }

        if (object.floorsBlocks) {
          project.set('floorsBlocks', [object.floorsBlocks]);
        }

        if (object.contactName) {
          project.set('contactName', object.contactName.toString());
        }
        if (object.contactPhone) {
          project.set('contactPhone', object.contactPhone);
        }
        if (object.areaUnit) {
          project.set('areaUnit', object.areaUnit.toString());
        }
        if (object.area) {
          project.set('area', +object.area);
        }
        if (object.areaUnit) {
          project.set('areaUnit', object.areaUnit.toString());
        }

        if (object.status) {
          const status = object.status ? object.status : 'Hold';
          project.set('status', status);
        }

        if (object.consistOf) {
          project.set('consistOf', object.consistOf);
        }
        if (object.consistOfUnit) {
          project.set('consistOfUnit', object.consistOfUnit);
        }

        if (object.purpose) {
          project.set('purpose', object.purpose);
        }

        if (object.category) {
          project.set('category', object.category);
        }

        if (object.type) {
          project.set('type', object.type);
        }

        if (object.contactSecondaryPhone) {
          project.set('contactSecondaryPhone', object.contactSecondaryPhone);
        }

        if (object.country) {
          project.set('country', object.country);
        }

        if (object.state) {
          project.set('state', object.state);
        }

        if (object.city) {
          project.set('city', object.city);
        }

        if (object.address) {
          project.set('address', { address: object.address });
        }

        if (object.description) {
          project.set('description', object.description);
        }

        if (object.notes) {
          project.set('notes', object.notes);
        }

        if (object.status === 'Sold') {
          if (object.sellingPrice) {
            project.set('sellingPrice', parseFloat(object.sellingPrice ?? '-1'))
            project.set('price', parseFloat(object.sellingPrice ?? '-1'))
          }
          if (object.soldAt) {
              if (object.status === 'Sold') {
                  project.set('soldAt', object.soldAt)
              }
              else {
                  project.set('soldAt', null)
              }
          }
          if (object.soldBy) {
            const seller = User.createWithoutData(object.soldBy)
            project.set('soldBy', seller);
          }
        }
        else {
          project.set('soldAt', null);
          project.set('soldBy', null);
          project.set('soldAt', null);
        }

        if (object.attachments && object.attachments.length > 0) {
          const fileRepository = new FileRepository();
          let savedFiles: any[] = [];
          const attachmentFiles = object.attachments as File[];
          await Promise.all(
            attachmentFiles.map(async (file) => {
              const fileModel: FileModel = {
                file: file,
              };
              const savedFile = await fileRepository.create(fileModel);
              if (savedFile) {
                savedFiles.push(savedFile);
              }
            })
          );
          const attachmentsRelation = project.relation('attachments');
          attachmentsRelation.add(savedFiles);
        }

        project.set('updatedAt', object.updatedAt);

        const userRepository = new UserRepository();
        const currentUser = userRepository.getCurrentUser();
        if (currentUser) {
          project.set('createdBy', currentUser);
          project.set('updatedBy', currentUser);
        } else {
          project.set('createdBy', null);
        }
        // project.set('updatedBy', null);
      }

      if (object.coverPicture && isFile(object.coverPicture)) {
        // ! delete prev images
        const prevCover = project.get('coverPicture');
        let coverPictureUrl = null;
        if (prevCover) {
          coverPictureUrl = prevCover.toJSON();
        }

        const coverPicture = new Gallery();
        const storeCoverPicture = new Parse.File(object.coverPicture.name, object.coverPicture);

        if (storeCoverPicture) {
          coverPicture.set('file', storeCoverPicture);
          await coverPicture.save(null, { useMasterKey: true });
          if (coverPicture) {
            project.set('coverPicture', coverPicture);

            // await galleryRepository.destroyImage(coverPictureUrl?.objectId);
          }
        }
      }

      const imagesFiles = object.pictures as Picture[];
      if (isArray(imagesFiles) && imagesFiles.length > 0) {
        let savedFiles: any[] = [];

        // ! delete prev images
        const picturesRelation = project.relation('pictures');
        const getPrevPictures = await picturesRelation.query().find({ useMasterKey: true });
        if (getPrevPictures.length > 0) {
          await Promise.all(
            getPrevPictures.map(async (picture: any) => {
              const getPicture = picture.toJSON();

              const isPictureExist = imagesFiles.find((picture: any) => picture.id === getPicture.objectId);
              if (!isPictureExist) {
                await galleryRepository.destroyImage(getPicture.objectId);
              }
            })
          );
        }

        await Promise.all(
          imagesFiles.map(async (file) => {
            if (isFile(file)) {
              const uploadFile = new Parse.File(file.name, file);
              const galleryModel: GalleryModel = {
                file: uploadFile,
              };
              const savedFile = await galleryRepository.createAndReturn(galleryModel);
              if (savedFile) {
                savedFiles.push(savedFile);
              }
            }
          })
        );
        const galleriesRelation = project.relation('pictures');
        galleriesRelation.add(savedFiles);

        // TODO : delete prev images
      }

      await project.save(null, { useMasterKey: true });
      return Toast(`Project updated successfully.`, 'success');
    } catch (error) {
      return Toast(`Project could not be updated. Error: ${error}`, 'error');
    }
  }

  public async delete(id: string) {
    try {
      const Project = Parse.Object.extend(this.className);
      const query = new Parse.Query(Project);

      const project = await query.get(id, { useMasterKey: true });

      if (project) {
        await project.destroy({ useMasterKey: true });
        Toast(`Project Deleted successfully.`, 'success');
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.error('Error deleting project:', error);
      return false;
    }
  }

  // TODO: ADD SOLD AT AND SOLD BY
  public async updateMultipleProjects(ProjectIds: string[], status: string | null) {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).containedIn('objectId', ProjectIds);
    try {
      const Projects = await query.find({ useMasterKey: true });
      console.log(Projects);

      var success = true;
      for (const Project of Projects) {
        if (status) {
          Project.set('status', status);
        }
        // if (price && price !== 0) {
        //   Project.set('price', price);
        // }

        await Project.save(null, { useMasterKey: true }).then(
          (_: any) => {},
          (error: Error) => {
            success = false;
          }
        );
      }
      if (success) {
        Toast(`Project updated successfully.`, 'success');
        return true;
      } else {
        Toast(`Project could not be updated successfully.`, 'error');
        return false;
      }
    } catch (error) {
      console.error('Error updating Project:', error);
      return false;
    }
  }

  public async deleteMultipleProject(ProjectIds: string[]): Promise<boolean | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).containedIn('objectId', ProjectIds);

    try {
      const Projects = await query.find({ useMasterKey: true });

      return new Promise((resolve, _) => {
        Parse.Object.destroyAll(Projects, { useMasterKey: true }).then(
          (_) => {
            Toast(`Project deleted successfully.`, 'success');
            resolve(true);
          },
          (error: Error) => {
            Toast(`Project could not be deleted successfully. The following error occurred: ${error.message}`, 'error');
            resolve(false);
          }
        );
      });
    } catch (error) {
      console.error('Error deleting Project:', error);
      return false;
    }
  }

  public async countByOrganization(organizationId: string): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).equalTo('organization', organizationId);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async countSoldByOrganization(organizationId: string): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).equalTo('organization', organizationId).equalTo('status', 'Sold');

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getTotalSales(organizationId: string): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).equalTo('status', 'Sold').equalTo('organization', organizationId).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (projects) => {
            if (projects) {
                let count = 0;
                for (let i = 0; i < projects.length; i++) {
                  count += projects[i].get('sellingPrice') ?? 0;
                }
                resolve(count);
            }
            else {
                resolve(null)
            }
            
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getTotalSalesForUser(userId: string): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).equalTo('status', 'Sold').equalTo('soldBy', userId).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (projects) => {
            if (projects) {
              let count = 0;
              for (let i = 0; i < projects.length; i++) {
                count += projects[i].get('sellingPrice') ?? 0;
              }
              resolve(count);
            } else {
              resolve(null);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getSalesCountForUser(userId: string): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project).equalTo('status', 'Sold').equalTo('soldBy', userId).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getSalesCountForUserByYear(userId: string, year: number): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(Project).equalTo('status', 'Sold').equalTo('soldBy', userId).greaterThanOrEqualTo('soldAt', yearStartDate).lessThanOrEqualTo('soldAt', yearEndDate).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getSalesCountForOrganizationByYear(organizationId: string, year: number): Promise<number | null> {
    const Project = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(Project).equalTo('status', 'Sold').equalTo('organization', organizationId).greaterThanOrEqualTo('soldAt', yearStartDate).lessThanOrEqualTo('soldAt', yearEndDate).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.count({ useMasterKey: true }).then(
          (count) => {
            resolve(count);
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getTotalSalesForUserByYearFormatted(userId: string, year: number): Promise<any | null> {
    const Project = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(Project).equalTo('status', 'Sold').equalTo('soldBy', userId).greaterThanOrEqualTo('soldAt', yearStartDate).lessThanOrEqualTo('soldAt', yearEndDate).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (projects) => {
            if (projects) {
              let quarterlyData = {'Q1': 0, 'Q2': 0, 'Q3': 0, 'Q4': 0};

              for (let i = 0; i < projects.length; i++) {
                const quarter = getYearQuarter(projects[i].get('soldAt'));
                if (quarter !== '-') {
                  quarterlyData[quarter] += projects[i].get('sellingPrice') ?? 0;
                }
              }
              resolve(quarterlyData);
            } else {
              resolve(null);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getTotalSalesForOrganizationByYearFormatted(organizationId: string, year: number): Promise<any | null> {
    const Project = Parse.Object.extend(this.className);
    let yearStartDate = new Date();
    yearStartDate.setFullYear(year, 0, 1);
    let yearEndDate = new Date();
    yearEndDate.setFullYear(year, 11, 31);
    const query = new Parse.Query(Project).equalTo('status', 'Sold').equalTo('organization', organizationId).greaterThanOrEqualTo('soldAt', yearStartDate).lessThanOrEqualTo('soldAt', yearEndDate).limit(100000);

    try {
      return new Promise((resolve, _) => {
        query.find({ useMasterKey: true }).then(
          (projects) => {
            if (projects) {
              let quarterlyData = {'Q1': 0, 'Q2': 0, 'Q3': 0, 'Q4': 0};

              for (let i = 0; i < projects.length; i++) {
                const quarter = getYearQuarter(projects[i].get('soldAt'));
                if (quarter !== '-') {
                  quarterlyData[quarter] += projects[i].get('sellingPrice') ?? 0;
                }
              }
              resolve(quarterlyData);
            } else {
              resolve(null);
            }
          },
          (error: Error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {
      console.error('Error counting projects:', error);
      return null;
    }
  }

  public async getAllAttachments(id: string): Promise<Parse.Object[] | null> {
    const Project = Parse.Object.extend(this.className);
    const query = new Parse.Query(Project);
    try {
      const project = await query.get(id, { useMasterKey: true });
      if (project) {
        const attachmentsQuery = project.relation('attachments').query().include('file').descending('createdAt');
        return new Promise((resolve, _) => {
          attachmentsQuery.find({ useMasterKey: true }).then(
            (attachments) => {
              if (attachments) {
                resolve(attachments);
              }
              else {
                resolve(null);
              }
            },
            (error: any) => {
              resolve(null);
            }
          );
        });
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public async countAllProjects() {
    try {
      const Project = Parse.Object.extend(this.className);
      const query = new Parse.Query(Project);

      return await query.count();
    }
    catch (e) {
      return null;
    }
  }
}

export default ProjectRepository;
