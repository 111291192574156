import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { globalColors } from "../../../../../utils/constants/color";
import { useDispatch, useSelector } from "react-redux";
import { selectColor, selectThemeMode } from "../../../../../store/selector";
import { styled } from "@mui/material";
import { useNavigate } from "react-router";
import { setAddNew } from "../../../../../store/routes";
import { CiEdit } from "react-icons/ci";
import OrganizationRepository from "../../../../../utils/api/repositories/organizationRepository";
import {
  formatDate,
  getColorForOrganizationStatus,
} from "../../../../../utils/helpers";
import { Toast } from "../../../../atoms";
import ModalStatusChange from "../modalStatusChange";

interface ContentRowProp {
  id: string;
  data: Parse.Object;
}

const ContentRow: React.FC<ContentRowProp> = ({ id, data }) => {
  // Menu
  const [changeAccess, setChangeAccess] = useState<string>(data.get("status"));
  const [originalAccess, setOriginalAccess] = useState<string>(
    data.get("status")
  );
  const mode = useSelector(selectThemeMode);
  const color = useSelector(selectColor);
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const style = {
    color: mode === "dark" ? globalColors.white : globalColors.black,
    whiteSpace: "nowrap",
    fontSize: "12px",
    fontWeight: "400",
    textTransform: "capitalize",
    sm: {
      width: "150px",
      maxWidth: "150px",
    },
    lg: {
      width: "250px",
      maxWidth: "250px",
    },
  };

  // const handleAction = (id: any) => {
  //   dispatch(setAddNew({ addNew: true, path: '/settings/commission' }));
  //   navigate(`/settings/commission/edit/${id}`);
  // };

  const handleChangeAccess = async (event: SelectChangeEvent<string>) => {
    setChangeAccess(event.target.value);
    setTimeout(() => {
      setOpenStatusModal(true);
    }, 2000);
  };

  const handleStatusChangeWithReason = async (reason: string) => {
    const organizationRepository = new OrganizationRepository();
    const organizationData = {
      status: changeAccess,
      sanction: changeAccess === "active" ? "" : reason,
    };
    const updatedOrganization = await organizationRepository.update(
      organizationData,
      id
    );
    if (updatedOrganization) {
      Toast(
        `Organization status changed to ${changeAccess} successfully.`,
        "success"
      );
      setOriginalAccess(changeAccess);
    } else {
      Toast(
        `Organization status could not be changed successfully. Please try again.`,
        "error"
      );
    }
  };

  return (
    <TableRow
      sx={{
        backgroundColor:
          mode === "dark" ? globalColors.black : globalColors.white,
        width: "100%",
        color: mode === "dark" ? globalColors.white : globalColors.black,
        borderRadius: "10px",
        gap: "20px",
        marginBottom: "20px",
        paddingBottom: "20px",
        borderBottom: `20px solid ${
          mode === "dark" ? globalColors.blackLight : globalColors.lightgray
        }`,
      }}
      key={id}
    >
      {/* name */}
      <TableCell sx={style} component="th" scope="row">
        {data.get("name") ?? "-"}
      </TableCell>

      {/* country */}
      <TableCell sx={style}>{data.get("businessLocation") ?? "-"}</TableCell>

      {/* users */}
      <TableCell
        style={{
          color: mode === "dark" ? globalColors.white : globalColors.black,
        }}
        sx={style}
      >
        {data.get("memberCount") ?? "-"}
      </TableCell>

      {/* dateJoined */}
      <TableCell
        style={{
          color: mode === "dark" ? globalColors.white : globalColors.black,
        }}
        sx={style}
      >
        {formatDate(data.get("createdAt")) ?? "-"}
      </TableCell>

      {/* status */}
      {/* <TableCell
        style={{ color: data?.status === 'Paid' ? globalColors.blue : data?.status === 'Unpaid' ? globalColors.red : globalColors.text.secondary }}
        sx={style}
      >
        {data.get('status') ?? '-'}
      </TableCell> */}

      {/* Action */}
      <TableCell sx={style}>
        <FormControl>
          <Select
            onChange={(event) => {
              handleChangeAccess(event);
            }}
            value={originalAccess}
            sx={{
              marginRight: "-15px",
              // border: `1px solid ${mode === 'dark' ? globalColors.white : globalColors.black}`,
              border: "none",
              fontSize: "12px",
              outline: "none",
              color:
                getColorForOrganizationStatus(originalAccess) ?? (mode === "dark"
                  ? globalColors.white
                  : globalColors.black),
              "& .MuiSelect-icon": {
                color:
                  getColorForOrganizationStatus(originalAccess) ??
                  (mode === "dark"
                    ? globalColors.white
                    : globalColors.black),
              },
              ".MuiOutlinedInput-notchedOutline": {
                borderStyle: "none",
              },
              "& .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input":
                {
                  padding: "8px 10px",
                },
            }}
          >
            <MenuItem
              className={
                mode === "dark" ? "bgColorFilter" : "bgColorFilterLight"
              }
              value="active"
            >
              Active
            </MenuItem>
            <MenuItem
              className={
                mode === "dark" ? "bgColorFilter" : "bgColorFilterLight"
              }
              value="inactive"
            >
              Inactive
            </MenuItem>
          </Select>
        </FormControl>
      </TableCell>

      <TableCell sx={style}>
        <Box display={"flex"} gap={"10px"}>
          <CiEdit
            size={"1.3rem"}
            color={color || globalColors.blue}
            style={{ cursor: "pointer" }}
            onClick={() => {
              dispatch(setAddNew({ addNew: true, path: "/dashboard" }));
              navigate(`/organizations/${id}`);
            }}
          />
        </Box>
      </TableCell>
      <ModalStatusChange
        open={openStatusModal}
        onClose={() => {
          setOpenStatusModal(false);
        }}
        onSubmit={handleStatusChangeWithReason}
        mode={mode}
        color={color}
        newStatus={changeAccess}
      />
    </TableRow>
  );
};

export default ContentRow;
